.rf-InboxControlAlertLabel {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: flex;
    flex-wrap: nowrap;
    color: $color-text;
    margin-right: spacing(2);

    .rf-IconLabel__icon {
        color: $color-text;
        font-size: 12px;
        margin-right: spacing(1);
    }

    &--is-icon-hidden {
        &.rf-IconLabel {
            .rf-IconLabel__icon {
                display: none;
            }

            .rf-IconLabel__label {
                margin-left: 0;
            }
        }
    }

    &--is-high-impact {
        .rf-IconLabel__icon {
            color: $color-ui-red;
        }
    }

    &--is-medium-impact {
        .rf-IconLabel__icon {
            color: $color-ui-orange;
        }
    }

    &--is-low-impact {
        .rf-IconLabel__icon {
            color: $color-grey-80;
        }
    }
}
