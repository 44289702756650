.rf-SelectControlTopicsModal {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    position: relative;

    .rf-ControlTopicSelectionTable {
        max-height: 40vh;
        overflow-y: scroll;
        overflow-x: hidden;
        // margin-right: spacing(1.25);

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-grey-65;
            border-radius: 2px;
        }

        &__table {
            // padding-left: spacing(3);
            padding-right: spacing(1.25);
        }
    }
}
