.rf-MonitoringReportsOverviewView {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    max-width: unset;


    &__table-container {
        background-color: $color-background-blue;
        min-height: 100vh;
        margin-bottom: spacing(-15);
        margin-left: spacing(-5);
        margin-right: spacing(-5);
        padding: spacing(8) spacing(8);
        border-radius: 16px 0 0 0;
    }

}
