.rf-Action {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: rgba($color-ruler-pale, 0.5);
    padding: spacing(3);
    border-radius: 8px;

    &__action-content {
        margin-top: 12px;

        // .rf-Column > * > * + * {
        //     margin-top: 10px;
        // }
    }

    &__action-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .rf-ComponentTitle {
            align-self: center;
        }
    }
}
