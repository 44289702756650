.rf-MultiEmailInput {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__input {
        line-height: 1;
        list-style: none;
        position: relative;
        display: inline-block;
        padding: spacing(1, 2) spacing(2);
        width: 100%;
        font-size: 14px;
        color: $color-input-text;
        background-color: #fff;
        border: 1px solid $color-input-border;
        border-radius: 4px;
        resize: vertical;
        -webkit-appearance: none;
        touch-action: manipulation;
        transition: border-color 0.3s;

        &:focus {
            border-color: $color-ruler-blue;
            outline: 0;
        }

        &::placeholder {
            color: $color-input-placeholder;
        }
    }

    &--is-disabled &__input {
        background-color: $color-grey-10;
    }
}
