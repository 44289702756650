.rf-CustomerGreeting {
    @import 'styles/variables/layout';

    &__greeting-container {
        display: flex;
    }

    &__figure {
        margin-right: spacing(3);

        img {
            width: 108px;
        }
    }
}
