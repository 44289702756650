.rf-CustomerDomainItem {
    @import 'styles/variables/colors.scss';
    @import 'styles/variables/layout';

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    &__link:hover {
        text-decoration: none;
        color: $color-grey-95;
    }

    &__link:hover &__suffix,
    &__link:focus &__suffix {
        text-decoration: underline;
    }

    &__name {
        font-size: 18px;
        margin-top: spacing(2);
    }

    &__suffix {
        color: $color-ruler-blue;
        font-size: 16px;
        margin-top: spacing(0.5);
    }

    &__avatar.rf-Avatar--is-large {
        height: 124px;
        width: 124px;
    }

    &__avatar.rf-Avatar--is-large > .rf-Avatar__image {
        background-size: cover;
    }

    &__avatar > .rf-Avatar__image {
        background-size: cover;
        height: 100%;
        width: 100%;
    }
}
