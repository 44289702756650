.rf-CustomerFeedExportsTable {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    .rf-Table__body {
        .rf-Table__row {
            .rf-Table__cell {
                border: none;
                border-bottom: 1px solid $color-grey-40;
            }

            &:first-child {
                .rf-Table__cell {
                    border-top: 1px solid $color-grey-40;
                }
            }
        }
    }

    &__file-tooltip {
        width: 100%;
    }

    &__file {
        position: relative;
        margin-right: spacing(2);
        width: calc(100% - #{spacing(2)});

        .rf-Button__inner {
            display: block;
            text-align: left;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
