.rf-EmployeeItem {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    padding: spacing(2) spacing(2);
    font-size: 14px;

    > * + * {
        margin-top: spacing(1);
    }

    &__role {
        font-weight: 400;
    }

    &__title {
        font-size: 14px;
        font-weight: 500;
        color: $color-grey-95;
    }
}
