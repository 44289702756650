.rf-Paragraph {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    font-size: 14px;
    color: $color-text;
    line-height: 1.5;
    font-weight: normal;

    &--is-subtle {
        color: $color-grey-80;
    }

    &--is-very-subtle {
        color: $color-grey-65;
    }

    &--is-blue {
        color: $color-ruler-blue;
    }

    &--is-white {
        color: $color-white;
    }

    &--is-emphasised {
        font-weight: 500;
    }

    &--is-large {
        font-size: 16px;
    }

    &--is-small {
        font-size: 12px;
    }

    &--is-extra-small {
        font-size: 8px;
    }

    &--preserve-line {
        white-space: pre-line;
    }

    &--is-bold {
        font-weight: 600;
    }

    &--is-center {
        text-align: center;
    }

    &--wrap {
        max-width: 522px;
    }

    &--strikethrough {
        text-decoration: line-through;
    }

    &--truncate-ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }

    &--no-wrap {
        text-wrap: nowrap;
    }
}
