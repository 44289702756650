.rf-VariableMatrixLines {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    min-height: 40px;
    animation: fadein 1.5s;

    &__line {
        stroke: white;
        fill: none;
        stroke-width: 2;

        &--is-dashed {
            stroke: $color-grey-40;
            stroke-dasharray: 6 5;
            stroke-width: 2;
        }
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
