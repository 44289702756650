.rf-SubTaskTemplatesTableContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    min-height: 170px;
    padding: spacing(2.5) spacing(3);
    background-color: $color-grey-10;
    border-radius: 4px;

    .rf-Table__header {
        &:nth-child(1) {
            width: 65%;
        }

        &:nth-child(2) {
            width: 35%;
        }
    }

    &--for-consultant {
        .rf-Table__header {
            &:nth-child(1) {
                width: 100%;
            }

            &:nth-child(2) {
                width: 0%;
            }
        }
    }

    .rf-Table__body {
        .rf-Table__row {
            background: $color-white;

            &--is-inactive {
                background: $color-grey-10;
            }
        }
    }

    &__no-sub-task-message {
        margin-top: spacing(4);
    }

    &__name {
        font-weight: 600;
    }

    &__tag-container {
        height: 25px;
    }
}
