.rf-Tombstone {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    background-color: $color-tombstone-background;
    border-radius: 2px;
    position: relative;
    overflow: hidden;
    display: flex;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba($color-grey-65, 15%), rgba(0, 0, 0, 0));
        transform: translateX(-100%);
        animation: loading 3s infinite;
        opacity: 0.2;
    }

    @keyframes loading {
        100% {
            transform: translateX(100%);
        }
    }
}
