.rf-MatrixLines {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    width: 72px;
    min-height: 40px;
    margin-top: spacing(1, 3px);

    &__line {
        stroke: white;
        fill: none;
        stroke-width: 2;

        &--is-dashed {
            stroke: $color-grey-40;
            stroke-dasharray: 6 5;
            stroke-width: 2;
        }
    }
}
