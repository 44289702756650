.rf-MainNavigation {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    @import 'styles/variables/defaults';

    width: $consultant-menu-width;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    background-color: $color-white;
    border-right: 1px solid $color-grey-40;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 100;

    &--has-banner {
        top: 32px;
        height: calc(100% - 32px);
    }

    &__header {
        padding: spacing(2);
        padding-bottom: spacing(2, -6px);
        border-bottom: 1px solid $color-grey-40;
    }

    &__logo {
        transform: translateX(0px);
        transition: transform 0.3s;

        &.rf-Logo {
            svg {
                width: 67px;
                height: 24px;
            }
        }

        .rf-MainNavigation--has-sidebar & {
            transform: translateX(-8px);

            svg {
                width: 24px;
            }
        }
    }

    &__navigation {
        padding-top: spacing(1);
        margin: 0 spacing(1);
        transform: translateX(0);
        transition: transform 0.3s;
        overflow-y: auto;
        overflow-x: hidden;
        flex-grow: 1;

        .rf-MainNavigation--has-sidebar & {
            transform: translateX(-#{spacing(2)});
        }
    }

    &__navigation-item + &__navigation-item {
        margin-top: spacing(1);
    }

    &__navigation-link {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        padding-left: 7px;
        font-size: 14px;
        text-decoration: none;
        border-radius: 3px;
        line-height: 1.3;
        min-height: 32px;
        transition: background-color 0.3s, opacity 0.3s, transform 0.3s, padding-left 0.3s;
        cursor: pointer;
        width: 100%;
        border: 0;
        background: transparent;
        position: relative;

        &:hover {
            background-color: $color-grey-10;

            .rf-Icon {
                color: $color-grey-95;
            }
        }

        &--active {
            font-weight: 600;
            background-color: $color-grey-10;

            .rf-MainNavigation__simple-notification {
                border-color: $color-grey-10;
            }

            .rf-Icon {
                background-color: $color-grey-10;
                color: $color-ruler-blue;
            }

            &:hover {
                .rf-MainNavigation__simple-notification {
                    border-color: $color-grey-10;
                }

                .rf-Icon {
                    color: $color-ruler-blue;
                }
            }
        }

        &--manage {
            .rf-MainNavigation__navigation-icon {
                .rf-Icon {
                    font-size: 21px;
                }
            }
        }

        &--logout {
            margin-left: 1px;
            padding-left: 6px;
        }

        &--disabled {
            color: $color-grey-80;
            cursor: default;

            &:hover {
                background-color: $color-white;
            }
        }

        .rf-MainNavigation--has-sidebar & {
            border-radius: 0;
            transform: translateX(8px);
            padding-left: 0;

            &:hover {
                background-color: $color-white;

                .rf-Icon {
                    background-color: $color-grey-10;
                }
            }

            .rf-MainNavigation__simple-notification {
                transform: translateX(1px);
            }

            &--active {
                font-weight: 600;
                background-color: white;
            }
        }
    }

    &__link-content {
        transition: opacity 0.3s;
        opacity: 1;

        .rf-MainNavigation--has-sidebar & {
            opacity: 0;
        }
    }

    &__navigation-icon {
        transform: translateX(0);
        transition: transform 0.3s;
        color: $color-grey-80;
        margin-right: 8px;

        .rf-Icon {
            font-size: 24px;
            width: 24px;
            height: 24px;
            position: relative;
            top: -1px;
            border-radius: 2px;
            transition: width 0.3s;

            &--inbox {
                font-size: 16px;
            }
        }

        &--help {
            .rf-Icon {
                font-size: 20px;
            }
        }

        &--logout {
            margin-right: 8px;

            .rf-Icon {
                font-size: 18px;
            }
        }

        &--disabled {
            color: $color-grey-65;
        }

        .rf-MainNavigation--has-sidebar & {
            transform: translateX(4px);

            &--help {
                .rf-Icon {
                    font-size: 20px;
                }
            }

            &--logout {
                .rf-Icon {
                    font-size: 18px;
                }
            }

            .rf-Icon {
                width: 32px;
            }
        }
    }

    &__simple-notification {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        border: 2px solid $color-white;
        background-color: $color-ruler-blue;
        position: absolute;
        top: 6px;
        left: 21px;
        transition: transform 0.3s;
    }

    &__footer {
        margin-top: auto;
        padding: spacing(1.5) 0 spacing(3, -6px);
        margin: 0 spacing(1);
        transition: transform 0.3s;
        opacity: 1;
        transform: translateX(0);

        .rf-MainNavigation--has-sidebar & {
            transform: translateX(-#{spacing(2)});
        }
    }

    &__version {
        transform: translateX(0);
        transition: transform 0.3s;
        cursor: pointer;
        font-size: 14px;
        color: $color-grey-80;
        margin-left: 8px;
        margin-top: spacing(3, -4px);

        .rf-MainNavigation--has-sidebar & {
            transform: translateX(-33px);
        }
    }

    &__version-text {
        opacity: 1;
        transition: opacity 0.3s transform 0.3s margin-right 0.3s;
        transform: scaleX(1);
        transform-origin: left center;
        margin-right: spacing(0.5);

        .rf-MainNavigation--has-sidebar & {
            opacity: 0;
            transform: scaleX(0);
            margin-right: 0;
        }
    }

    &__click-area {
        position: absolute;
        top: 57px; // height of logo
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        .rf-MainNavigation--has-sidebar & {
            pointer-events: auto;
        }
    }

    &__sub-menu {
        position: absolute;
        top: 0;
        left: 40px;
        height: 100%;
        width: calc(100% - 40px);
    }
}
