.rf-RiskTopicSelect {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';

    width: 100%;
    max-width: 160px;

    .rf-Select--is-filter {
        &__menu {
            width: 490px;
        }

        &__option {
            transition: background-color $animation-appear;
            &--is-selected {
                background-color: $color-ruler-pale !important;
            }

            &--is-focused {
                background-color: $color-grey-10 !important;
            }
        }
    }
}
