.rf-ControlsContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__indent-1 {
        margin-left: spacing(5.5);
    }

    &__indent-2 {
        margin-left: spacing(11);
    }

    &__header-container {
        padding: 0 spacing(5) 0 spacing(3);
        font-size: 14px;
        font-weight: 600;
        display: grid;
        grid-template-columns: 69% 16% 15%;
    }

    &__loading-more {
        margin: 0;
        position: absolute;
        left: 0;
        bottom: 6px;
        right: 0;
        width: 100%;
    }
}
