.rf-Table {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    @import 'styles/variables/animations';

    &__table {
        width: 100%;
        text-align: left;
        color: $color-grey-95;
        font-size: 14px;
        font-weight: 400;
        border-collapse: separate;
        border-spacing: 0 spacing(1);
    }

    &__head &__row {
        border-top: none;
    }

    &__loading {
        padding: spacing(2);
    }

    &__header {
        padding: spacing(1) spacing(1, -4) 1px;
        text-align: left;
        white-space: nowrap;
        font-weight: 600;

        &--no-auto-width {
            padding-right: spacing(2);
        }

        &--no-line-break {
            white-space: nowrap;
        }

        &--align-right &--no-auto-width {
            padding-left: spacing(2);
            padding-right: 0;
        }
        &:first-child {
            padding-left: spacing(2);
        }

        &:last-child {
            padding-right: spacing(2);
        }

        &--align-left {
            text-align: left;
        }

        &--align-center {
            text-align: center;
        }

        &--align-right {
            text-align: right;
        }

        &--no-auto-width {
            padding-left: spacing(2);
            padding-right: spacing(2);
        }
    }

    &__body {
        .rf-Table__cell {
            border-top: 1px solid $color-grey-20;
            border-bottom: 1px solid $color-grey-20;

            &:first-child {
                border-left: 1px solid $color-grey-20;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }

            &:last-child {
                border-right: 1px solid $color-grey-20;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }

    &__row {
        break-inside: avoid;

        &--is-clickable {
            cursor: pointer;

            &:hover {
                background-color: $color-grey-10;
            }
        }

        &--is-inactive {
            background: $color-grey-10;

            .rf-Table__cell,
            .rf-Table__row-action {
                color: $color-grey-80;
            }
        }
    }

    &__cell {
        padding: spacing(2) spacing(1, -4px);
        font-size: 14px;

        &:first-child {
            padding-left: spacing(2);
            font-weight: 500;
        }

        &:last-child {
            padding-right: spacing(2);
        }

        &--truncate {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 1px;

            .rf-Table__checkbox-cell,
            .rf-Table__checkbox-content-wrapper {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        &--align-left {
            text-align: left;
        }

        &--align-center {
            text-align: center;
        }

        &--align-right {
            text-align: right;
        }

        &--expandable {
            vertical-align: middle;
        }

        &--no-auto-width {
            padding-left: spacing(2);
            padding-right: spacing(2);
        }

        &--no-line-break {
            white-space: nowrap;
        }

        &--has-checkbox {
            position: relative;

            &:first-child {
                padding: 0;
            }
        }

        &--is-checkbox-active {
            .rf-Table__checkbox-container-inner {
                transform: translateX(0);
            }
        }

        &--is-checked {
            .rf-Table__checkbox-container-inner {
                transform: translateX(0);
            }
        }
    }

    &--hide-headers &__head {
        display: none;
    }

    &__expand-button {
        appearance: none;
        padding: 0;
        margin: 0;
        border: 0;
        background: none;
        transition: transform 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
        transform: rotate(-90deg);
    }

    &__plain-button {
        appearance: none;
        padding: 0;
        margin: 0;
        border: 0;
        background: none;
        font-weight: 600;

        h2 {
            font-size: 14px;
        }
    }

    &__row--has-children {
        > td:nth-child(2) {
            font-weight: 600;
        }
    }

    &__row--is-expanded &__expand-button {
        transform: rotate(0deg);
    }

    &__row--is-expanded &__cell:first-child {
        border-bottom-left-radius: 0;
    }
    &__row--is-expanded &__cell:last-child {
        border-bottom-right-radius: 0;
    }

    &__expanded-row > td {
        position: relative;
        top: -8px;
        border: 1px solid $color-grey-20;
        border-top: 0;
        padding: 8px;
    }

    &__row--is-child + &__row--is-child {
        > td:first-of-type {
            border-top: 1px solid white;
        }
    }

    &__row--is-child {
        > td:nth-of-type(2) {
            padding-left: spacing(3);
        }
    }

    &__row-action {
        max-width: none !important;
        padding-right: spacing(1);
        border: 1px solid $color-grey-20;
        border-left: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        .rf-TableRowOptionsButton {
            margin-right: 3px;
        }
    }

    &--show-actions &__row-action {
        opacity: 1 !important;
    }

    &--white-background .rf-Table__row {
        background-color: $color-white;
    }

    .expandableContainer-appear {
        opacity: 0.01;
        border-color: #fff;
    }

    .expandableContainer-appear-active {
        opacity: 1;
        transition: opacity 200ms ease-in, border 200ms ease-in;
        border-color: $color-grey-20;
    }

    .expandableContainer-exit {
        opacity: 1;
        border-color: $color-grey-20;
    }

    .expandableContainer-exit-active {
        opacity: 0.01;
        border-color: #fff;
        transition: opacity 200ms ease-in, border 200ms ease-in;
    }

    .expandableContainer-enter-done {
        opacity: 1;
        border-color: $color-grey-20;
    }

    &__checkbox-container-outer {
        position: absolute;
        top: 0;
        left: 0;
        width: 72px;
        height: 100%;
        z-index: 2;
    }

    &__checkbox-container-inner {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 58px;
        padding-right: 4px;
        height: 100%;
        border-right: 2px solid $color-ruler-blue;
        background-color: $color-ruler-blue;
        transform: translateX(-50px);
        transition: transform $animation-appear;

        .rf-Checkbox {
            margin-left: spacing(0.5);

            &__checkbox {
                border: 2px solid $color-white;
                border-radius: 2px;
                background-color: $color-ruler-blue;
            }

            &--is-checked > div {
                border-color: $color-white;
                background-color: $color-white;
            }

            &--is-checked:hover > div {
                border-color: $color-white;
                background-color: $color-white;
            }

            &:focus-within > div {
                border-color: $color-white;
            }

            &__mark {
                color: $color-ruler-blue;
            }
        }
    }
    &__border-container {
        width: 6px;
        height: 100%;
        background-color: $color-background;
        position: absolute;
        border-radius: 4px 0 0 4px;
        left: 4px;
        transform: translateX(50px);
        transition: transform $animation-appear;
    }

    &__checkbox-container-outer:hover &__checkbox-container-inner {
        transform: translateX(0);
    }

    &__checkbox-container-outer + * {
        padding-left: spacing(3, -3px);
        padding-right: 50px;
        transform: translateX(0);
        transition: transform $animation-appear;
    }

    &__checkbox-content-wrapper {
        padding: spacing(2) spacing(1, -4px);
        padding-left: spacing(3, -3px);
    }

    &__checkbox-container-outer:hover + *,
    &__cell--is-checked .rf-Table__checkbox-container-outer + *,
    &__cell--is-checkbox-active .rf-Table__checkbox-container-outer + * {
        padding-left: 50px + spacing(3, -3px);
        padding-right: spacing(1, -4px);
        transform: translateX(46px);
        transition: transform $animation-appear;
    }

    // For initial animations
    &__checkbox-appear &__checkbox-container-inner {
        transform: translateX(0);
    }

    &__checkbox-appear-active.rf-Table__checkbox-container-outer + * {
        padding-left: 50px + spacing(3, -3px);
        padding-right: spacing(1, -4px);
        transition: none;
    }
}
