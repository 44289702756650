.rf-ControlsWidget {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__container {
        height: 98px;
        width: min-content;
        border-radius: 4px;
        padding: spacing(2) spacing(3);

        p {
            word-break: keep-all;
            white-space: nowrap;
        }

        &--is-danger {
            color: $color-widget-red;
            background-color: $color-widget-light-red;

            .rf-ControlsWidget__count,
            .rf-ControlsWidget__text {
                color: $color-widget-red;
            }
        }
    }

    &__count {
        font-size: 36px;
        color: $color-grey-80;
        line-height: 1;
    }
}
