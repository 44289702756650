.rf-SidebarLayout {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    display: flex;

    .rf-Sidebar {
        width: 288px;
        position: fixed;
        top: 72px;
        bottom: 0px;
        overflow-y: auto;
        z-index: 9;
    }

    .rf-Page {
        width: 100%;
        padding: spacing(7);
        margin-left: 288px;
    }
}

.rf-AppLayout--has-consultant-header .rf-SidebarLayout .rf-Sidebar {
    @import 'styles/variables/layout';

    top: 121px;
}
