.rf-ZeroInbox {
    @import 'styles/variables/layout';

    display: flex;
    flex-flow: column;
    min-height: 50vh;
    justify-content: center;
    text-align: center;

    &__title {
        font-size: 30px;
        margin-top: spacing(4);
    }

    &__text {
        margin-top: spacing(1, +2);
        font-size: 14px;
        font-weight: 400;
    }

    img {
        width: 380px;
    }
}
