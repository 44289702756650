.rf-AcceptBlock {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    background-color: $color-grey-10;
    padding: spacing(2) spacing(3);
    border-radius: 4px;
    width: 100%;

    .rf-Row {
        display: flex;
        flex-direction: row-reverse;
    }

    &__paragraph {
        line-height: 1.71;
    }

    .rf-Toggle__wrapper {
        display: flex;

        .rf-Paragraph {
            line-height: 1.67;
            font-weight: bold;
        }

        .rf-Paragraph--is-subtle {
            color: $color-grey-80;
        }

        .rf-Paragraph--is-small {
            font-size: 18px;
        }
    }

    &__toggle {
        margin-left: 0;
        margin-right: spacing(2);
    }
}
