.rf-AssessmentScoreBarTooltipContent {
    @import 'styles/variables/layout';

    width: 270px;
    height: auto;
    text-align: left;
    padding: spacing(2);

    &__assessments-icon-container {
        margin-left: spacing(0.75);
        margin-right: spacing(0.75);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: spacing(2, -1) spacing(2, 2);
    }

    &__counts {
        margin-left: spacing(0.75);
    }
}
