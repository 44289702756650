.rf-PermissionLabels {
    @import 'styles/variables/layout';

    &__content {
        margin: spacing(1);
    }

    &__checkbox {
        margin: spacing(1) 0;
        min-height: unset;
    }
}
