.rf-TaskNameWithCompleteButton {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';

    width: 100%;

    &__column-container {
        display: grid;
    }

    &__row-container {
        display: flex;
        align-items: flex-start;
    }

    &__subtask-indent {
        width: 16px;
        height: 13px;
        border-bottom: 1px solid $color-grey-65;
        border-left: 1px solid $color-grey-65;
    }

    &__parent-label {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__task-name {
        font-weight: 600;
        line-height: 1.5em;
        display: grid;

        .rf-Button__inner {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__profile-list {
        width: 80px;
    }

    &__subtask-count {
        flex-wrap: nowrap;
        white-space: nowrap;
    }

    &__repeat-icon {
        color: $color-grey-65;
        padding-top: spacing(1, -2);
        &:hover {
            color: $color-text;
        }
    }

    &__review-task-icon {
        color: $color-grey-65;
        transform: scale(1.3);
        padding-top: spacing(1, -2);
    }

    &__review-subtask-icon {
        color: $color-grey-65;
        margin-right: spacing(1);
    }

    &--is-completed {
        .rf-TaskNameWithCompleteButton__task-name {
            text-decoration: line-through;
        }
    }

    &--is-review-subtask &__task-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-left: 0;
        padding-top: spacing(0, 1);
    }

    &__review-subtask-name {
        font-weight: 400;
        margin-right: spacing(0.5);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
