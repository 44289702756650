.rf-VariableMatrix {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: spacing(2);
    width: 100%;

    &__item-wrapper {
        // Position relative is added so we can get the relative positioning of the items
        position: relative;
        width: 100%;

        .rf-VariableMatrix__item + .rf-VariableMatrix__item {
            margin-top: spacing(1);
        }
    }

    &__item {
        display: block;
        transition: opacity ease-in-out 225ms;
    }

    &__lines,
    &__lines-placeholder {
        flex-shrink: 0;
    }

    &__column {
        width: 100%;
    }

    &__column-content-wrapper {
        display: flex;
    }

    &__column-title {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.71;
        color: $color-grey-65;
        margin-bottom: spacing(1, -6px);
    }

    &__add-button {
        margin-top: spacing(2, 4);
    }

    &--is-hovered {
        .rf-VariableMatrix__item {
            opacity: 0.4;

            &--active {
                opacity: 1;
            }
        }
    }
}
