.rf-TopicAssessmentIconContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    width: 32px;
    height: 32px;
    display: flex;

    &__button {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        transition: transform 0.2s;

        .rf-Button__inner {
            height: 100%;
            justify-content: center;
        }

        &:hover {
            transform: scale(1.2);
        }
    }

    &__popper {
        .rf-Popper__content {
            min-width: 146px;
            padding-bottom: 0;
        }

        .rf-Popper__item-container {
            margin: spacing(1);
        }
    }

    .rf-PopperContainer {
        width: 100%;
        height: 100%;
    }

    &__option {
        width: 100%;
        padding: spacing(1) spacing(2);
    }

    &__tooltip {
        width: 100%;
        height: 100%;

        &-content {
            width: 290px;
            max-height: 300px;
            padding: spacing(2);

            .rf-Paragraph {
                text-align: left;
            }
        }
    }

    &__description {
        font-size: 12px;

        * {
            text-align: left;
        }
    }
}
