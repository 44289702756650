.rf-Logo {
    @import 'styles/variables/layout';

    svg {
        display: inline-block;
        width: 112px;
        height: 40px;
    }

    &--is-flex {
        display: flex;
    }

    &--is-small svg {
        width: 70px;
        height: 22px;
    }

    // &--is-extra-small svg {}

    &--is-icon-only {
        svg {
            width: 40px;
            height: 40px;
        }
    }

    &--is-icon-only.rf-Logo--is-small svg {
        width: 32px;
        height: 32px;
    }

    &--is-icon-only.rf-Logo--is-extra-small svg {
        width: 20px;
        height: 20px;
    }

    &--is-icon-only.rf-Logo--is-tiny svg {
        width: 12px;
        height: 12px;
    }

    &--inline {
        margin-left: spacing(0.5);
    }
}
