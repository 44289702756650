.rf-ArticleTitle {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    font-size: 30px;
    color: $color-text-header;
    font-weight: 700;
    position: relative;
    padding-bottom: spacing(1.5);
    white-space: normal;
    hyphens: auto;
    display: block;
    line-height: 1.33;

    &--subtle {
        color: $color-grey-80;
    }

    &__suffix {
        margin-left: 4px;
    }

    &__articletitle-wrapper {
        max-width: 1072px;
    }

    &--has-suffix {
        vertical-align: sub;
    }

    &--has-suffix &__divider {
        margin-right: 12px;
    }
}
