.rf-TableListItem {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';
    transition: opacity $animation-appear;
    user-select: none;

    .rf-Checkbox {
        min-height: auto;
    }
}
