.rf-ControlTypeCheckboxes {
    @import 'styles/variables/layout';

    white-space: normal;

    .rf-Checkbox {
        display: inline-flex;
        vertical-align: middle;
        width: 50%;

        &__label {
            margin-left: spacing(1);

            .rf-Icon {
                font-size: 16px;
                margin-right: spacing(1);
            }
        }
    }
}
