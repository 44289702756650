.rf-TranslationInProgressLabel {
    @import 'styles/variables/colors';

    &__text {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.5;
        color: $color-ruler-blue;
    }

    &__logo {
        color: $color-ruler-blue;

        & > i {
            width: 60px;
            height: 25px;
        }

        svg {
            width: 60px;
            height: 25px;
        }
    }
}
