@import 'styles/variables/colors';
@import 'styles/variables/animations';
@import 'styles/variables/layout';

.rf-InboxLabel {
    display: flex;
    flex-wrap: nowrap;
    color: $color-text;

    .rf-IconLabel__icon {
        color: $color-text;
        font-size: 12px;
        margin-right: spacing(1);
    }

    &--is-high-impact {
        .rf-IconLabel__icon {
            color: $color-ui-red;
        }
    }

    &--is-medium-impact {
        .rf-IconLabel__icon {
            color: $color-ui-orange;
        }
    }

    &--is-low-impact {
        .rf-IconLabel__icon {
            color: $color-grey-80;
        }
    }

    &.rf-IconLabel--is-link {
        text-decoration: none;

        &:hover {
            color: $color-ruler-blue;
            transition: $animation-default;

            svg * {
                fill: $color-ruler-blue;
            }
        }
    }
}
