.rf-FrameworkIconList {
    @import 'styles/variables/layout';

    display: inline-flex;

    .rf-ProfileIcon {
        cursor: default;
        &:active {
            transform: none;
        }

        .rf-Icon {
            cursor: default;
        }
    }

    &--align-left {
    }

    &--align-right {
        justify-content: flex-end;
    }
}
