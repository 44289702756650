.rf-InlineTaskWidget {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';

    position: fixed;
    display: flex;
    left: 0;
    right: 0;
    bottom: 0;
    padding-left: 228px;
    justify-content: center;
    z-index: 997; // below modals
    pointer-events: none;

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 40px;
        background: #fff;
        border-radius: 4px;
        user-select: none;
        padding: spacing(1, 1) spacing(2);
        cursor: pointer;
        pointer-events: all;
        border: 1px solid $color-grey-40;
    }

    &__employees-container {
        margin-left: spacing(2);
    }

    &__action-button {
        margin-left: spacing(4);
        font-weight: 500;
    }
}
