.rf-TombstoneInner {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    border-radius: 2px;
    background-color: $color-tombstone-element;
    display: inline-block;

    &--is-round {
        border-radius: 100%;
    }
}
