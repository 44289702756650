.rf-CustomerFeedsTable {
    @import 'styles/variables/colors';

    .rf-Table__body {
        .rf-Table__row {
            .rf-Table__cell {
                border: none;
                border-bottom: 1px solid $color-grey-40;
            }

            &:first-child {
                .rf-Table__cell {
                    border-top: 1px solid $color-grey-40;
                }
            }
        }
    }
}
