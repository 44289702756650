.rf-BulkAlertActionContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__selection-box {
        .rf-EditInboxItemImpactActionButton__icon {
            padding: spacing(1, 1);

            .rf-Button__inner {
                .rf-Icon {
                    color: $color-white;
                    margin-bottom: 0;
                }
            }
            &:hover {
                background-color: $color-ruler-blue-light;
            }
        }
    }
}
