.rf-SelectDepartmentForExportModal {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__radio {
        background-color: $color-background-light;
        padding: spacing(3);
        padding-left: spacing(3, 2px);

        & + .rf-ComplianceExportModal__radio {
            margin-top: spacing(1);
        }
    }

    &__radio-label-wrapper {
        display: flex;
        align-items: center;
    }

    &__radio-label {
        margin-left: spacing(1);
        font-size: 14px;
        font-weight: 600;
        width: 140px;
        display: inline-block;
        flex-shrink: 0;
    }

    &__departments {
        margin-left: spacing(5);
    }
}
