.rf-OperationsCheckboxList {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    position: relative;

    .rf-FieldSet & .rf-Row:last-child {
        margin-top: 0;
    }

    &__checkbox {
        border-bottom: 1px solid $color-grey-40;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $color-grey-95;
        padding: spacing(1.5) 0;

        &.rf-Checkbox {
            min-height: 0;
            width: 100%;

            .rf-Checkbox__checkbox {
                width: 18px;
                height: 18px;
            }

            .rf-Checkbox__label {
                margin-left: 16px;
            }
        }
    }

    .rf-Row:last-child {
        .rf-OperationsCheckboxList__checkbox {
            border: none;
        }
    }

    .rf-Row:first-child .rf-OperationsCheckboxList__checkbox {
        padding-top: spacing(0.25);
    }

    &--no-underline {
        .rf-OperationsCheckboxList__checkbox {
            border-bottom: none;
        }
    }
}
