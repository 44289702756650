.rf-FilterButton {
    @import 'styles/variables/colors';

    &::after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $color-ruler-blue;
        position: absolute; // depends on .rf-Button's position: relative; property
        top: -3px;
        right: -3px;
        opacity: 0;
        transform: scale(0);
        transition: transform 150ms, opacity 150ms;
    }

    &--is-filter-open {
        background-color: $color-grey-10;
        color: $color-ruler-blue;
    }

    &--is-filter-active {
        &::after {
            opacity: 1;
            transform: scale(1);
        }
    }
}
