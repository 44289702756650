.rf-PreviewModal {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    position: relative;
    background: #fff;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    width: 744px;
    z-index: 9999;

    &__close {
        position: absolute;
        top: 26px;
        right: 26px;

        &.rf-Button--is-icon-button .rf-Icon.rf-Icon--close-modal.rf-Button__icon {
            font-size: 12px;
        }
    }

    &__content {
        padding: 96px;
    }
}
