.rf-Attention {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    background-color: $color-ui-orange-pale;
    border-radius: 4px;
    padding: spacing(1);

    &__icon {
        color: $color-ui-orange;
        padding-top: spacing(1);
    }

    &__text {
        color: $color-ui-orange;
    }

    &--blue-background {
        background-color: $color-ruler-pale;
    }

    &--no-icon {
        padding: spacing(1) spacing(2);
    }
}
