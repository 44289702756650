.rf-TopicDesignAndEffectivenessLinkedArticlesModal {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__select-all-container {
        width: 100%;
        border-bottom: 1px solid $color-grey-40;
        padding-bottom: spacing(1);
    }

    &__icon-container {
        padding: spacing(2);
        background-color: $color-ruler-pale;
        border-radius: 4px;
    }

    &__container {
        overflow-y: auto;
        max-height: 580px;
        padding-right: spacing(5);

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-grey-65;
            border-radius: 2px;
        }
    }

    &__link-list {
        .rf-Checkbox {
            min-height: unset;
        }

        &:nth-child(n + 2) {
            margin-top: spacing(5);
        }
    }

    &__expired {
        margin-left: spacing(2.5);

        &--is-editing {
            margin-left: spacing(4.5);
        }
    }
}
