@import 'styles/variables/defaults';
@import 'styles/variables/colors';
@import 'styles/variables/layout';

.rf-MetaCard {
    border-radius: $default-border-radius;
    background-color: $color-ruler-pale;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    padding: spacing(2) spacing(3) spacing(2, 2) spacing(3);
    position: relative;
    flex: 1;
    display: block;
    text-decoration: none;

    &__title {
        font-size: 14px;
        font-weight: 600;
        color: $color-grey-95;
    }

    > * + * {
        margin-top: spacing(0.5);
    }
}
