.rf-ExpiredArticleInfoBlock {
    // width: calc(100% + 16px);
    // position: relative;
    // top: -8px;
    // left: -8px;
    border-radius: 4px;
    padding: 8px;
    background-color: #fff3e5;
    margin-bottom: 8px;

    &__icon {
        color: #ff9001;
        font-size: 14px;
    }

    &__expired-at {
        color: #ff9001;
        font-weight: 500;
    }

    &__delete-button {
        color: #ff9001;
        font-size: 14px;
        font-weight: 500;

        &:hover {
            text-decoration: underline;
        }
    }
}
