.rf-Row {
    @import 'styles/variables/layout';

    display: flex;
    flex-direction: row;
    align-items: center;

    & > * + * {
        margin-left: spacing(2);
    }

    &--even-space {
        > * {
            flex: 1;
        }
    }

    &--flex-start {
        align-items: flex-start;
    }

    &--space-between {
        justify-content: space-between;
    }

    &--align-center {
        justify-content: center;
    }

    &--align-right > *:first-child {
        margin-left: auto;
    }

    &--stretch-children,
    &--stretch-children .rf-RowFraction {
        align-items: stretch;
    }

    &--small-spacing {
        & > * + * {
            margin-left: spacing(1);
        }
    }

    &--extra-small-spacing {
        & > * + * {
            margin-left: spacing(0.5);
        }
    }

    &--no-spacing {
        & > * + * {
            margin: 0;
        }
    }

    &--wrap {
        position: relative;
        top: spacing(-0.5);
        left: spacing(-0.5);
        flex-wrap: wrap;

        > * {
            margin: spacing(0.5);
        }
    }

    &--full-width {
        width: 100%;
    }
}
