.rf-WidgetContainer {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    height: 100%;
    width: 360px;
    margin: auto;
    padding: spacing(8) spacing(3) spacing(2, 2);
    border-radius: 8px;
    background-color: $color-white;
    position: relative;

    &__opaque-layer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 8px;
        background-color: $color-white;
        z-index: 1;
        opacity: 0.8;
    }

    &__header {
        z-index: 2;
        position: absolute;
        top: 18px;
        width: calc(100% - 48px); // - (paddingRight + paddingLeft)
    }

    &__buttons {
        position: absolute;
        right: 16px;
        bottom: 16px;
        width: 70px;
        height: 32px;
        border-radius: 16px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px $color-grey-40;
        background-color: $color-white;
        z-index: 2;
    }

    &__edit-button {
        height: 100%;
        padding-top: 0;
        padding-left: spacing(1, 1);
        padding-right: spacing(1);
        border-right: 1px solid $color-profile-grey;
        border-radius: 16px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &.rf-Button--is-not-styled:hover,
        .rf-Button--is-not-styled:active,
        .rf-Button--is-not-styled:focus {
            border-right: 1px solid $color-profile-grey;
            background-color: $color-grey-40;
        }

        svg {
            font-size: 15px;
        }
    }

    &__delete-button {
        height: 100%;
        padding-top: 0;
        padding-left: spacing(1);
        padding-right: spacing(1, 1);

        border-radius: 16px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &.rf-Button--is-not-styled:hover,
        .rf-Button--is-not-styled:active,
        .rf-Button--is-not-styled:focus {
            border-right: 1px solid $color-profile-grey;
            background-color: $color-grey-40;
        }

        svg {
            font-size: 15px;
        }
    }

    &--subtle {
        background-color: $color-ruler-pale-dark;

        .rf-WidgetContainer__opaque-layer {
            background-color: $color-ruler-pale-dark;
        }
    }

    &--has-blue-background {
        background-color: $color-ruler-blue;

        .rf-WidgetContainer__opaque-layer {
            background-color: $color-ruler-blue;
        }
    }

    &--is-clickable {
        cursor: pointer;
    }

    &--is-empty {
        border: dashed 2px $color-widget-light-grey;
        padding: spacing(3.5) spacing(5);
        background-color: transparent;

        .rf-WidgetContainer__opaque-layer {
            background-color: transparent;
        }
    }

    &--is-detail {
        padding-top: spacing(2, 2);

        .rf-WidgetContainer__header {
            right: 8px;
            width: auto;
            top: -10px;
        }
    }
}
