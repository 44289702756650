.rf-CheckboxList {
    @import 'styles/variables/colors';

    &--disabled {
        opacity: 0.5;
    }

    &__checkbox {
        border-bottom: 1px solid $color-grey-40;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: $color-grey-95;
        padding: 16px 8px;

        &--invalid {
            .rf-Checkbox__label {
                color: $color-ui-red;
            }
        }

        &:first-of-type {
            padding-top: 5px;
        }

        &.rf-Checkbox {
            min-height: 0;
            width: 100%;

            .rf-Checkbox__checkbox {
                width: 18px;
                height: 18px;
            }

            .rf-Checkbox__label {
                margin-left: 16px;
            }
        }
    }
}
