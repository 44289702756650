.rf-IconList {
    @import 'styles/variables/layout';

    display: flex;
    flex-wrap: wrap;

    > * {
        margin: spacing(2);
    }
}
