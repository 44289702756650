.rf-TaskCounter {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    display: flex;
    width: 100%;
    position: relative;

    &__attention-icon {
        font-size: 12px;
        color: $color-ui-red;
        margin-right: spacing(2);
    }

    &__icon-container {
        padding-right: spacing(4);
    }

    &__count {
        white-space: nowrap;
    }
}
