.rf-ExportWidget {
    @import 'styles/variables/colors';

    &__button {
        width: 100%;

        .rf-Button__inner {
            margin: auto;
        }
    }

    .rf-Select__menu-list {
        height: 100px;
        overflow: auto;

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-grey-65;
            border-radius: 2px;
        }
    }
}
