.rf-HierarchyListItem {
    @import 'styles/variables/layout';

    display: block;

    &--indent-1 {
        margin-left: spacing(4);
    }

    &--indent-2 {
        margin-left: spacing(8);
    }
}
