.rf-PageDetailContent {
    line-height: 1.71;

    > * + * {
        margin-top: 24px;
    }

    .rf-Paragraph {
        line-height: 1.71;
    }
}
