.rf-EmployeeOverviewView {
    @import 'styles/variables/layout';

    &__notice {
        font-size: 12px;
    }

    &__sticky-footer {
        margin-left: spacing(-2);
        position: sticky;
        bottom: 0;
    }
}
