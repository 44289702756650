.rf-InlineTextIcon {
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    margin-left: 0.5em;

    &__icon {
        display: inline-block;
    }
}
