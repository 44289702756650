@import 'styles/variables/colors';
@import 'styles/variables/layout';

.rf-RiskGraphIndicatorTooltip {
    max-width: 200px;
    margin: 6px;
    text-align: left;

    > * + * {
        margin-top: spacing(0.5);
    }

    &__title {
        font-size: 12px;
        font-weight: 600;
    }

    &__description {
        font-size: 10px;
    }

    &__assessment-label .rf-IconLabel__label,
    &__assessment-label .rf-IconLabel__icon {
        font-size: 10px;
        font-weight: 600;
        color: $color-grey-80;
    }
}
