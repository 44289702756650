.rf-TextEditor {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    --ck-color-toolbar-background: $color-white;

    // This hides the bulletlist dropdown option
    .ck-list-styles-dropdown:last-child .ck-splitbutton__arrow {
        display: none;
    }

    p,
    ul,
    ol,
    strong,
    b {
        font-size: 14px;
        color: $color-text;
        white-space: pre-wrap;
    }

    ol,
    ul {
        padding-left: spacing(4);
    }

    ol {
        list-style: decimal; // override list-style: none from base.scss
    }

    ul {
        list-style: disc; // override list-style: none from base.scss
    }

    li {
        margin-top: 6px;
    }

    strong,
    b {
        font-weight: 500;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 500;
    }

    h1 {
        font-size: 15px;
    }

    a {
        color: $color-ruler-blue;
        text-decoration: none;
    }

    .ck-content .table {
        margin: initial 0;
        width: 100%;

        table {
            width: 100%;
            font-size: 12px;
            text-align: left;
            border: 1px solid $color-grey-80;

            th {
                font-weight: 500;
                padding: spacing(1.5);
                background-color: unset;
            }

            td {
                padding: spacing(1.5);
            }
        }
    }

    .ck-content > * + * {
        margin-top: spacing(1.5);
    }

    .ck-placeholder {
        color: $color-input-placeholder;
    }

    .editor__editable,
    .ck-editor[role='application'] .ck.ck-content {
        width: 100%;
        background: $color-white;
        line-height: 1.6;
        padding: spacing(1) spacing(3);
    }

    .ck-editor[role='application'] {
        overflow: auto;

        *:not(table) {
            border: none;
        }
    }

    .ck.ck-editor__main[role='presentation'] {
        border: 1px solid $color-input-border;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border: 1px solid $color-input-border;
        border-bottom: none;
    }

    .ck.ck-toolbar {
        padding-left: spacing(1.5);

        .ck.ck-toolbar__separator {
            margin: spacing(1);
            margin-left: spacing(0.5);
            background: $color-input-border;
        }
    }

    .ck-content {
        min-height: 200px;
    }

    .ck.ck-editor__editable {
        background: $color-white;
        width: 100%;
    }

    .ck.ck-editor__editable {
        position: relative;
        z-index: 10;
    }

    .editor-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        position: relative;
        justify-content: center;
    }
}
