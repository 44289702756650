.rf-ChatPlaceholder {
    @import 'styles/variables/colors';

    height: 60px;
    width: 60px;
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 100;
    background-color: $color-ruler-blue;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
        background-color: $color-ruler-blue-darken-10;
    }
}
