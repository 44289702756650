.rf-FilterOption {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    .rf-Field__content {
        min-width: 306px;
        max-width: 450px;
    }

    &--is-sub {
        background: $color-grey-10;
        padding: 8px 0;
        padding-right: 8px;

        .rf-Field__label-input {
            padding-left: 16px;
        }
    }
}
