.rf-AppLayout {
    @import 'styles/variables/defaults';
    @import 'styles/variables/layout';

    display: block;
    box-sizing: border-box;
    min-height: 100%;
    position: relative;
    width: 100%;
    min-height: 100%;
    padding: 0 spacing(5);
    padding-left: $default-menu-width + spacing(5);

    &--has-consultant-header {
        margin-top: 42px;

        .rf-TabletProfileDropdown {
            top: 71px;
        }
    }

    &--is-consultant-layout {
        padding-left: $consultant-menu-width + spacing(5);
    }
}
