.rf-TaskStatus {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__open {
        display: inline-block;
        font-size: 20px;
        white-space: nowrap;
        font-weight: 800;
        color: $color-grey-95;
    }

    &__closed {
        display: inline-block;
        font-size: 16px;
        color: $color-grey-80;
        margin-left: spacing(1);
    }
}
