.rf-ActiveDepartment {
    font-size: 14px;
    font-weight: 500;

    // ellipsis styling
    max-width: 76px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
