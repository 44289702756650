.rf-TopicDesignAndEffectivenessTableSummary {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    .rf-Table__table {
        table-layout: fixed;
    }

    .rf-Table__header {
        &:first-child {
            width: 52%;
            padding-left: 0;
        }

        &:nth-child(2) {
            width: 20%;
        }

        &:nth-child(3) {
            width: 12%;
        }

        &:nth-child(4) {
            width: 16%;
        }
    }

    .rf-Table__body {
        .rf-Table__row {
            .rf-Table__cell {
                padding: spacing(2, -2) spacing(0.5);

                &:first-child {
                    padding-left: spacing(2, -2);
                }
            }
        }
    }

    &__name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
