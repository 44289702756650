.rf-DepartmentsTableWidget {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    bottom: 32px;
    left: 53vw;
    position: fixed;
    height: 36px;
    display: inline-flex;
    justify-content: space-around;
    background-color: $color-white;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
    border: solid 2px $color-ui-orange;
    border-radius: 16px;
    z-index: 2;

    &__widget-icon {
        font-weight: 600;
        color: $color-ui-orange;
        padding-left: spacing(1, -2);
    }

    &__widget-text {
        font-weight: 600;
        color: $color-ui-orange;
        padding-right: spacing(2);
    }
}
