.rf-TopicNotesAndControlsTableContainer {
    @import 'styles/variables/layout';

    display: flex;
    flex-direction: column;

    &__name-tooltip {
        display: block;
    }

    &__name-icon {
        display: inline-block;
        margin-right: spacing(1);
    }

    &__name-button {
        display: grid;
        font-weight: inherit;

        .rf-Button__inner {
            display: block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__name-container {
        display: block;
    }

    &__name {
        .rf-ControlLabel {
            &__icon-label {
                width: 100%;
            }

            &__content {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                font-weight: 500;
            }

            &__attention-icon {
                display: inline-block;
            }
        }
    }
}
