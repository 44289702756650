.rf-PopperButtonItem {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    padding: spacing(1) spacing(2);
    border-radius: 4px;
    width: 100%;

    &--only-child {
        margin: spacing(1) 0;
    }

    &:hover {
        background-color: $color-ruler-pale;

        > .rf-Button__inner .rf-PopperButtonItem__label {
            font-weight: 600;
            color: $color-ruler-blue;
        }
    }

    > .rf-Button__inner {
        justify-content: space-between;

        > .rf-Icon--arrow-right {
            font-size: 12px;
        }
    }

    &--wider {
        width: 185px;
    }

    &--has-nested-items {
        cursor: default;
    }
}
