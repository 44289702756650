.rf-ReleaseUpdateButton {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    background: $color-ruler-blue;
    border: none;
    height: 32px;
    color: white;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    padding: 4px;
    padding-left: 8px;

    &__count {
        background: white;
        color: $color-ruler-blue;
        height: 12px;
        width: 12px;
        line-height: 12px;
        font-size: 8px;
        border-radius: 100%;
        font-weight: bold;
        text-align: center;
    }

    &__text {
        white-space: nowrap;
        margin-right: 4px;
        margin-top: -2px;
    }

    &__close {
        font-weight: normal;

        svg {
            font-size: 11px;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    > button {
        padding: 0.3rem;
        margin-left: -0.2rem;
    }
}
