.rf-ColorSchemeGroup {
    @import 'styles/variables/layout';

    display: flex;
    flex-direction: column;
    border-radius: 4px;
    flex-grow: 1;
    width: 100%;
    margin-right: spacing(2);
    border-radius: 0;

    :first-child {
        border-radius: 4px 4px 0 0;
    }

    :last-child {
        border-radius: 0 0 4px 4px;
    }

    :only-child {
        border-radius: 4px;
    }
}
