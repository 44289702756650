.rf-RulerEmployeeWarning {
    @import 'styles/variables/layout';

    &__text-container {
        padding: spacing(2);
    }

    &__logo {
        align-items: center;
        display: inline-flex;

        svg {
            width: 24px;
            height: 24px;
        }
    }
}
