.rf-ArticleTitleBreadCrumbs {
    @import 'styles/variables/colors';
    display: flex;
    align-items: center;
    font-size: 14px;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    max-width: 470px;

    &__separator {
        color: $color-grey-40;
        display: inline-block;
    }

    &__tooltip {
        width: 100%;
    }

    &__name {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
