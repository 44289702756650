.rf-OptionsButton {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;

    &.rf-Button--is-icon-button {
        .rf-Icon.rf-Button__icon {
            font-size: 26px;
            color: white;
        }
    }
}
