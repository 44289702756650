.rf-FetchMoreButton {
    @import 'styles/variables/layout';

    text-align: center;

    &--aligned-left {
        text-align: left;
    }

    &__button {
        font-size: 14px;
        font-weight: 500;

        .rf-Icon.rf-Button__icon {
            font-size: 14px;
            margin-top: spacing(1, -4px);
        }
    }
}
