.rf-ComplianceCardList {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    .rf-LinkedRiskCard {
        margin-bottom: spacing(1);
    }

    &__title {
        font-size: 14px;
        font-weight: 600;
        color: $color-grey-65;
        margin-bottom: 2px;
    }
}
