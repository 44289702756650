.rf-TopicMonitoringReportsTable {
    @import 'styles/variables/layout';

    &__container {
        .rf-Table__head {
            .rf-Table__header:first-of-type {
                width: 73%;
            }
        }
    }
}
