.rf-SignalingWidget {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &.rf-WidgetContainer {
        padding: spacing(3, -2) spacing(3);
    }

    .rf-WidgetContainer__header {
        width: calc(100% - 170px);
    }

    &__total {
        width: 122px;
        height: 40px;
        justify-content: center;
        border-radius: 4px;
        background-color: $color-widget-dark-blue;
        color: $color-white;

        position: absolute;
        right: 24px;
        top: 20px;
    }

    &__total-count,
    &__total-text {
        color: $color-white;
        line-height: 1;
    }

    &__content-container {
        margin-top: spacing(6, 2);
        height: 96px;
        border-radius: 4px;
        background-color: $color-white;

        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &__info {
        border-right: 1px solid $color-grey-20;
        padding-top: spacing(1.5);
        height: 64px;
        margin-left: spacing(2);
    }

    &__open-items {
        margin-left: spacing(2, 2);

        .rf-SignalingWidget__count,
        .rf-SignalingWidget__text {
            color: $color-ruler-blue;
        }
    }

    &__sent-items {
        margin-left: spacing(2, 2);
    }

    &__count-link {
        margin-left: 0;
    }

    &__count {
        font-size: 36px;
        line-height: 1;
        font-weight: bold;
    }
}
