@font-face {
    font-family: 'Barlow';
    src: local('Barlow Italic'), local('Barlow-Italic'),
        url('../assets/fonts/Barlow/Barlow-Italic.woff2') format('woff2'),
        url('../assets/fonts/Barlow/Barlow-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: local('Barlow Regular'), local('Barlow-Regular'),
        url('../assets/fonts/Barlow/Barlow-Regular.woff2') format('woff2'),
        url('../assets/fonts/Barlow/Barlow-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: local('Barlow Medium Italic'), local('Barlow-MediumItalic'),
        url('../assets/fonts/Barlow/Barlow-MediumItalic.woff2') format('woff2'),
        url('../assets/fonts/Barlow/Barlow-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: local('Barlow Medium'), local('Barlow-Medium'),
        url('../assets/fonts/Barlow/Barlow-Medium.woff2') format('woff2'),
        url('../assets/fonts/Barlow/Barlow-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: local('Barlow SemiBold Italic'), local('Barlow-SemiBoldItalic'),
        url('../assets/fonts/Barlow/Barlow-SemiBoldItalic.woff2') format('woff2'),
        url('../assets/fonts/Barlow/Barlow-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: local('Barlow SemiBold'), local('Barlow-SemiBold'),
        url('../assets/fonts/Barlow/Barlow-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/Barlow/Barlow-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: local('Barlow Bold Italic'), local('Barlow-BoldItalic'),
        url('../assets/fonts/Barlow/Barlow-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/Barlow/Barlow-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow';
    src: local('Barlow Bold'), local('Barlow-Bold'),
        url('../assets/fonts/Barlow/Barlow-Bold.woff2') format('woff2'),
        url('../assets/fonts/Barlow/Barlow-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
