.rf-TaskOpenClosedCounter {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';

    &__tasks-container {
        display: flex;
        align-items: center;
    }

    &__attention-icon {
        font-size: 12px;
        margin-right: 5px;
        color: $color-ui-red;
    }

    &__open-count,
    &__task-empty-title {
        white-space: nowrap;
        font-size: 14px;
        color: $color-grey-95;
    }

    &__tasks-container:hover,
    &__tasks-container:focus {
        .rf-TaskWidget__open-count,
        .rf-TaskWidget__closed-count {
            color: $color-ruler-blue;
        }
    }

    &__open-count {
        transition: color $animation-appear;
        font-weight: 600;
    }

    &__closed-count {
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
        color: $color-grey-80;
        margin-left: 4px;
        transition: color $animation-appear;
    }
}
