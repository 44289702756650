.rf-CustomerFrameworkIconList {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    display: flex;

    > * + * {
        margin-left: spacing(1);
    }

    &__active-frameworks {
        display: flex;

        > * + * {
            margin-left: spacing(1);
        }
    }

    &__counter {
        font-size: 12px;
        font-weight: 600;
        padding: spacing(1, -3px);
        align-items: center;
        line-height: 1;
        color: $color-grey-80;
        white-space: nowrap;
        background-color: $color-ruler-pale;
        border: 1px solid $color-ruler-pale-dark;
        border-radius: 100%;
        display: flex;
    }

    &__tooltip-list {
        padding: spacing(1) spacing(0.5);
    }

    &__framework-icon {
        display: flex;
    }
}
