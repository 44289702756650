.rf-RadarItemContent {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    &__title {
        font-weight: 500;
        font-size: 16px;
    }

    &__content {
        display: flex;
        margin-bottom: spacing(2);
        justify-content: space-between;

        > * + * {
            margin-left: spacing(1);
        }
    }

    &__profile-list {
        min-width: 76px; //based on the width and spacing of 3 profile-icons
        justify-content: flex-end;
        align-content: flex-start;

        > * {
            margin-bottom: spacing(1);
        }
    }

    &__meta-data {
        font-size: 14px;

        span {
            font-weight: 500;
        }
    }

    &__last-change {
        margin-top: spacing(1);
    }

    &__title-icons-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &__following-icon {
        margin-right: spacing(1, -2px);
        color: $color-icon;
        &:hover {
            color: $color-text;
        }
    }
}
