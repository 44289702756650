.rf-Notifications {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';
    @import 'styles/variables/layout';

    position: fixed !important;
    z-index: 9999;
    bottom: 53px;
    right: 50%;
    transform: translateX(-50%);
    will-change: transform;

    &__notification {
        position: absolute;
        right: 0;
        margin-right: -253px; // width/2 + 16px padding
        bottom: 0;
        width: 474px;
        height: 52px;
        padding: 10px 16px;
        color: #fff;
        font-size: 14px;
        line-height: 1.43;
        border-radius: 4px;
        margin-bottom: 5px;
        will-change: transform;
        transition: 200ms bottom ease-in-out;

        &--severity-success {
            background-color: $color-ui-green;
        }
        &--severity-success-with-undo {
            background-color: $color-ui-green;
        }
        &--severity-warning {
            background-color: $color-ui-red;
        }
        &--severity-info {
            background-color: $color-ui-orange;
        }
        &--severity-error {
            background-color: $color-ui-red;
        }
    }

    &__notification-container {
        height: 100%;
    }

    &__undo-button.rf-Button--is-not-styled {
        padding: spacing(1) spacing(2);
        border: 1px solid $color-white;

        &:hover,
        &:active,
        &:focus,
        &.rf-Button--is-disabled {
            border: 1px solid $color-white;
        }

        &.rf-Button--is-disabled {
            text-decoration: line-through;
        }
    }

    &__notification-animation {
        &-appear {
            opacity: 0;
            transform: translateY(100%);
        }

        &-appear-active {
            transition: opacity $animation-appear, transform $animation-appear;
            transform: translateY(0);
            opacity: 1;
        }

        &-enter {
            opacity: 0;
            transform: translateY(100%);
        }

        &-enter-active {
            transition: opacity $animation-appear, transform $animation-appear;
            transform: translateY(0);
            opacity: 1;
        }

        &-enter-done {
            opacity: 1;
            transform: translateY(0);
        }

        &-exit {
            opacity: 1;
        }

        &-exit-active {
            opacity: 0;
            transform: translateY(100%);
            transition: opacity $animation-appear, transform $animation-appear;
        }

        @media screen and (prefers-reduced-motion: reduce) {
            &-appear {
                opacity: 0;
                transform: scale(1);
            }

            &-enter {
                opacity: 0;
                transform: scale(1);
            }

            &-enter-done {
                opacity: 1;
                transform: scale(1);
            }

            &-exit {
                opacity: 1;
                transform: scale(1);
            }

            &-exit-active {
                opacity: 0;
                transform: scale(1);
            }
        }
    }
}
