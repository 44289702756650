.rf-InternalAlertTimelineLog {
    @import 'styles/variables/layout';

    &__content-styling {
        margin-top: spacing(1);
        margin-bottom: spacing(3);
    }

    &__spacing {
        margin-top: spacing(1);
    }

    &__title-styling {
        margin-left: spacing(1);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }

    &__truncated-note {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-top: 0px;
    }
}
