.rf-AddWidgetModal {
    @import 'styles/variables/layout';

    width: 590px;

    &__section-container {
        display: grid;
        grid-template-columns: 35% 65%;
        gap: spacing(3) spacing(3);
    }

    &__image-container {
        position: relative;
    }

    &__image {
        width: 100%;
    }

    &__text-container {
        width: calc(100% - 24px);
    }

    &__description {
        white-space: pre-line;
    }
}
