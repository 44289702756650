.rf-TopicAssessmentLinkedControlsTable {
    @import 'styles/variables/colors';

    .rf-Table__table {
        table-layout: fixed;
    }

    .rf-Table__header {
        &:first-child {
            width: 70%;
            padding-left: 0;
        }

        &:nth-child(3) {
            width: 5%;
        }
    }

    .rf-Table__cell {
        border: none;
        padding-top: 0;
        padding-bottom: 0;

        &:first-child,
        &:last-child {
            border: none;
        }
    }

    &__label {
        width: 100%;

        .rf-ControlLabel {
            &__icon-label {
                width: 100%;

                .rf-IconLabel__label {
                    width: 100%;
                }
            }

            &__content {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }

            &__attention-icon {
                display: inline-block;
            }
        }

        &--single-indent {
            .rf-ControlLabel__content {
                width: 90%;
            }
        }

        &--double-indent {
            .rf-ControlLabel__content {
                width: 85%;
            }
        }
    }

    &__icon {
        font-size: 20px;
        color: $color-grey-65;
        height: 48px;

        &--current {
            color: $color-grey-95;
        }
    }

    .rf-Table__row--is-inactive {
        background-color: $color-ruler-pale;
    }
}
