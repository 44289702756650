@import 'styles/variables/colors';
@import 'styles/variables/layout';

.rf-CustomerFrameworkToggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: spacing(1);

    > * + * {
        margin-left: spacing(1);
    }

    &__framework-metadata {
        display: flex;
        align-items: center;
        flex: 1;

        > * + * {
            margin-left: spacing(1);
        }
    }

    &__framework-name {
        font-size: 14px;
        line-height: 1.5;
        color: $color-grey-95;

        // ellipsis styling
        max-width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__framework-toggle {
        display: flex;
    }
}
