.rf-MultiDepthExpandableTable {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    margin-top: spacing(1);

    &__list-item {
        &.rf-ExpandableTableListItem {
            border-top: none;

            &:last-child {
                border-bottom: none;
            }

            &.rf-TableListItem {
                padding: 0;
            }
        }

        .rf-ExpandableTableListItem__inner {
            flex-direction: row-reverse;
            margin-bottom: spacing(1);
            padding: 0 spacing(1);
            height: 56px;
            border-radius: 4px;
            background-color: $color-ruler-pale;

            > :first-child {
                padding-left: spacing(3);
            }

            .rf-Button {
                margin-left: 0;
            }
        }

        &.rf-ExpandableTableListItem--is-expanded {
            .rf-ExpandableTableListItem__inner {
                padding-bottom: 0;
            }

            .rf-ExpandableTableListItem__children {
                margin-bottom: spacing(5);
                border-top: none;
                padding-left: spacing(6);

                .rf-TableListItem {
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-left: spacing(2, 4);

                    &:last-child {
                        border-bottom: 1px solid $color-grey-40;
                    }
                }
            }
        }

        &--is-sub-parent.rf-TableListItem.rf-ExpandableTableListItem.rf-MultiDepthExpandableTable__list-item,
        &--is-sub-parent.rf-TableListItem.rf-ExpandableTableListItem.rf-MultiDepthExpandableTable__list-item:last-child {
            padding-left: 0;
            border: none;

            .rf-ExpandableTableListItem__inner {
                height: 40px;
                background-color: $color-grey-10;
            }
        }
    }

    &__table-list-item {
        &--is-parent {
            height: 56px;
            display: flex;
            border-radius: 4px;
            background-color: $color-ruler-pale;
            margin-bottom: spacing(1, -4);
            padding-left: spacing(7, 4);
        }

        &--is-sub-parent {
            height: 40px;
            display: flex;
            border-radius: 4px;
            background-color: $color-grey-10;
            margin-bottom: spacing(1, -4);
            padding-left: spacing(7, 4);
            border: none !important;
        }
    }

    .rf-MultiDepthExpandableTable__list-item.rf-ExpandableTableListItem--is-expanded
        .rf-ExpandableTableListItem__children
        .rf-TableListItem.rf-MultiDepthExpandableTable__table-list-item--is-sub-parent {
        padding-left: spacing(7, 4);
    }
}
