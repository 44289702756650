.rf-Form {
    @import 'styles/variables/layout';

    .rf-FieldSet {
        max-width: 648px;

        > * + * {
            margin-top: spacing(1);
        }
    }

    > * + *,
    .rf-Modal .rf-Content > * + * {
        margin-top: spacing(2);
    }

    &--is-compact .rf-Field {
        display: block;
        width: 100%;

        &__label-input,
        &__label-heading {
            margin-top: 0;
            font-size: 14px;
            font-weight: 600;
            min-width: auto !important;
            line-height: 1;
            margin-bottom: 6px;
        }

        &__content {
            // margin-top: spacing(1);
        }
    }

    &--has-extra-field-spacing .rf-Modal .rf-Content > .rf-Field + .rf-Field,
    &--has-extra-field-spacing > .rf-Field + .rf-Field {
        margin-top: spacing(2);
    }
}
