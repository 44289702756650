.rf-TopicWidget {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__row-container {
        height: 40px;
        width: 100%;
        border-radius: 4px;
        display: grid;
        grid-template-columns: 20% 80%;

        &--is-notassessed {
            background-color: $color-grey-10;

            .rf-TopicWidget__count {
                color: $color-ruler-blue;
                font-size: 20px;
            }
        }

        &--is-insufficient {
            background-color: $color-widget-light-red;

            .rf-TopicWidget__count {
                color: $color-widget-red;
                font-size: 20px;
            }

            .rf-TopicWidget__text {
                color: $color-widget-red;
            }
        }
    }

    &__count {
        text-align: center;
    }

    &__text {
        font-weight: 500;
        margin-left: 0;
    }
}
