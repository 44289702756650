.rf-NewsSourcesEditListItem {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    padding: spacing(2, 2);
    border: none;
    font-size: 14px;
    border: 1px solid $color-grey-40;
    border-radius: 4px;

    .rf-ExpandableTableListItem:last-child {
        border-bottom: none;
    }
    .rf-ExpandableTableListItem__children {
        border-top: none;
    }

    &.rf-ExpandableTableListItem--is-expanded {
        background-color: $color-grey-10;
    }

    &__group-checkbox {
        font-weight: 600;
    }

    &__source-item {
        font-weight: normal;
        border-bottom: $color-grey-40 1px solid;
        padding: spacing(2, 4) spacing(2) spacing(2, 4) 0;
        margin-left: spacing(2);
        margin-right: spacing(4, -2);
        color: $color-grey-95;
    }
    &__source-item:last-of-type {
        border-bottom: none;
        padding-bottom: 3px;
    }
}
