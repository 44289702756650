.rf-HierarchyControlGroupTile {
    @import 'styles/variables/layout';

    .rf-HierarchyControlTile {
        + .rf-HierarchyControlTile {
            // Todo remove this when implementing grouped tiles
            margin-top: spacing();
        }
    }
}
