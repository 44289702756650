.rf-Sidebar {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';

    width: 340px;
    border-right: 1px solid $color-grey-10;
    font-size: 14px;
    background: white;

    &__container {
        display: flex;
        height: 100%;
        flex-direction: column;
    }

    &__heading-container {
        padding: spacing(7, -5) spacing(6) spacing(3) spacing(6);
    }

    &__backto-container {
        padding: spacing(4) spacing(6) spacing(0) spacing(6);
    }

    &__backto-container + &__heading-container {
        padding-top: spacing(3);
    }

    &__logout-container {
        margin-top: auto;
        display: block;

        padding: spacing(6) spacing(6);
    }

    &__version {
        display: block;
        color: $color-grey-80;
        font-size: 14px;
    }

    &__icon {
        margin-right: spacing(1);
        font-size: 18px;
    }

    &__nav-link,
    &__text-link {
        display: flex;
        align-items: center;
        padding: spacing(2, 2) spacing(6);
        text-decoration: none;
        transition: background $animation-appear;

        &:hover,
        &:focus-within {
            background: rgba($color-grey-10, 0.4);
        }

        &.active {
            background: $color-grey-10;
            font-weight: 500;
        }
    }
}
