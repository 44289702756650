.rf-TextLink {
    @import 'styles/variables/colors';

    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    color: inherit;

    .rf-Icon {
        margin-right: 5px;
    }

    &:hover {
        text-decoration: underline;
        color: $color-text-link;
        .rf-Paragraph {
            color: $color-text-link;
        }
    }

    &--has-icon {
        .rf-Icon {
            display: inline-block;
            vertical-align: top;
        }
    }
}
