// Brand colors
$color-ruler-blue: #1d55ff;
$color-ruler-blue-light: #3d6dff;
$color-ruler-blue-darken-10: darken($color-ruler-blue, 10%);
$color-ruler-blue-darken-15: darken($color-ruler-blue, 15%);

$color-ruler-pale: #f3f6ff;
$color-ruler-pale-dark: #e6ecff;

// Grey colors
$color-white: #fff;
$color-grey-10: #f7f7fa;
$color-grey-15: #eaedf5;
$color-grey-20: #ededf2;
$color-grey-40: #e0e1e4;
$color-grey-65: #bfbfc2;
$color-grey-70: #c4c4c4;
$color-grey-80: #87878a;
$color-grey-95: #3d3d40;

// UI Colors
$color-ui-red: #ff0043;
$color-ui-red-darken-10: darken($color-ui-red, 10%);
$color-ui-red-darken-15: darken($color-ui-red, 15%);
$color-ui-red-pale: #fff2f6;
$color-ui-red-pale-dark: #ffe6ec;

$color-ui-orange: #ff9100;
$color-ui-orange-darken-10: darken($color-ui-orange, 10%);
$color-ui-orange-darken-15: darken($color-ui-orange, 15%);
$color-ui-orange-pale: rgba(255, 136, 0, 0.1);

$color-ui-green: #00e981;
$color-ui-green-darken-10: darken($color-ui-green, 10%);
$color-ui-green-darken-15: darken($color-ui-green, 15%);

// Profile colors
$color-profile-purple: #991b82;
$color-profile-dark-blue: #1f2769;
$color-profile-blue: #0f8ab2;
$color-profile-light-blue: #62e7f7;
$color-profile-green: #5dd39e;
$color-profile-yellow: #ffd46f;
$color-profile-orange: #f89c3d;
$color-profile-red: #f95858;
$color-profile-pink: #f67695;
$color-profile-black: #000000;
$color-profile-grey: #d8d8d8;

// Backgrounds
$color-background: $color-white;
$color-background-light: $color-grey-10;
$color-background-dark: $color-grey-65;
$color-background-blue: $color-ruler-pale;

// Text
$color-text: $color-grey-95;
$color-text-header: #4a4a4a;
$color-text-link: $color-ruler-blue;

// Form & Inputs
$color-input-placeholder: $color-grey-65;
$color-input-text: $color-grey-95;
$color-input-border: $color-grey-40;

// Icons
$color-icon: $color-grey-40;
$color-icon-active: $color-ruler-blue;

// Tombstones
$color-tombstone-background: $color-grey-10;
$color-tombstone-element: $color-grey-40;

// Widgets
$color-widget-light-red: #fff2f5;
$color-widget-red: #ff0043;
$color-widget-light-blue: $color-background-blue;
$color-widget-blue: #0d4be7;
$color-widget-dark-blue: #0c43cf;
$color-widget-light-grey: #ccd5f1;
