.rf-ThemesOverviewTableContainer {
    @import 'styles/variables/layout';

    &__grid-container {
        grid-row-gap: spacing(3.25);

        &.rf-CardList {
            grid-column-gap: spacing(2.25);
        }
    }
}
