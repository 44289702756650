.rf-HierarchyControlTile {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    position: relative;
    background-color: $color-white;
    border-radius: 4px;
    padding: 5px spacing(2);
    height: 48px;
    display: flex;
    align-items: center;
    overflow: hidden;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;

    // Inside shadow on hover (shown on hover)
    &::after {
        content: '';
        position: absolute;
        border-radius: 4px;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    // Control icon
    &__control-icon {
        font-size: 16px;
        margin-right: spacing(1);
    }

    // Unlink button
    &__unlink-button {
        display: inline-block;
        color: $color-ruler-blue;
        white-space: nowrap;
        margin-left: auto;
        transition: 150ms opacity;
        opacity: 0;
        border-radius: 0 4px 4px 0; // For hover background color

        // For better clickable area
        height: 46px;
        padding-left: spacing(2);
        margin-right: spacing(-2) + 1px;
        padding-right: spacing(2);

        &:hover {
            background-color: $color-grey-10;
        }
    }

    &__expired-warning {
        margin-left: spacing(2);
        margin-top: 1px;
    }

    &:hover {
        // Inside shadow (looks like a border)
        &:after {
            box-shadow: $color-grey-40 0px 0px 0px 1px inset;
        }

        .rf-HierarchyControlTile__unlink-button {
            opacity: 1;
        }
    }

    &--is-current-control {
        font-weight: 600;

        // Outside shadow
        box-shadow: 0px 3px 6px 0px rgba(61, 61, 64, 0.12);

        // Blue bar
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            background-color: $color-ruler-blue;
            width: 4px;
        }

        // Current control is not clickable, so don't show hover
        &:hover {
            &:after {
                box-shadow: none;
            }
        }
    }
}
