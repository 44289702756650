.rf-AssessmentAddNormArticlesAndTopicsSelect {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    &__topic-row {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: spacing(1);

        .rf-ExpandableRow__content {
            padding-bottom: 0;
        }

        .rf-ExpandableRow__row-content {
            &:hover {
                .rf-IconLink {
                    color: $color-text;
                }
            }
        }
    }

    &__checkbox {
        min-height: 0;

        .rf-Checkbox__checkbox {
            height: 20px;
            width: 20px;
        }

        .rf-Checkbox__mark-indeterminate {
            left: 5px;
            top: 8px;
        }
    }

    &__topic-title {
        align-items: center;
        display: flex;

        .rf-IconLink {
            margin-left: spacing(2, -5px);
        }
    }

    &__topic-end-wrapper {
        margin-left: auto;
        display: flex;
        align-items: center;

        .rf-AssessmentAddNormArticlesAndTopicsSelect__topic-coverage {
            width: 120px;
            margin-right: 18px;
        }

        .rf-NormArticleCount {
            .rf-Icon {
                font-size: 13px;
                margin-right: 4px;
            }
        }
    }

    .rf-NormArticleCount {
        font-weight: normal;
    }
}
