.rf-ErrorMessage {
    @import 'styles/variables/colors';

    line-height: 1;
    padding: 8px 12px;
    border: 1px solid $color-ui-red;
    border-radius: 4px;
    color: $color-ui-red;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}
