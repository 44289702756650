.rf-Checkbox {
    @import 'styles/variables/colors';

    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 40px;

    &__spinner {
        display: none;
        margin-left: 16px;
    }

    &__input {
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;
        -webkit-appearance: none;
    }

    &__checkbox {
        position: relative;
        width: 16px;
        height: 16px;
        border-radius: 3px;
        background-color: $color-white;
        border: 1px solid #b7b7b7;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        transition: all 0.3s;
    }

    &__label {
        margin-left: 12px;
        font-size: 14px;
        line-height: 1em;
        color: $color-text;
        user-select: none;
        display: flex;
    }

    &:hover &__checkbox {
        border-color: darken(#b7b7b7, 10%);
    }

    &:focus-within &__checkbox {
        border-color: $color-ruler-blue;
    }

    &__mark {
        color: #fff;
        font-size: 12px;
        opacity: 0;
        transform: scale(0.75);
        transition: opacity 0.3s, transform 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    }

    &__mark,
    &__mark-indeterminate {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    &--large {
        .rf-Checkbox__checkbox {
            width: 20px;
            height: 20px;
        }

        .rf-Checkbox__mark {
            font-size: 16px;
        }
    }

    &--is-checked &__mark {
        opacity: 1;
        transform: scale(1);
    }

    &--is-checked &__checkbox,
    &--is-indeterminate &__checkbox {
        background-color: $color-ruler-blue;
        border-color: darken($color-ruler-blue, 5%);
        background-image: none;
    }

    &--is-checked:hover &__checkbox {
        border-color: darken($color-ruler-blue, 5%);
    }

    &--is-indeterminate &__mark {
        opacity: 0;
    }

    &__mark-indeterminate {
        opacity: 0;
        transform: scale(0.75);
        transition: opacity 0.3s, transform 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    }

    &--is-indeterminate &__mark-indeterminate {
        left: 3px;
        top: 6px;
        opacity: 1;
        transform: scale(1);
        width: 8px;
        height: 2px;
        background-color: #fff;
    }

    &--is-loading {
        .rf-Checkbox__spinner {
            display: inline-block;
        }
    }

    &--is-disabled {
        cursor: default;
        .rf-Checkbox__checkbox {
            opacity: 0.3;
            background-color: $color-grey-40;
            border: none;
        }

        .rf-Checkbox__mark {
            color: $color-grey-95;
        }
    }

    &--is-animation-disabled {
        .rf-Checkbox__checkbox,
        .rf-Checkbox__mark,
        &.rf-Checkbox--is-checked .rf-Checkbox__mark,
        .rf-Checkbox__mark-indeterminate,
        &.rf-Checkbox--is-indeterminate .rf-Checkbox__mark-indeterminate {
            transition: none;
            transform: none;
        }
    }
}
