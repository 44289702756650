.rf-MarkTopicsAsNonApplicableFormList {
    @import 'styles/variables/layout';

    margin-top: spacing(4.5);

    &.rf-CardList {
        grid-row-gap: spacing(3.25);
        grid-column-gap: spacing(2.25);
    }
}
