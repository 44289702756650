.rf-Tooltip {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: inline-block;

    .react-tooltip-lite {
        width: auto !important;
        min-width: 136px;
        max-width: 320px;
        border: 1px solid $color-grey-40;
        background-color: white;
        color: $color-text;
        font-size: 14px;
        padding: 4px !important;
        line-height: 1.5;
        border-radius: 4px;
        text-align: center;
        // Add important to overule inline style of tooltip
        z-index: 9999 !important;
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1));

        .rf-LabelTag {
            margin: 4px;
        }
    }

    &__title {
        font-size: 12px;
        font-weight: 600;
    }

    &__message {
        font-size: 10px;
    }
}
