.rf-DrawerModal {
    @import 'styles/variables/layout';
    @import 'styles/variables/defaults';
    @import 'styles/variables/colors';

    background: #fff;
    min-width: calc(100vw - #{spacing(6)});
    height: calc(100vh - 24px);
    overflow: auto;
    border-top-left-radius: $default-border-radius;
    border-top-right-radius: $default-border-radius;
    width: 100%;

    &__header {
        padding: spacing(2) spacing(3);
    }

    &__title {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        font-size: 20px;
    }

    &__main-content {
        width: 100%;
    }
    &--has-sidebar &__main-content {
        padding-right: spacing(6);
    }

    &__content-header {
        padding: spacing(2) spacing(3);
        padding-top: 0;
        max-width: 1176px;
        margin: 0 auto;
    }

    &__content {
        padding: spacing(2) spacing(3);
        padding-top: 0;

        display: flex;
        max-width: 1176px;
        margin: 0 auto;
    }

    &__sidebar {
        padding: 0 spacing(6);
        flex: 1 1;
        padding-left: 48px;
        width: 100%;
        min-width: 360px;
        font-size: 14px;
    }

    &__sidebar-title {
        font-size: 18px;
        margin-bottom: spacing(2);

        .rf-AttentionIcon {
            font-size: 12px;
            margin-left: 4px;
        }
    }

    &__seperator {
        align-self: stretch;
        width: 1px;
        background-color: $color-grey-20;
    }

    &__close-button {
        &.rf-Button--is-icon-button .rf-Icon.rf-Button__icon {
            font-size: 12px;
        }
    }

    &__action-bar-spacer {
        width: 100%;
        height: 73px;
    }

    &__action-bar {
        width: calc(100% - #{spacing(6, -1)});
        border-top: 1px solid $color-grey-20;
        padding: spacing(2) spacing(3);
        background: $color-white;
        position: fixed;
        bottom: 0;
        z-index: 10;

        @media screen and (min-width: 1500px) {
            width: 1392px;
        }
    }

    @media screen and (min-width: 1500px) {
        min-width: auto;
        max-width: 1392px;
        margin: 0 auto;
    }
}
