.rf-Toggle {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';
    position: relative;
    width: 28px;
    height: 16px;

    &:focus-within,
    &:hover {
        .rf-Toggle__track {
            background-color: $color-grey-80;
        }
    }

    &--is-checked:focus-within,
    &--is-checked:hover {
        .rf-Toggle__track {
            background-color: $color-ruler-blue-darken-10;
        }
    }

    &__checkbox {
        width: 100%;
        height: 100%;
        margin: 0;
        opacity: 0;
        cursor: pointer;
    }

    &__switch {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    &__track {
        flex: 1;
        height: 100%;
        border-radius: 15px;
        background-color: $color-grey-65;
        transition: background-color $animation-appear;
    }

    &__slider {
        position: absolute;
        top: 50%;
        left: 2px;
        width: 12px;
        transform: translateY(-50%);
        height: 12px;
        border-radius: 50%;
        background-color: $color-white;
        transition: transform $animation-appear;
    }

    &__toggle-wrapper {
        display: flex;
        align-items: center;
    }

    &--is-checked {
        .rf-Toggle__track {
            background-color: $color-ruler-blue;
        }

        .rf-Toggle__slider {
            transform: translateX(12px) translateY(-50%);
        }
    }

    &--is-disabled {
        opacity: 0.2;

        &,
        .rf-Toggle__slider,
        .rf-Toggle__track,
        .rf-Toggle__switch,
        .rf-Toggle__checkbox {
            &:hover {
                cursor: no-drop;
            }
        }
    }

    &--is-big {
        width: 42px;
        height: 24px;

        .rf-Toggle__slider {
            width: 18px;
            height: 18px;
            left: 3px;
        }

        &.rf-Toggle--is-checked {
            .rf-Toggle__slider {
                transform: translateX(18px);
            }
        }
    }
}
