.rf-CustomerFeedSettings {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    width: 450px;
    padding: spacing(2) spacing(3);
    border-radius: 4px;
    background-color: $color-grey-10;

    &__setting {
        width: 100%;
        border-bottom: 1px solid $color-grey-40;

        &:last-child {
            border-bottom: none;
        }
    }
}
