.rf-MainSearch {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: flex;
    justify-content: center;
    align-content: center;
    color: $color-grey-80;
    width: 100%;
    height: 32px;
    z-index: 1;

    & .rf-MainSearch__search-icon {
        color: $color-ruler-blue;
    }

    &__search-button,
    &__clear-button {
        color: $color-grey-80;
        padding: 3px;
    }

    &__search-button .rf-Icon.rf-Button__icon {
        font-size: 18px;
    }

    &__clear-button .rf-Icon.rf-Button__icon {
        font-size: 10px;
    }

    &__form {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
    }

    &__form-label {
        height: 32px;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__search-icon {
        color: $color-grey-80;
        font-size: 15.5px;
        height: 28px;
    }

    &__form-input {
        width: 100%;
        height: 31px;
        border: 0;
        border-bottom: 1px solid $color-ruler-blue;
        padding-left: spacing(4);
        padding-right: spacing(3);
        font-size: 14px;
        line-height: 1;
        transition: box-shadow 0.2s ease;
        cursor: text;

        &:focus,
        &:hover {
            outline: none;
        }
    }

    &__form-label {
        height: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__clear-button {
        position: absolute;
        right: 0;
        top: spacing(2, -6px);
        height: 100%;
        width: spacing(3);
        text-align: center;
        justify-content: center;
    }
}
