.rf-CustomerFeedStatus {
    @import 'styles/variables/colors';

    display: flex;
    align-items: center;

    .rf-Icon {
        width: 8px;
        height: 8px;
        margin-right: 8px;
        color: $color-ui-red;
    }

    &--enabled {
        .rf-Icon {
            color: $color-ui-green;
        }
    }
}
