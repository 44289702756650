.rf-InboxItem {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    @import 'styles/variables/animations';

    border-color: $color-grey-20;
    box-shadow: none;
    padding: spacing(2) spacing(3);
    transition: background-color $animation-appear;
    display: grid;
    grid-template-columns: minmax(0, 1fr) 200px;
    gap: spacing(2.5);

    &:hover {
        box-shadow: 1px 4px 8px rgba(41, 41, 136, 0.15);
    }

    &__title-container {
        width: 100%;
        position: relative;
    }

    &__title {
        font-size: 16px;
        font-weight: 600;
        color: $color-grey-95;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &__label {
        text-transform: uppercase;
    }

    &__signaling-icon {
        margin-left: spacing(.5);
    }
}
