.rf-ResponsiveGrid {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    .react-grid-layout {
        position: relative;
        transition: height 200ms ease;
    }

    .react-grid-item {
        transition: all 200ms ease;
        transition-property: left, top;
    }

    .react-grid-item.cssTransforms {
        transition-property: transform;
    }

    .react-grid-item.react-draggable {
        cursor: grab;

        .rf-ResponsiveGrid__animation-container {
            animation: wiggle 0.12s linear 0s infinite alternate;
        }
    }

    .react-grid-item.react-draggable-dragging {
        transition: none;
        z-index: 3;
        will-change: transform;
        cursor: grabbing;

        .rf-ResponsiveGrid__animation-container {
            animation: none;
            transform: rotate(3deg);
        }
    }

    .react-grid-item.react-grid-placeholder {
        background: $color-grey-15;
        opacity: 1;
        transition-duration: 100ms;
        z-index: 2;
    }

    @keyframes wiggle {
        0% {
            transform: rotate(0.3deg);
        }
        100% {
            transform: rotate(-0.3deg);
        }
    }

    &__animation-container {
        height: calc(100% - 24px);
    }
}
