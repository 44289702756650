.rf-NewsSourcesOverviewView {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    &__show-from-container {
        margin-top: spacing(3);
    }

    &__show-from {
        background-color: $color-grey-10;
        border-radius: 4px;
        padding: spacing(1.5);
    }
}
