.rf-Modal {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    position: relative;
    background: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    width: 744px;
    z-index: 9999;

    &__header {
        padding: spacing(2) spacing(3);
        flex-shrink: 0;
        line-height: 1.5;
    }

    &__close-button {
        color: $color-text;
        padding: 10px 0 10px 10px;

        .rf-Icon--close-modal.rf-Button__icon {
            font-size: 10px;
        }
    }

    &__spacer {
        padding-top: 16px;
    }

    &__line {
        border-top: 1px solid $color-grey-40;
        margin-left: spacing(-3);
        margin-right: spacing(-3);
    }

    &__title {
        font-size: 20px;
        color: $color-text;
        line-height: 1;
        font-weight: 700;
        line-height: 24px;
    }

    &__button-container {
        margin-top: 0 !important;
        width: 100%;
    }

    &__cancel-button {
        &:focus {
            background-color: unset;
        }
    }

    .rf-Content,
    &__content {
        padding: 0 spacing(3) spacing(2) spacing(3);
        background: $color-white;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        height: 100%;
    }

    .rf-Form {
        margin-bottom: spacing(2);
    }

    .rf-Field__label-input,
    .rf-Field__label-heading {
        min-width: 150px;
    }

    &--has-buttons-hidden &__header {
        padding-right: spacing(2);
    }

    &__buttons {
        width: 100%;
        margin-top: 16px;

        > * + * {
            margin-left: spacing(1);
        }
    }

    &__action-container {
        width: 100%;
        margin-top: spacing(2) !important;
    }

    &__button-container {
        display: flex;
    }

    &--is-full-page {
        width: 100vw;
        min-height: calc(100vh - #{spacing(4)});
        max-height: none;
        max-width: 100%;

        .rf-Content {
            display: flex;
            flex-direction: column;
        }

        .rf-Modal__button-container {
            margin-top: auto;
        }

        .rf-Modal__buttons {
            justify-content: space-between;
        }
    }

    &--modal-size-small {
        width: 590px;
    }
}
