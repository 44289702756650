.rf-PriorityIndicator {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: flex;
    align-items: center;

    &--is-tooltip {
        display: block;
    }

    &--is-low {
        .rf-PriorityIndicator__icon {
            color: $color-grey-65;
        }
    }

    &--is-medium {
        .rf-PriorityIndicator__icon {
            color: $color-ui-orange;
        }
    }

    &--is-high {
        .rf-PriorityIndicator__icon {
            color: $color-ui-red;
        }
    }

    &__label {
        font-size: 14px;
        margin-left: spacing(1);
        display: inline-block;
    }
}
