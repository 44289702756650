$timeline-outer-dot-size: 16px;
$timeline-inner-dot-size: 12px;
$timeline-inner-dot-spacing: $timeline-outer-dot-size - $timeline-inner-dot-size / 2;
$timeline-line-spacing: 16px;

.rf-TimelineDot {
    @import 'styles/variables/colors';

    width: $timeline-outer-dot-size;
    height: $timeline-outer-dot-size;
    border-radius: 50%;
    background: $color-ruler-blue;
    display: flex;
    justify-content: center;
    align-items: center;

    &__inner-circle {
        border: 2px solid $color-white;
        background: transparent;
        width: $timeline-inner-dot-size;
        height: $timeline-inner-dot-size;
        border-radius: 50%;
    }
}
