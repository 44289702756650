.rf-ControlSelectTable {
    @import 'styles/variables/animations';
    @import 'styles/variables/layout';

    &__select-control-button {
        opacity: 0;
        transition: opacity $animation-appear;
    }

    &__control-title {
        display: flex;
        align-items: center;

        .rf-ControlSelectTable__control-icon {
            font-size: 16px;
            margin-right: spacing();
        }
    }

    .rf-Table__row:hover {
        .rf-ControlSelectTable__select-control-button {
            opacity: 1;
        }
    }
}
