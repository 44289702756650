.rf-SelectLinkableControlsTable {
    @import 'styles/variables/layout';

    &__select-interaction {
        margin-right: 16px;
    }

    &__control-title {
        display: flex;
        align-items: center;

        .rf-SelectLinkableControlsTable__control-icon {
            font-size: 16px;
            margin-right: spacing();
        }
    }
}
