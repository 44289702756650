.rf-MobileItemList {
    @import 'styles/variables/colors';

    > * {
        border-top: 1px solid $color-grey-40;
        border-bottom: 1px solid $color-grey-40;
    }

    > * + * {
        border-top: 0;
    }
}
