.rf-Cover {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    padding: spacing(10);
    // margin-top: -83px;
    color: $color-input-text;
    max-width: 540px;
    width: 100%;

    border: 2px solid $color-ruler-pale;
    background-color: $color-white;
    border-radius: 8px;

    .rf-PageTitle {
        margin-bottom: spacing(4);

        &:after {
            display: none;
        }
    }

    &__brand {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: spacing(4);
        left: spacing(5);
    }

    &__content > * + * {
        margin-top: spacing(2.5);
    }

    &__submit-button.rf-Button {
        margin-top: spacing(3);
        width: 100%;
        display: flex;
        justify-content: space-around;
        font-size: 16px;
        padding: 11px 16px;
    }

    &__link {
        text-align: center;
        margin-top: spacing(2);
        color: #9c9c9c;
        font-size: 16px;
        display: block;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__sso-tooltip {
        display: inline-flex;
        align-items: center;
        color: $color-ruler-blue;
    }

    &__sso-tooltip-label {
        font-size: 13px;
        margin-right: 4px;
        line-height: 14px;
        word-break: normal;
    }

    &__sso-tooltip-icon {
        display: inline-flex;
    }
}
