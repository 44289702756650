.rf-Search {
    @import 'styles/variables/colors';

    &.rf-Input--has-icon {
        .rf-Icon {
            width: 16px;
            font-size: 16px;
            color: $color-grey-65;
        }
        > .rf-Input__input {
            padding-left: 40px;
        }
    }

    &.rf-Icon--search {
        color: $color-grey-65;
        font-size: 16px;
        width: auto;
    }
}
