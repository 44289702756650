.rf-LoginToEnvironmentButton {
    @import 'styles/variables/layout';
    @import 'styles/variables/animations';
    @import 'styles/variables/colors';

    font-size: 14px;
    font-weight: 500;
    // padding: spacing(1) 0;
    color: $color-ruler-blue;
    border-radius: 20px;
    padding-top: spacing(2, -6px);
    padding-bottom: spacing(2, -6px);

    & polygon.arrow {
        transform: translateX(0);
        transition: transform $animation-appear;
    }

    &:hover,
    &:focus {
        & polygon.arrow {
            transform: translateX(15%);
        }
    }

    .rf-Icon.rf-Button__icon {
        font-size: 18px;
    }
}
