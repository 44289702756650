.rf-TopicDetailViewInboxTableContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';
    @import 'styles/variables/layout';

    margin-top: spacing(5);

    &__table {
        .rf-Table__body .rf-Table__row {
            background-color: $color-white;
            transition: background-color $animation-appear;

            &:hover {
                background-color: $color-grey-10;
            }
        }
    }
}
