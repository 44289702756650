.rf-RadarWidget {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__row {
        display: grid;
        grid-template-columns: 20px calc(100% - 20px);
    }

    &__left-column {
        margin-top: 0;
        margin-bottom: auto;
    }

    &__circle {
        background: radial-gradient(
            $color-ruler-blue 0,
            $color-ruler-blue 25%,
            $color-white 35%,
            $color-white 45%,
            $color-ruler-blue 55%,
            $color-ruler-blue 60%,
            transparent 70%
        );

        width: 20px;
        height: 20px;
    }

    &__dots {
        height: 48px;
        width: 10px;
        top: 8px;
        left: 5px;
        position: absolute;

        // dots
        background-image: radial-gradient($color-ruler-blue 0, $color-ruler-blue 25%, transparent 40%);
        background-position: top;
        background-size: 6px 6px;
        background-repeat: repeat-y;
    }

    &__name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
