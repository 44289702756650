.rf-NoResults {
    @import 'styles/variables/layout';

    display: flex;
    flex-flow: column;
    min-height: 50vh;
    justify-content: center;
    text-align: center;

    &__title {
        font-size: 34px;
    }

    &__text {
        margin-top: spacing(2);
        font-size: 16px;
        font-weight: 500;
    }

    img {
        width: 380px;
        margin-right: 161px;
    }
}
