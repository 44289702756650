.rf-SubMenu {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    @import 'styles/variables/defaults';

    background-color: $color-white;
    width: $consultant-menu-width - spacing(5);
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid $color-grey-40;
    overflow: auto;

    &__back {
        padding: 17px 18px;
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        border: 0;
        background: none;
        color: $color-grey-65;

        &:hover {
            color: $color-grey-80;

            .rf-SubMenu__back-icon {
                color: $color-grey-65;
            }
        }
    }

    &__back-icon {
        font-size: 10px;
        color: $color-grey-65;
        transition: color 0.3s;
        margin-right: spacing(0.5, -2);
    }

    &__title {
        margin: 0 spacing(2) spacing(3, -6px);
        font-size: 16px;
        font-weight: 600;
    }

    &__navigation {
        margin: 0 spacing(1);
        transform: translateX(0);
        transition: all 0.3s;
    }

    &__navigation-item + &__navigation-item {
        margin-top: spacing(1);
    }

    &__navigation-link {
        display: flex;
        align-items: center;
        padding: 7px 8px;
        font-size: 14px;
        line-height: 1.3;
        text-decoration: none;
        border-radius: 3px;
        min-height: 32px;
        transition: all 0.3s;

        &:hover {
            background-color: $color-grey-10;
        }

        &.active {
            background-color: $color-grey-10;
            font-weight: 600;
        }

        &--disabled {
            color: $color-grey-65;
            cursor: not-allowed;

            &:hover {
                background-color: $color-white;
            }
        }
    }
}
