.rf-LinkedItemCard {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    border: 0;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    padding: spacing(2) spacing(3, 1px);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    background-color: $color-background;
    height: 100%;

    &__title {
        margin-right: auto;
        margin-bottom: spacing(0.5);
    }

    &__card-header {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    &__card-footer {
        margin-top: auto;
    }

    &:hover {
        background-color: $color-background-light;
    }
}
