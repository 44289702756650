.rf-DepartmentsTableRowContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__row {
        &--empty-mid-parent {
            width: 100%;
            padding-right: spacing(1);

            & > .rf-DepartmentsTableRowContainer__checkbox-list {
                .rf-DepartmentsTableRowContainer__checkbox-container,
                .rf-DepartmentsTableRowContainer__checkbox-container--hover {
                    border-radius: 8px;
                    height: 38px !important;
                }
            }
        }
    }

    &__label-container {
        position: relative;
        display: grid;
    }

    &__label {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-flex;
        align-items: center;

        .rf-AttentionIcon {
            padding-right: spacing(1, 4);
            color: $color-ui-orange;
        }

        .rf-FrameworkIcon {
            display: inline-flex;
            margin-right: spacing(1);
        }
    }

    &__eye-icon {
        color: $color-grey-65;
    }

    &__checkbox-list {
        display: grid;
        grid-template-columns: repeat(2, 104px);
        margin-right: spacing(6, 2);
        gap: spacing(1);

        &--is-level-1 {
            .rf-DepartmentsTableRowContainer__checkbox-container--hover {
                background-color: $color-ruler-pale-dark;

                .rf-Checkbox--is-disabled .rf-Checkbox__checkbox {
                    background-color: $color-ruler-pale-dark;

                    .rf-Checkbox__mark,
                    .rf-Checkbox__mark-indeterminate {
                        color: $color-ruler-blue;
                    }
                }
            }
        }

        &--is-level-2 {
            margin-right: spacing(7, 2);

            .rf-DepartmentsTableRowContainer__checkbox-container--hover {
                background-color: $color-ruler-pale;

                .rf-Checkbox--is-disabled .rf-Checkbox__checkbox {
                    background-color: $color-ruler-pale-dark;

                    .rf-Checkbox__mark {
                        color: $color-ruler-blue;
                    }
                }
            }
        }

        .rf-Checkbox--is-indeterminate .rf-Checkbox__mark-indeterminate {
            left: 5px;
            top: 8px;
        }

        @media screen and (min-width: 1200px) {
            grid-template-columns: repeat(4, 104px);
        }

        @media screen and (min-width: 1500px) {
            grid-template-columns: repeat(6, 104px);
        }
    }

    &__checkbox-container,
    &__checkbox-container--hover {
        width: 40px;
        height: 51px;
        display: flex;
        justify-content: center;

        .rf-Checkbox--is-disabled {
            .rf-Checkbox__checkbox {
                border-radius: 20px;
                opacity: 1;
                background-color: $color-ruler-blue;

                .rf-Checkbox__mark {
                    color: $color-white;

                    &-indeterminate {
                        left: 6px;
                        top: 9px;
                    }
                }
            }
        }
    }

    &__checkbox-container--hover {
        background-color: $color-ruler-pale-dark;
    }

    &__checkbox {
        &--is-hidden {
            visibility: hidden;
        }

        &--is-level-1 {
            &.rf-Checkbox--is-disabled .rf-Checkbox__checkbox {
                background-color: $color-ruler-pale-dark;

                .rf-Checkbox__mark {
                    color: $color-ruler-blue;
                }

                .rf-Checkbox__mark-indeterminate {
                    background-color: $color-ruler-blue;
                }
            }
        }

        &--is-level-2 {
            &.rf-Checkbox--is-disabled .rf-Checkbox__checkbox {
                background-color: $color-ruler-pale;

                .rf-Checkbox__mark {
                    color: $color-ruler-blue;
                }
            }
        }
    }
}
