.rf-AssessMonitoringReportTopicItemButton {
    @import 'styles/variables/layout';

    .rf-Popper__content {
        min-width: 146px;
        padding-bottom: 0;
    }

    .rf-Popper__item-container {
        margin: spacing(1);
    }

    &__button {
        padding: 0;
        margin-top: spacing(0.5);
    }

    &__option {
        width: 100%;
        padding: spacing(1) spacing(2);
    }
}
