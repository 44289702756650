.rf-TaskFormTemplateField {
    @import 'styles/variables/layout';

    .rf-Select__single-value {
        width: calc(100% - #{spacing(3)});
    }

    &__label {
        display: inline-flex;
        overflow: hidden;
        width: 100%;
    }

    &__name {
        overflow: hidden;
    }

    &__framework {
        overflow: hidden;
        max-width: 30%;
    }

    &__text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__type {
        white-space: nowrap;
    }
}
