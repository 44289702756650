.rf-NewsItemTag {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: inline-block;
    padding: spacing(1);
    background-color: $color-grey-20;
    color: $color-grey-80;
    padding: spacing(.75) spacing(1);
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
    border: 1px solid $color-grey-20;

    &--is-suggested-topic {
        background-color: $color-background;
        color: $color-ruler-blue;
        border-color: $color-grey-65;
        border-style: dashed;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 150px;
    }

    &--is-topic {
        color: $color-ruler-blue;
        background-color: $color-ruler-pale;
        border-color: $color-ruler-pale;
    }

    &--is-department {
        color: $color-ruler-blue;
        background-color: $color-background;
        border-color: $color-grey-40;
    }

    &--is-internal-alert {
        color: $color-grey-95;
    }
}
