.rf-CustomerFrameworkRadio {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    $height: spacing(6);
    $width: spacing(6);
    $padding: spacing(0.5);

    position: relative;
    left: -#{$padding};
    top: -#{$padding};

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    &__swatch,
    &__icon {
        cursor: pointer;
    }

    &__swatch {
        height: #{$height - $padding * 2};
        width: #{$height - $padding * 2};
        margin: $padding;
        border-radius: 50%;
        background-color: $color-profile-grey;
        transition: background-color 0.2s ease-in-out;
    }

    &__radio-item {
        height: $height;
        width: $height;
        background-color: $color-white;
        margin: $padding;
        border-radius: 50%;
        opacity: 0.8;
        transition: opacity 0.2s ease-in-out, transform 0.2s ease, background 0.1s ease;
        position: relative;
        transform: scale(1);

        &--is-selected {
            box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.1);
            color: $color-white;
            opacity: 1;
        }

        &--is-not-selected {
            &:hover {
                transform: scale(1.05);
            }

            &.rf-CustomerFrameworkRadio__radio-item {
                &--color-green:hover,
                &--color-green:focus {
                    background-color: $color-profile-green;
                }

                &--color-yellow:hover,
                &--color-yellow:focus {
                    background-color: $color-profile-yellow;
                }

                &--color-orange:hover {
                    background-color: $color-profile-orange;
                }

                &--color-red:hover {
                    background-color: $color-profile-red;
                }

                &--color-light-blue:hover {
                    background-color: $color-profile-light-blue;
                }

                &--color-blue:hover {
                    background-color: $color-profile-blue;
                }

                &--color-pink:hover {
                    background-color: $color-profile-pink;
                }

                &--color-purple:hover {
                    background-color: $color-profile-purple;
                }

                &--color-dark-blue:hover {
                    background-color: $color-profile-dark-blue;
                }

                &--color-black:hover {
                    background-color: $color-profile-black;
                }
            }
        }

        &--color-green .rf-CustomerFrameworkRadio__swatch {
            background-color: $color-profile-green;
        }

        &--color-yellow .rf-CustomerFrameworkRadio__swatch {
            background-color: $color-profile-yellow;
        }

        &--color-orange .rf-CustomerFrameworkRadio__swatch {
            background-color: $color-profile-orange;
        }

        &--color-red .rf-CustomerFrameworkRadio__swatch {
            background-color: $color-profile-red;
        }

        &--color-light-blue .rf-CustomerFrameworkRadio__swatch {
            background-color: $color-profile-light-blue;
        }

        &--color-blue .rf-CustomerFrameworkRadio__swatch {
            background-color: $color-profile-blue;
        }

        &--color-pink .rf-CustomerFrameworkRadio__swatch {
            background-color: $color-profile-pink;
        }

        &--color-purple .rf-CustomerFrameworkRadio__swatch {
            background-color: $color-profile-purple;
        }

        &--color-dark-blue .rf-CustomerFrameworkRadio__swatch {
            background-color: $color-profile-dark-blue;
        }

        &--color-black .rf-CustomerFrameworkRadio__swatch {
            background-color: $color-profile-black;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-10px, -10px);
        font-size: 20px;
        color: inherit;
    }

    &--option-type-icon
        &__radio-item:not(.rf-CustomerFrameworkRadio__radio-item--is-selected)
        .rf-CustomerFrameworkRadio__swatch {
        background-color: $color-white;
        border: 1px solid $color-grey-10;
    }

    [type='radio']:checked,
    [type='radio']:not(:checked) {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
    }
}
