.rf-TabletConsultationItem {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    padding: spacing(2) spacing(1);
    font-size: 14px;

    > * + * {
        margin-top: spacing(2);
    }

    &__start-date {
        display: block;
    }

    &__end-date {
        display: block;
        margin-left: spacing(5, +2);
    }

    &__title {
        font-size: 16px;
        font-weight: 500;
    }
}
