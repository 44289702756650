.rf-ColorSchemeCard {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    background-color: $color-grey-40;
    padding: spacing(2.5);
    color: $color-white;

    &__color-name {
        font-size: 18px;
        font-weight: 500;
    }

    &__meta-data {
        display: flex;
        justify-content: space-between;
    }

    &__color-variable {
        font-size: 14px;
        font-weight: 500;
        opacity: 0.7;
    }

    &__color-hex {
        font-size: 14px;
        font-weight: 500;
        opacity: 0.7;
        text-transform: uppercase;
        margin-left: spacing(1);
    }

    &--has-light-background {
        color: rgba($color-grey-95, 0.7);
    }

    &--main-color {
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
        transform: scale(1.025);
        border-radius: 4px;
    }
}
