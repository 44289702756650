.rf-TopicAssessmentIcon {
    @import 'styles/variables/colors';

    &--is-assessment-satisfies,
    &--is-assessment-satisfies-partly {
        color: $color-ui-green;
    }

    &--is-assessment-almost-satisfies,
    &--is-assessment-almost-satisfies-partly {
        color: $color-ui-orange;
    }

    &--is-assessment-not-satisfies,
    &--is-assessment-not-satisfies-partly {
        color: $color-ui-red;
    }

    &--is-assessment-not-given,
    &--assessment-not-given,
    &--is-not-applicable {
        color: $color-grey-40;
    }
}
