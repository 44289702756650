.rf-ControlDetailMeta {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    justify-content: space-between;

    .rf-PageDetailMeta__container:last-child {
        margin-right: spacing(8);
    }

    &__date {
        &--warn {
            border-bottom: 1px solid $color-ui-red;
        }
    }

    &__archived-reason {
        width: 100%;
        max-width: 225px;
    }
}
