@import 'styles/variables/layout';
@import './TimelineDot';

.rf-Timeline {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: spacing(6.25);
    padding-bottom: 0;
    padding-left: $timeline-outer-dot-size;

    .rf-TimelineDot {
        z-index: 1;
    }

    .rf-TimelineGroup + .rf-TimelineGroup {
        margin-top: spacing(2);
    }

    &__line {
        width: $timeline-outer-dot-size;
        height: 100%;
        position: absolute;
        background-image: url('./dotted-line.svg');
        background-position-x: 50%;
        background-repeat: repeat-y;
        left: 0;
        top: 0;

        &:before,
        &:after {
            content: '';
            position: absolute;
            height: 54px;
            max-height: 25%;
            width: 100%;
            z-index: 0;
        }

        &:before {
            top: 0;
            background: linear-gradient(to top, rgba(white, 0), white);
        }

        &:after {
            bottom: 0;
            background: linear-gradient(to bottom, rgba(white, 0), white);
        }
    }
}
