.rf-MatrixList {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    width: 100%;

    &__title {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.71;
        margin-bottom: spacing(1, -6px);
    }

    &__items {
        & > * + * {
            margin-top: spacing(1);
        }
    }

    &__item-wrapper {
        transition: opacity 200ms;
    }

    &--is-hovered {
        .rf-MatrixList__item-wrapper {
            opacity: 0.4;

            &--active {
                opacity: 1;
            }
        }
    }
}
