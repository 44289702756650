.rf-SignalingItemCard {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    padding-left: spacing(3, 1px);
    padding-right: spacing(3);
    display: flex;
    align-items: center;
    box-shadow: none;

    &:hover {
        box-shadow: 1px 4px 8px rgba(41, 41, 136, 0.15);
    }

    &__content {
        overflow: hidden;
        padding-right: spacing(6.5);

        .rf-SignalingTitle__tooltip {
            width: 100%;
        }

        .rf-SignalingTitle__title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__action {
        display: flex;
        flex-shrink: 0;
        margin-left: auto;

        & > * + * {
            margin-left: spacing(2);
        }
    }

    &__archive-button {
        padding: spacing(1, 3px);
    }

    &--is-archived {
        background-color: $color-background-light;

        .rf-SignalingTitle__title {
            color: $color-grey-80;
        }
    }

    &--is-published {
        .rf-SignalingTitle__title {
            color: $color-grey-80;
        }
    }
}
