.rf-TaskTemplateNameContainer {
    @import 'styles/variables/layout';

    position: relative;
    width: 100%;

    &__name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &__type {
        margin-left: spacing(1);
        white-space: nowrap;
    }
}
