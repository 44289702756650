.rf-DashboardView {
    @import 'styles/variables/layout';

    margin-top: spacing(7, 2);

    &__greeting-container {
        display: flex;
    }

    &__figure {
        margin-right: spacing(3);

        img {
            width: 108px;
        }
    }

    &__buttons {
        align-self: flex-end;
        margin-bottom: spacing(1);
        margin-left: auto;
        margin-top: spacing(3);
    }
}
