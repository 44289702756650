.rf-FrameworkCard {
    @import 'styles/variables/defaults';
    @import 'styles/variables/colors.scss';
    @import 'styles/variables/layout.scss';

    height: 100%;
    width: 100%;

    .rf-Button__inner {
        height: 100%;

        .rf-Card--is-selected {
            border-radius: $default-border-radius;
            border: 1px solid $color-grey-40;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 1.33;
    }

    &__logo {
        margin-left: spacing(1);
    }

    &__logo-wrapper {
        white-space: nowrap;
    }

    &__description {
        margin-top: spacing(1.5);
        font-size: 14px;
        line-height: 1.5;
    }

    &__check-icon-container {
        margin-right: spacing(1);
    }

    &__check-icon {
        &.rf-Icon {
            color: $color-ruler-blue;
        }
    }

    &--is-selected {
        .rf-Card {
            background-color: $color-grey-10;
        }
    }

    &--is-disabled {
        cursor: default;

        .rf-FrameworkCard__check-icon.rf-Icon {
            color: $color-grey-65;
        }
    }

    &--has-icon {
        .rf-FrameworkCard__icon {
            margin: 0 auto;
            pointer-events: none;
            margin-bottom: spacing(1.5);
        }
    }
}
