.rf-LabelTag {
    @import 'styles/variables/colors';

    display: inline-block;
    background: $color-ruler-blue;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    text-align: center;
    color: #ffffff;
    padding: 6px 8px;
    border-radius: 4px;

    &__warning-icon {
        color: #ff9001;
        margin-right: 4px;
    }

    &--is-subtle {
        border: none;
        font-size: 12px;
        color: $color-grey-95;
        font-weight: 600;
        padding: 6px 8px;
        background: $color-grey-20;
    }

    &--is-warning {
        display: inline-flex;
        align-items: center;
        border: none;
        font-size: 12px;
        color: #ff9001;
        font-weight: 600;
        padding: 6px 8px;
        background-color: #fff3e5;
    }
}
