// This wierd naming is caused by the lack of proper BEM support by the 'react-select' library
.rf-Select__container {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';
    @import 'styles/variables/layout';

    width: 100%;

    .rf-Select__label {
        white-space: nowrap;
    }

    .rf-Select__create {
        color: $color-ruler-blue;
        display: flex;
        width: 100%;
    }

    .rf-Select__create-icon {
        color: $color-ruler-blue;
        font-size: 16px;
    }

    .rf-Select__control,
    .rf-Select--is-multi__control,
    .rf-Select--is-filter__control {
        box-shadow: none;
        font-size: 14px;
        color: $color-input-placeholder;
        border: 1px solid $color-input-border;
        min-height: 40px;
        cursor: pointer;

        &--is-focused {
            border-color: $color-ruler-blue;
        }
    }

    .rf-Select__value-container,
    .rf-Select--is-multi__value-container,
    .rf-Select--is-filter__value-container {
        padding-left: 12px;
        &--has-value {
            color: $color-input-text;
        }
    }

    .rf-Select--is-multi__value-container {
        padding-left: 4px;
    }

    .rf-Select--is-multi__multi-value {
        font-size: 14px;
        padding: 4px;
        border-radius: 4px;
        background-color: $color-grey-10;
        color: #3d3d40;
        font-weight: 600;
    }
    .rf-Select--is-multi__placeholder {
        padding-left: 12px;
        margin-left: 0;
    }
    .rf-Select--is-multi__input {
        padding-left: 10px;
    }

    .rf-Select--is-multi__multi-value__remove {
        background-color: transparent;
        color: #bfbfc2;
    }

    .rf-Select__indicators,
    .rf-Select--is-multi__indicators,
    .rf-Select--is-filter__indicators {
        padding: 8px;
    }

    .rf-Select__indicator,
    .rf-Select--is-multi__indicator,
    .rf-Select--is-filter__indicator {
        color: hsl(0, 0%, 80%);
        display: flex;
        padding: 4px;
        transition: color 150ms;
        margin: 0;
        &:hover {
            color: $color-text;
        }
    }

    .rf-Select__control--menu-is-open &__indicator--dropdown,
    .rf-Select--is-multi__control--menu-is-open &__indicator--dropdown,
    .rf-Select--is-filter__control--menu-is-open &__indicator--dropdown {
        color: $color-text;
    }

    .rf-Select__menu,
    .rf-Select--is-multi__menu,
    .rf-Select--is-filter__menu {
        z-index: 100;
        border-radius: 4px;
        border: 1px solid $color-input-border;
        margin-top: 0;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }

    .rf-Select__menu-list,
    .rf-Select--is-multi__menu-list,
    .rf-Select--is-filter__menu-list {
        padding: 8px;
    }

    .rf-Select__option,
    .rf-Select--is-multi__option,
    .rf-Select--is-filter__option {
        color: $color-input-text;
        font-size: 14px;
        cursor: pointer;

        &:hover {
            background-color: #f7f7fa;
        }

        &--is-focused {
            background-color: #f7f7fa;
        }

        &--is-selected {
            background-color: #dddde2;
            color: $color-input-text;
        }

        &--is-disabled {
            color: $color-input-placeholder;

            &:hover,
            &.rf-Select__option--is-focused,
            &.rf-Select__option--is-selected {
                background-color: transparent;
            }
        }
    }

    .rf-Select__menu-notice,
    .rf-Select--is-multi__menu-notice,
    .rf-Select--is-filter__menu-notice {
        color: #bfbfc2;
        font-size: 14px;
        padding: 8px;
    }

    .rf-Select--is-filter {
        &__multi-value {
            background: $color-grey-10;
            border-radius: 4px;

            &__label {
                color: $color-ruler-blue;
                font-weight: 500;
                font-size: 14px;
            }

            &__remove {
                color: $color-grey-65;
                font-size: 12px;
                transition: color $animation-appear;

                &:hover {
                    background: none;
                    color: $color-grey-95;
                    cursor: pointer;
                }

                > svg {
                    height: 16px;
                    width: 16px;
                    margin-top: 1px;
                }
            }
        }
        &__placeholder {
            font-weight: 600;
            color: $color-text;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            padding-right: 7px;
        }

        &__indicators {
            .rf-Select__indicator--dropdown {
                color: $color-text;
            }
        }

        &__group-heading {
            font-weight: 600;
            color: black;
            font-size: 14px;
            text-transform: none;
        }
    }

    .rf-Select--is-filter--is-disabled {
        .rf-Select--is-filter__control {
            background-color: $color-grey-10;
        }

        .rf-Select--is-filter__multi-value {
            background-color: $color-grey-20;
        }
    }

    & .rf-Select--is-multi__multi-value--is-disabled {
        & > .rf-Select--is-multi__multi-value__label {
            font-weight: 400;
            color: $color-grey-65;
        }

        .rf-Select--is-multi__multi-value__remove {
            display: none;
        }
    }

    & .rf-Select--is-multi__multi-value--is-disabled {
        background: none;
    }

    &--has-search-icon {
        * {
            cursor: text;
        }

        .rf-Select__indicators {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
        }

        .rf-Select__value-container {
            margin-left: spacing(2);

            &--has-value + .rf-Select__indicators {
                flex-direction: row-reverse;
            }
        }

        .rf-Select__single-value {
            padding-right: spacing(4);
        }
    }
}
