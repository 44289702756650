.rf-OnboardingOverlay {
    z-index: 999998;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
