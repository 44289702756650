.rf-CustomerDomainItemListLayout {
    @import 'styles/variables/layout';
    max-width: 984px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .rf-CustomerDomainItem {
        margin: spacing(2) spacing(4, +4);
    }

    // this allows items to wrap on older IE browsers
    &__nav {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
