.rf-FrameworkIcon {
    @import 'styles/variables/colors';

    background-color: $color-profile-grey;
    border-radius: 50%;
    color: $color-white;
    font-size: 14px;
    height: 24px;
    width: 24px;
    justify-content: center;
    transition: all 0.2s ease;
    user-select: none;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;

    &__profile-wrapper {
        display: flex;
        align-items: center;
        font-weight: 500;

        > * + * {
            margin-left: 16px;
        }
    }

    //colors
    &--color-purple {
        background-color: $color-profile-purple;
    }

    &--color-dark-blue {
        background-color: $color-profile-dark-blue;
    }

    &--color-blue {
        background-color: $color-profile-blue;
    }

    &--color-light-blue {
        background-color: $color-profile-light-blue;
    }

    &--color-green {
        background-color: $color-profile-green;
    }

    &--color-yellow {
        background-color: $color-profile-yellow;
    }

    &--color-orange {
        background-color: $color-profile-orange;
    }

    &--color-red {
        background-color: $color-profile-red;
    }

    &--color-pink {
        background-color: $color-profile-pink;
    }

    &--color-black {
        background-color: $color-profile-black;
    }

    &--color-grey {
        background-color: $color-profile-grey;
    }

    &--is-inactive {
        background: $color-profile-grey;
    }

    .rf-Icon {
        opacity: 0.9;
    }

    &--is-not-visible {
        visibility: hidden;
        user-select: none;
    }

    &--is-small {
        font-size: 10px;
        height: 20px;
        width: 20px;
    }

    &--is-extra-small {
        font-size: 10px;
        height: 16px;
        width: 16px;
    }

    &--is-dot {
        height: 10px;
        width: 10px;

        &:active {
            transform: none;
        }
    }

    &.margin-right {
        margin-right: 8px;

        &:active {
            transform: none;
        }
    }

    button {
        background: none;
        border: none;
    }

    &--is-pale-saturated {
        filter: saturate(0.9);
    }
}
