.rf-MonitoringReportTopicSelectionHeader {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__spacer {
        width: spacing(7.5);
    }

    &__header-container {
        display: grid;
        grid-template-columns: 35% 65%;
        overflow: hidden;
        margin-left: spacing(1);
        margin-right: spacing(1);
        gap: spacing(2);
    }

    &__departments-container {
        position: relative;
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        grid-auto-flow: column;
        gap: spacing(1);
    }

    &__left-button-container,
    &__right-button-container {
        padding: spacing(1);
        background-color: $color-white;
        position: absolute;
        margin-left: 0;
    }

    &__left-button-container {
        padding-left: 0;
        left: calc(-40px - #{spacing(1)});
    }

    &__right-button-container {
        padding-right: 0;
        right: 0;
    }

    &__button {
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
    }

    &__department {
        width: 100%;
        height: 50px;
        border-radius: 4px;
        background-color: $color-grey-10;
        overflow: hidden;
        padding-left: spacing(1.5);
        padding-right: spacing(1.5);
    }
}
