.rf-SelectableLawArticlesTable {
    @import 'styles/variables/colors';
    width: 100%;
    text-align: left;

    > tbody:last-of-type {
        .rf-GroupedTable__border-bottom:last-of-type {
            border: none;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 14px;
    }

    &__padding-title {
        padding-top: 32px;
    }

    &__column-two {
        min-width: 20%;
    }

    &__padding {
        padding: 6px 0;
    }

    .rf-DateFormat {
        color: $color-grey-40;
        font-size: 14px;
        font-weight: 500;
    }

    &__border-bottom {
        border-bottom: 1px solid #ededf2;
    }
}
