.rf-MobileFilterOverlay {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    background: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: spacing(2);
    padding-bottom: spacing(1);

    &__container {
        overflow: scroll;
        -webkit-overflow-scrolling: touch;

        > * {
            display: block;
            margin: 0 auto;
        }

        > * + * {
            margin-top: 16px;
        }
    }

    &__close {
        position: absolute;
        top: 18px;
        right: 24px;
        width: 12px;
        height: 12px;
    }

    &__title {
        font-size: 14px;
    }

    &__button {
        width: 288px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
