.rf-ControlItem {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    @import 'styles/variables/animations';

    border-color: $color-grey-20;
    box-shadow: none;
    padding: spacing(2) spacing(3);
    transition: background-color $animation-appear;
    display: grid;
    grid-template-columns: auto;
    gap: 0;

    &:hover {
        box-shadow: 1px 4px 8px rgba(41, 41, 136, 0.15);
    }

    &__title-container {
        width: 70%;
        position: relative;
    }

    &__column-container {
        width: 15%;
        position: relative;
    }

    &__title {
        font-size: 14px;
        font-weight: 400;
        color: $color-grey-95;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: spacing(4);
    }

    &__date-container {
        margin-top: spacing(0.25);
    }

    &__info-icon {
        position: absolute;
        right: spacing(3);
    }

    &--has-indent-1,
    &--has-indent-2 {
        .rf-ControlItem__date-container {
            margin-left: spacing(1, -1);
        }
    }

    &--has-indent-1 {
        .rf-ControlItem__title-container {
            width: calc(70% - #{spacing(4.5)});
        }
    }

    &--has-indent-2 {
        .rf-ControlItem__title-container {
            width: calc(70% - #{spacing(6)});
        }

        .rf-ControlItem__column-container {
            width: calc(15% + #{spacing(0.75)});
        }
    }
}
