.rf-Matrix {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: flex;
    justify-content: space-between;
    padding-bottom: spacing(2);

    &__list {
        max-width: 312px;
    }

    &--no-right-group {
        .rf-Matrix__list {
            max-width: 100%;
        }

        .rf-RelevantMatrixCard {
            max-width: 100%;
        }
    }
}
