.rf-ControlTopicSelectionTable {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__table {
        &.rf-MultiDepthExpandableTable {
            .rf-MultiDepthExpandableTable__list-item {
                > .rf-ExpandableTableListItem__inner {
                    background-color: $color-grey-10;
                }
                &.rf-ExpandableTableListItem--is-expanded .rf-ExpandableTableListItem__children {
                    margin-bottom: 0;
                    padding-top: spacing(0.5);

                    .rf-MultiDepthExpandableTable__table-list-item {
                        &.rf-TableListItem {
                            border-top: none;
                            margin-bottom: spacing(1);
                            padding: spacing(1);
                            padding-left: spacing(3);

                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .rf-Checkbox {
        margin-right: spacing(5);

        &--is-indeterminate .rf-Checkbox__mark-indeterminate {
            left: 5px;
            top: 8px;
        }
    }

    &__theme-row {
        background-color: $color-grey-10;
    }

    &__topic-row {
        .rf-Paragraph--truncate-ellipsis {
            width: auto;
        }
    }

    &__label-container {
        display: grid;
    }

    &__eye-icon {
        color: $color-grey-65;
    }
}
