.rf-WidgetTitle {
    @import 'styles/variables/colors';

    &__icon {
        font-size: 20px;
    }

    &__title-container {
        display: grid;
        grid-template-columns: auto auto;
        position: relative;
    }

    &__main-name {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__sub-name {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 500;
    }

    &--is-white {
        .rf-WidgetTitle__icon {
            svg {
                color: $color-white;
            }
        }

        .rf-WidgetTitle__main-name,
        .rf-WidgetTitle__sub-name {
            color: $color-white;
        }
    }
}
