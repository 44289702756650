.rf-CoverView {
    background-color: #fff;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: url('./LoginBG.png');
    background-size: cover;

    &__footer.rf-Footer {
        border: none;
        background: transparent;
    }
}
