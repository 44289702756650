@import 'styles/variables/layout';

.rf-FieldSet {
    > * + * {
        margin-top: spacing(2);
    }

    // This will add margin-top to the last row of a fieldset, making the assumption that this is a button row.
    .rf-Row:last-child {
        margin-top: spacing(2);
    }

    &--no-margin-on-last-child {
        .rf-Row:last-child {
            margin-top: 0;
        }
    }
}

.rf-FieldSet + .rf-FieldSet {
    margin-top: spacing(4);
}
