.rf-Icon {
    @import 'styles/variables/animations';
    @import 'styles/variables/colors';

    display: flex;
    justify-content: center;
    align-items: center;
    width: 1em;
    height: 1em;
    position: relative;

    &__count {
        color: $color-ruler-blue;
        position: absolute;
        top: 0px;
        left: 4px;
        font-size: 14px;
        line-height: 14px;
        font-style: normal;
        font-weight: 500;
    }

    &--subtler {
        color: $color-grey-80;
    }

    &--subtle {
        color: $color-grey-65;
    }

    &--very-subtle {
        color: $color-grey-40;
    }

    &--large {
        font-size: 20px;
    }

    &--small {
        font-size: 12px;
    }
}

.rf-Icon svg {
    @import 'styles/variables/animations';
    color: inherit;
    font-size: inherit;
    width: 1em;
    height: 1em;
    pointer-events: none;
    transform-origin: 50% 50%;
    transition: transform $animation-appear;
}
