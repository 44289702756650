.rf-TopicAssessmentSelect {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    padding: spacing(2);
    background-color: $color-ruler-pale;
    border-radius: 4px;

    &__label {
        max-width: 70%;
    }

    &__select-container {
        width: 170px;
    }
}
