.rf-LinkedInboxItemsTable {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    width: 100%;

    .rf-Table__table {
        table-layout: fixed;
        background-color: $color-ruler-pale;
        padding: spacing(1, -2) spacing(3);
        border-radius: 4px;

        .rf-Table__header {
            padding: spacing(0, 4) 0 0 0;
        }

        .rf-Table__body {
            .rf-Table__cell {
                padding: spacing(0.5) spacing(3) spacing(0.5) 0;
                border: none;
                font-weight: 400;
                vertical-align: top;

                &:last-child {
                    padding-right: spacing(3, -2);
                }

                .rf-IconLabel__label {
                    font-weight: 400;
                }

                .rf-SubTasksTable {
                    font-weight: 400;
                }
            }
        }
    }

    &--has-department-column {
        .rf-Table__header {
            &:first-child {
                width: 70%;
            }
        }
    }

    &--is-for-tooltip {
        .rf-Table__table {
            background-color: unset;
            max-width: 700px;
            padding: 0 spacing(2);

            .rf-Table__header:first-child {
                padding-left: unset;
            }
        }
    }
}
