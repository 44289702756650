.rf-PinnableCard {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    display: flex;
    flex-direction: column;
    position: relative;

    &__card {
        min-height: 116px;
    }

    &__header-container {
        padding-right: spacing(1);
        padding-bottom: spacing(4);
    }

    &__pin {
        position: absolute;
        right: spacing(1.25);
        top: spacing(1.25);

        display: none;

        &--pinned {
            display: block;
        }
    }

    &__info {
        position: absolute;
        bottom: 16px;
        left: 16px;

        .rf-ActionButton__button {
            padding: spacing(0.5);
        }
    }

    &:hover {
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);

        .rf-PinnableCard__pin {
            display: block;
        }
    }
}
