.rf-EmployeeDetailEditView {
    @import 'styles/variables/layout';

    &__email-text {
        margin-top: spacing(1, 2);
        margin-bottom: spacing(0.5);
    }

    &__email-input {
        .rf-Attention {
            margin-top: spacing(0.5) !important;
        }
    }
}
