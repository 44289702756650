.rf-Input {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    position: relative;
    width: 100%;

    &__input {
        line-height: 1;
        list-style: none;
        position: relative;
        display: inline-block;
        padding: 10.5px 16px;
        width: 100%;
        font-size: 14px;
        color: $color-input-text;
        background-color: #fff;
        border: 1px solid $color-input-border;
        border-radius: 4px;
        resize: vertical;
        -webkit-appearance: none;
        touch-action: manipulation;
        transition: border-color 0.3s;

        &:focus {
            border-color: $color-ruler-blue;
            outline: 0;
        }

        &::placeholder {
            color: $color-input-placeholder;
        }

        &[placeholder] {
            text-overflow: ellipsis;
        }
    }

    &--is-type-text,
    &--is-type-textarea {
        max-width: 775px;
    }

    &--is-type-textarea &__input {
        line-height: inherit;
        min-height: spacing(18);
    }

    &__input:focus ~ &__icon {
        color: $color-ruler-blue;
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 14px;
        bottom: 0;
        height: 100%;
        width: 16px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        color: $color-text;
        transition: color 0.3s;
    }

    &__suffix {
        position: absolute;
        right: 1px;
        top: 1px;
        bottom: 0;
        > *:not(.rf-Button) {
            height: calc(100% - 1px);
            width: 100%;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            box-shadow: none !important;
            border: 0 !important;
        }
        > .rf-Button {
            position: relative;
            top: -1px;
            right: -1px;
        }
    }

    &__counter {
        text-align: right;
        margin: 8px 0;
        font-size: 14px;
        color: $color-grey-40;
    }

    &__input:focus ~ &__suffix {
        border-color: $color-ruler-blue;
    }

    &--has-icon &__input {
        padding-left: 36px;
    }

    &--is-raised &__input {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    }

    &--is-disabled &__input {
        background-color: $color-grey-10;
        text-overflow: ellipsis;
    }
}
