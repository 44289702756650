.rf-CustomerFeedTypeSelectField {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__settings-container {
        margin-top: spacing(1);
        border-radius: 4px;
        padding: spacing(2);
        background-color: $color-grey-10;

        .rf-Field__label-input {
            margin-right: 0;
        }
    }

    &__setting-label {
        .rf-Field__label-heading {
            height: 40px;
            padding-left: spacing(2);
            border-radius: 4px;
            border: 1px solid $color-grey-40;
        }
    }
}
