.rf-SelectionBox {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';
    @import 'styles/variables/layout';

    &__container {
        z-index: 10;
        position: fixed;
        bottom: 24px;
        left: 50%;
        margin-left: -132px; // to account for the customer navigation & page padding
        min-height: 48px;
        background-color: $color-ruler-blue;
        color: #fff;
        padding-left: spacing(3);
        padding-right: spacing(1);
        border-radius: 8px;
        box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.1);
    }

    &__title-container {
        padding-right: spacing(4);
        white-space: nowrap;
        border-right: 2px solid $color-white;
    }

    &__selected-amount {
        display: block;
        color: $color-ruler-blue;
        background-color: #fff;
        border-radius: 50%;
        min-width: 21px;
        height: 21px;
        text-align: center;
        line-height: 21px;
        padding-left: 4px;
        padding-right: 4px;
        font-size: 14px;
        font-weight: 600;
    }

    &__buttons {
        height: 21.5px;
        padding-right: spacing(2);
        border-right: 2px solid $color-white;
    }

    &__close,
    &__button {
        padding: spacing(1);
        border-radius: 4px;

        .rf-Button__inner {
            .rf-Button__icon {
                margin-bottom: 0;
            }
        }
        &:hover {
            background-color: $color-ruler-blue-light;
        }
    }

    &__tooltip {
        .react-tooltip-lite {
            background-color: $color-ruler-blue;
            min-width: unset;
            padding: 0 !important; // to override the inline style
        }
    }

    &__tooltip-content {
        line-height: 1;
        padding: spacing(1) spacing(2);
    }

    &__close {
        margin-left: spacing(1);
    }
}
