.rf-InfoWidget {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    &.rf-WidgetContainer {
        padding: spacing(3.5) spacing(5);
    }

    &__title {
        color: $color-ruler-blue;
        hyphens: none;
    }

    &__text {
        width: 60%;
    }

    &--is-small {
        .rf-InfoWidget__title {
            font-size: 20px;
        }

        .rf-InfoWidget__text {
            width: 100%;
        }
    }
}
