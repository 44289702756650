.rf-TaskTags {
    @import 'styles/variables/layout';
    display: inline-block;

    &__tag {
        display: inline-block;
        margin-bottom: spacing(1);

        & + .rf-TaskTags__tag {
            margin-left: spacing(1, -4px);
        }
    }

    &__list {
        margin-top: spacing(1, -4px);
        margin-bottom: -4px;

        .rf-TaskTags__tag {
            max-width: none;
        }
    }

    &--has-tooltip {
        &.rf-TaskTags--is-single {
            max-width: 110px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}
