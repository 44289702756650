@import 'styles/variables/colors';

.rf-RiskGraphGrid {
    height: 100%;
    width: 100%;

    &__columns,
    &__rows {
        display: flex;
        position: absolute;
        height: 100%;
        width: 100%;
    }

    &__rows {
        flex-direction: column;
    }

    &__columns > div + div {
        width: 25%;
        height: 100%;
        border-right: 1px solid $color-grey-20;
    }

    &__rows > div + div {
        height: 25%;
        width: 100%;
        border-bottom: 1px solid $color-grey-20;
    }
}
