.rf-AssessmentMatrixLayout {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    position: relative;
    justify-content: space-around;

    &__empty {
        border: 1px dashed $color-grey-40;
        padding: spacing(1, -1px) spacing(2);
        min-width: 328px;
        border-radius: 4px;
        text-align: center;
    }

    &__add-button {
        font-size: 13px;

        .rf-Icon {
            font-size: 12px !important;
        }
    }

    &--has-four-columns {
        .rf-AssessmentMatrixLayout__empty {
            min-width: 256px;
        }
    }

    .rf-VariableMatrixLines__line {
        stroke: none;

        &--is-dashed {
            stroke: $color-grey-40;
        }
    }
}
