.rf-Pin {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';
    @import 'styles/variables/layout';

    align-self: flex-start;
    background: none;
    border: none;
    transition: opacity $animation-appear;
    padding: 4px;
    margin-top: auto;
    margin-bottom: auto;

    &:hover &__pin {
        color: rgba($color-grey-95, 0.6);
    }

    &--is-pinned &__pin {
        color: $color-grey-95;
    }

    &__pin {
        font-size: 12px;
        color: $color-profile-grey;
        transition: color $animation-appear;
        padding: 0;
        margin: 0;
    }

    // This is a way to target IE10+ browsers, it ensures that the Pinned Icons are always visible due to otherwise a hover bug
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        &__button {
            opacity: 1;

            &__pin {
                color: $color-profile-grey;
            }
        }
    }
}
