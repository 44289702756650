.rf-AssessmentDetailCard {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';
    border: 0;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);

    &__content {
        text-decoration: none;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__title {
        margin-right: auto;
        font-size: 16px;
        font-weight: 600;
    }

    &__description {
        font-weight: 400;
    }

    &__card-header {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        font-size: 14px;
        font-weight: 500;
        min-height: 57px;
    }

    &__count {
        font-size: 13px;
        color: $color-grey-80;
    }

    &__icon {
        font-size: 8px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__card &__profile-list {
        margin-top: spacing(1.5);
    }

    &__card-footer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: auto;
        padding-top: spacing(1);

        > * + * {
            margin-left: spacing(3, +2);
        }
    }

    &__count-container {
        > * + * {
            margin-left: spacing(0.5);
        }

        &--at-zero {
            color: $color-grey-40;
        }
    }

    &:hover {
        background-color: rgba(247, 247, 250, 0.3);
    }
}
