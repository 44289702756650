.rf-IconListItem {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: spacing(12);
    width: spacing(12);
    padding: spacing(1);
    border-radius: spacing(0.5);
    background-color: $color-grey-10;
    font-size: 12px;
    font-weight: 500;

    .rf-Icon {
        font-size: spacing(2);
    }

    > * + * {
        margin-top: spacing(2);
    }
}
