.rf-Truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;

    &__truncate-middle {
        white-space: nowrap;
    }

    &__multi-line-row {
        display: block;
    }
}
