.rf-AssessmentLinkedNormsBlockList {
    @import 'styles/variables/layout';

    .rf-LinkedNormsCard {
        border-top-width: 0;
        border-bottom-width: 0;
    }
    .rf-LinkedNormsCard + .rf-LinkedNormsCard {
        padding-top: 0;

        .rf-LinkedNormsCard__edit {
            top: 0;
        }
    }

    .rf-LinkedNormsCard {
        border-radius: 0;
    }

    .rf-LinkedNormsCard:first-child {
        border-top-width: 1px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .rf-LinkedNormsCard:last-child {
        border-bottom-width: 1px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
