.rf-CustomerFrameworksTableContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__label-container {
        position: relative;
        display: grid;
    }

    &__label {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__eye-icon {
        color: $color-grey-65;
    }

    &__checkbox-list {
        display: grid;
        grid-template-columns: repeat(2, 104px);
        margin-right: spacing(6, 2);
        gap: spacing(1);

        &--is-level-1 {
            margin-right: spacing(7, 2);

            .rf-CustomerFrameworksTableContainer__checkbox-container--hover {
                background-color: $color-ruler-pale;
            }

            .rf-CustomerFrameworksTableContainer__checkbox-container--hover {
                background-color: $color-ruler-pale;

                .rf-Checkbox--is-disabled .rf-Checkbox__checkbox {
                    background-color: $color-ruler-pale-dark;

                    .rf-Checkbox__mark {
                        color: $color-ruler-blue;
                    }
                }
            }
        }

        .rf-Checkbox--is-indeterminate .rf-Checkbox__mark-indeterminate {
            left: 5px;
            top: 8px;
        }

        @media screen and (min-width: 1200px) {
            grid-template-columns: repeat(4, 104px);
        }

        @media screen and (min-width: 1500px) {
            grid-template-columns: repeat(6, 104px);
        }
    }

    &__checkbox-container,
    &__checkbox-container--hover {
        width: 40px;
        height: 51px;
        display: flex;
        justify-content: center;

        .rf-Checkbox--is-disabled {
            .rf-Checkbox__checkbox {
                border-radius: 20px;
                opacity: 1;
                background-color: $color-ruler-blue;

                .rf-Checkbox__mark {
                    color: $color-white;

                    &-indeterminate {
                        left: 6px;
                        top: 9px;
                    }
                }
            }

            &.rf-CustomerFrameworksTableContainer__checkbox--is-unchecked {
                &.rf-CustomerFrameworksTableContainer__checkbox--is-level-0 {
                    .rf-Checkbox__checkbox {
                        background-color: $color-grey-40;
                    }
                }
                &.rf-CustomerFrameworksTableContainer__checkbox--is-level-1 {
                    .rf-Checkbox__checkbox {
                        background-color: $color-grey-20;
                    }
                }
            }
        }
    }

    &__checkbox-container--hover {
        background-color: $color-ruler-pale-dark;
    }

    &__checkbox {
        &--non-applicable {
            visibility: hidden;
        }

        &--is-level-1 {
            &.rf-Checkbox--is-disabled .rf-Checkbox__checkbox {
                background-color: $color-ruler-pale;

                .rf-Checkbox__mark {
                    color: $color-ruler-blue;
                }
            }
        }
    }

    .rf-MultiDepthExpandableTable__list-item .rf-ExpandableTableListItem__inner {
        .rf-CustomerFrameworksTableContainer__checkbox-container,
        .rf-CustomerFrameworksTableContainer__checkbox-container--hover {
            border-radius: 8px;
            height: 38px;
        }
    }

    .rf-MultiDepthExpandableTable__table-list-item {
        &:first-child {
            .rf-CustomerFrameworksTableContainer__checkbox-container,
            .rf-CustomerFrameworksTableContainer__checkbox-container--hover {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }
        }

        &:last-child {
            .rf-CustomerFrameworksTableContainer__checkbox-container,
            .rf-CustomerFrameworksTableContainer__checkbox-container--hover {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                height: 50px;
            }
        }
    }
}
