.rf-VisualRadar {
    @import 'styles/variables/colors';
    @import 'styles/variables/backgrounds';

    &__radar-container {
        border-bottom: 1px solid rgba($color-grey-40, 0.2);
        display: flex;
        height: 400px;
        justify-content: center;
        margin-bottom: 8px;
        overflow: hidden;
        position: relative;
    }

    &__period-container {
        justify-content: space-between;
        max-width: 67%;
    }

    &__period-wrapper {
        display: inline-block;
        text-align: center;

        &--0 {
            width: 15%;
        }

        &--1 {
            width: 15%;
        }

        &--2 {
            width: 20%;
        }

        &--3 {
            width: 49%;
        }
    }

    &__circle {
        border: 1px solid $color-grey-40;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        &--first {
            height: 800px;
            width: 800px;
        }

        &--second {
            height: 640px;
            width: 640px;
        }

        &--third {
            height: 476px;
            width: 476px;
        }

        &--fourth {
            height: 272px;
            width: 272px;
        }
    }

    &__circle-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__pendelum {
        transform-origin: 50% 100%;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 50%;
    }

    &__phase-5 {
        background: #d2ddff;

        &--is-unfollowed {
            @include stripedBG(#f2f4f5, #cfcfcf);
        }
    }

    &__phase-4 {
        background: #a5bbff;

        &--is-unfollowed {
            @include stripedBG(#e0e1e4, #bfbfc2);
        }
    }

    &__phase-3 {
        background: #7799ff;

        &--is-unfollowed {
            @include stripedBG(#7799ff);
        }
    }

    &__phase-2 {
        background: #4a77ff;

        &--is-unfollowed {
            @include stripedBG(#4a77ff);
        }
    }

    &__phase-1 {
        background: $color-ruler-blue;

        &--is-unfollowed {
            @include stripedBG($color-ruler-blue);
        }
    }
}
