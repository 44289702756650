.rf-AssessmentDetailList {
    @import 'styles/variables/layout';

    grid-column-gap: spacing(3);
    grid-row-gap: spacing(3);

    .rf-AssessmentDetailCard {
        height: 100%;
    }
}
