.rf-InternalAlertDepartmentLabelTagContainer {
    @import 'styles/variables/colors';

    &__tag {
        font-weight: 600;
        border: 1px solid $color-grey-20;

        &--new,
        &--returned {
            background-color: $color-white;
            color: $color-ruler-blue;
        }

        &--accepted {
            background-color: $color-ruler-pale;
            color: $color-ruler-blue;
            border-color: $color-ruler-pale;
        }

        &--archived {
            background-color: $color-white;
            color: $color-grey-65;
        }
    }
}
