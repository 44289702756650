.rf-AttachmentList {
    @import 'styles/variables/colors';
    background-color: $color-ruler-pale;
    padding: 16px 20px;
    border-radius: 16px;
    .rf-ComponentTitle {
        font-size: 14px;
    }
    .rf-LinkList__linked-document-list {
        margin-top: 16px;
    }
    .rf-LinkList__linked-document {
        color: $color-text-link;
        &:hover {
            text-decoration: underline;
        }
    }
}
