.rf-StickyFooter {
    @import 'styles/variables/layout';

    background: white;
    position: sticky;
    bottom: 0;
    position: sticky;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) -12%, white 20%);
    padding: spacing(2) 0 spacing(1) 0;
    text-align: center;
    z-index: 99;

    .rf-Button {
        width: 288px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__fixed-button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__notice {
        font-size: 12px;
    }
}
