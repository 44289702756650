.rf-EditRiskValueField {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__title {
        margin-top: spacing(5);
        margin-bottom: spacing(2);
    }

    &__warning-text {
        padding: spacing(2, 5);
        background-color: $color-grey-10;
        border-radius: 4px;
        max-width: 650px;
        margin-bottom: spacing(7);
    }

    .rf-FieldSet &__slider-label,
    .rf-FieldSet &__slider-label:last-child {
        margin-top: spacing(-1, 2);
    }

    &__tooltip {
        .react-tooltip-lite {
            padding: spacing(2) !important;
            min-width: 220px !important;
        }
    }

    &__line {
        color: $color-grey-20;
        background-color: $color-grey-20;
        height: 1px;
        border: 0;

        &:last-of-type {
            display: none;
        }
    }
}
