.rf-CustomerFrameworksTableHeader {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__right-button,
    &__left-button {
        margin-top: spacing(8);

        &.rf-Button--is-icon-button {
            padding: spacing(1, 4);
        }

        &.rf-Button--is-disabled {
            visibility: hidden;

            &:hover {
                cursor: default;
            }
        }
    }

    &__header-item {
        width: 104px;
        height: 203px;
        background-color: $color-grey-10;
        border-radius: 4px;
        padding: spacing(2, -4) spacing(2, -2) spacing(2, -4) spacing(2, -4);
        position: relative;
    }

    &__customer-framework-name {
        line-height: 1.29;
        cursor: default;
    }

    &__framework-name {
        line-height: 1.33;
        cursor: default;
    }

    &__edit-button {
        position: absolute;
        bottom: spacing(2, -4);
        left: spacing(2, -4);
    }
}
