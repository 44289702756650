@import 'styles/variables/colors';
@import 'styles/variables/layout';

.rf-CustomerDepartmentContextSwitch {
    width: 184px;
    padding-bottom: spacing(1);

    &__close {
        display: none;
    }

    &__title {
        font-size: 14px;
        font-weight: 500;
        color: $color-grey-95;
        padding: spacing(1) spacing(1) spacing(0.5);
    }

    &__department {
        .rf-PopperButtonItem__label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__tooltip {
        display: block;
    }
}
