.rf-OnboardingStepInstructions {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    z-index: 999998;
    position: fixed;
    left: 100px;
    background-color: #fff;
    border-radius: 4px;
    padding: spacing(2);
    width: 336px;
    margin-left: -168px;
    font-size: 14px;

    > * + * {
        margin-top: 8px;
    }

    &--position-above {
        transform: translateY(-100%);
    }

    &__title {
        font-weight: 600;
    }

    &__close-button {
        padding: 3px;

        .rf-Icon.rf-Button__icon {
            font-size: 14px;
        }
    }

    &__buttons {
        margin-top: 16px;
    }

    &__arrow {
        border: solid transparent;
        pointer-events: none;
        border-color: rgba($color-white, 0);
        border-top-color: $color-background;
        filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.08));
        border-width: 5px;
        z-index: 999999;
        height: 10px;
        width: 10px;
        margin-top: -10px;
        margin-left: -5px;
        position: fixed;
        transform: rotate(180deg);
    }
}
