.rf-SignalingTitle {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__title {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 600;
        color: $color-grey-95;
        margin-bottom: spacing(1, -1px);
        display: block;
    }

    &__meta {
        display: flex;
    }

    &__date {
        display: inline-block;
    }

    &--is-for-modal {
        padding-top: spacing(2);
        padding-bottom: spacing(3);

        .rf-SignalingTitle__title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: spacing(1, 3px);
        }
    }
}
