.rf-TopicDetailView {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    .rf-PageDetail {
        &__action-component {
            align-self: start;
        }

        &__sidebar {
            padding-left: spacing(3);
        }
    }

    &__content-container {
        position: relative;
        height: 360px;
        overflow: hidden;

        &--expanded,
        &--no-content {
            height: unset;
        }
    }

    &__markdown {
        height: 100%;
    }
}
