.rf-ExpandableTopicCardRow {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    padding: spacing(2, -1px) spacing(2);
    padding-left: spacing(2, -2);

    &__preview {
        flex: 1;
        display: flex;
        align-content: space-between;
    }

    &__name {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;

        .rf-CustomerFrameworkIconList {
            margin-left: spacing(1);
            display: inline;
        }
    }

    &__external-link {
        margin-left: 8px;
        color: $color-grey-65;

        &:hover {
            color: $color-text;
        }

        .rf-Button__icon {
            font-size: 12px !important;
        }
    }

    &__footer {
        display: flex;
        margin-left: auto;
        align-items: center;

        .rf-AsssessmentCoverageBar {
            width: 96px;
            margin-right: spacing(2, -5px);
        }
    }

    &__checkbox {
        margin-left: spacing(1.5);
        min-height: auto;
    }
}
