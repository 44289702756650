.rf-PageDetailMeta {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: flex;
    border: 1px solid $color-grey-20;
    border-radius: 4px;
    padding: spacing(2) spacing(3);

    &__container {
        overflow: hidden;
        min-width: spacing(15);

        &:not(:first-child) {
            margin-left: spacing(3);
        }
    }

    &__label {
        white-space: nowrap;
    }
}
