.rf-AllTasksView {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    max-width: unset;

    &__content-container {
        width: 100%;
    }

    &__header-container {
        max-width: calc(1128px + #{spacing(8)});
        margin: 0 auto;
    }

    &__table-container {
        background-color: $color-background-blue;
        min-height: 100vh;
        margin-bottom: spacing(-15);
        margin-left: spacing(-5);
        margin-right: spacing(-5);
        padding: spacing(3) spacing(5);
        border-radius: 16px 0 0 0;
    }

    &__table {
        max-width: calc(1128px + #{spacing(8)});
        margin: 0 auto;
    }

    &__option-dropdown {
        width: min-content; // so that the container doesnt span across the whole parent width

        &.rf-OptionsDropdown--is-active {
            .rf-AllTasksView__option-dropdown-button {
                background-color: $color-grey-10;

                .rf-Button__inner {
                    color: $color-ruler-blue;
                }
            }
        }

        .rf-OptionsDropdown__dropdown-wrapper {
            right: unset;
            margin-top: spacing(0.5);
        }
    }

    &__option-dropdown-button {
        height: 32px;
        padding: 0 spacing(1);
        align-items: center;
        border: solid 1px $color-grey-40;

        &:active {
            background: unset;
        }

        .rf-Button__inner {
            color: $color-text;
        }
    }
}
