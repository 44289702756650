.rf-TabletProfileDropdown {
    @import 'styles/variables/animations';
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    -webkit-overflow-scrolling: touch;
    align-items: center;
    background: $color-white;
    border: solid 1px $color-grey-10;
    box-shadow: 0 8px 4px -4px rgba($color-grey-95, 0.04);
    max-height: calc(#{spacing(2)} + (6.5 * 40px));
    overflow-y: scroll;
    padding: spacing(2) spacing(6) spacing(2) spacing(2);
    position: fixed;
    right: 0;
    top: 100%;
    transition: opacity 0.15s $animation-appear-bezier;
    z-index: 9999;

    .rf-Checkbox {
        &__label {
            margin-left: spacing(2);
        }
    }

    &-enter {
        opacity: 0;
    }

    &-enter-done {
        opacity: 1;
    }

    &-exit-active {
        opacity: 0;
    }

    &-exit-done {
        opacity: 0;
    }

    // IE10+ browsers have a bug with top 100%, this ensures it will work on those browsers.
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        top: 66px;
    }
}
