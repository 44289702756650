.rf-CustomerSwitch {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    background-color: #fff;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: linear-gradient(rgba(243, 246, 255, 0.5), rgba(243, 246, 255, 0.5)),
        url('../../Cover/LoginBG.png');
    background-size: cover;

    &__container {
        padding: spacing(10);
        color: $color-input-text;
        max-width: 610px;
        width: 100%;
        border: 2px solid $color-ruler-pale;
        background-color: $color-white;
        border-radius: 8px;
    }

    &__name {
        line-height: 1;
    }

    &__customers {
        overflow-y: auto;
        max-height: 50vh;
        height: 100%;
        padding-right: spacing(1);

        &::-webkit-scrollbar-track {
            width: 4px;
            border-radius: 2.5px;
            background-color: $color-grey-10;
        }
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background: $color-grey-80;
            border-radius: 2px;
        }
        &::-webkit-scrollbar-button {
            height: 10px;
            visibility: hidden;
        }
    }

    &__button {
        width: 100%;
        background-color: $color-grey-10;
        padding: spacing(2);

        .rf-Button__inner {
            justify-content: space-between;
            width: 100%;
        }

        .rf-Icon--next-arrow {
            font-size: 20px;
        }

        &:hover {
            background-color: $color-ruler-pale;
            color: $color-ruler-blue;

            p {
                color: $color-ruler-blue;
            }
        }
    }
}
