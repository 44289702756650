.rf-InboxWidget {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__main-content-container {
        border-radius: 4px;
        background-color: $color-white;
        height: 64px;
        padding: 0 spacing(3);
    }

    &__week-count {
        color: $color-ruler-blue;
        font-size: 20px;
        white-space: nowrap;
    }

    &__text {
        font-weight: 500;
    }
}
