.rf-PageHeader {
    @import 'styles/variables/layout';
    @import 'styles/variables/backgrounds';
    @import 'styles/variables/colors';

    + .rf-PageDetail {
        padding-top: spacing(2.5);
    }

    &__page-title {
        margin: 0;
        flex: 1;
    }

    &__action-component {
        flex: 1;
        width: 100%;

        & > .rf-Row {
            justify-content: flex-end;

            & > .rf-Select__container {
                max-width: 160px;
            }

            & > .rf-Search {
                max-width: 224px;
            }

            & > .rf-OptionsDropdown {
                margin-left: spacing(1.5);
            }
        }
    }

    &--has-back-to,
    &--has-breadcrumbs {
        .rf-PageHeader__title-container {
            padding-top: spacing(2, 2px);
        }
    }

    &--is-unfollowed {
        .rf-PageHeader__page-title {
            @include stripedBG($color-grey-20, $color-background);
            background-size: 7px 7px;
            margin-left: -16px;
            margin-right: -24px;
            padding: spacing(1, 1px);
            padding-left: spacing(2);
            padding-right: spacing(3);

            .rf-PageTitle__title-text {
                padding-bottom: 0;
            }
        }

        .rf-PageTitle__suffix {
            align-self: baseline;
        }
    }
}
