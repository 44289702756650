.rf-InboxLinkedItems {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    padding-left: 0;

    &.rf-PageDetailMeta {
        background-color: $color-white;
        border: 0;
        flex-direction: column;

        .rf-Row {
            display: flex;
            flex-direction: column;
        }

        .rf-Icon svg {
            width: 1.3em;
            height: 1.3em;
        }
    }

    .rf-PageDetailMeta {
        &__label {
            font-size: 16px;

            & + p {
                margin-top: spacing(1);
            }
        }

        &__container:not(:first-child) {
            margin-left: 0;
        }

        &__container-editors-note {
            margin-top: spacing(2.5);
        }

        &__container {
            .rf-Paragraph--is-subtle {
                margin-left: spacing(2.7);
            }
        }
    }

    &__linked-item.rf-Tooltip {
        margin-left: 0;
    }

    &__radar-sub-container {
        white-space: normal;
        align-items: flex-start;
    }

    &__radar-phase {
        white-space: normal;
        word-break: break-word;
        margin-left: 0;
    }

    &__news {
        align-items: flex-start;

        &-sources {
            .rf-Paragraph--is-subtle {
                display: inline;

                .rf-NewsSourcesLabel {
                    margin-left: 0;
                }

                &.rf-InboxLinkedItems__source-label {
                    margin-left: spacing(1.7);

                    .rf-Paragraph--is-bold {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
