.rf-EditRiskModalContainer {
    &--is-for-bruto {
        max-width: 725px;

        .rf-DrawerModal__action-bar {
            max-width: 725px;
        }
    }

    .rf-DrawerModal__sidebar {
        padding-right: 0;
    }
}
