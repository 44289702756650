.rf-CustomerNavigation {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    width: 72px;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    background-color: $color-white;
    border-right: 1px solid $color-grey-40;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 100;
    align-items: center;
    justify-content: space-between;

    &__logo-container {
        margin-top: spacing(2);
        margin-bottom: spacing(3);

        .rf-CustomerNavigationItem__icon > .rf-Button__inner > .rf-Icon--logo {
            font-size: 40px;

            svg {
                width: 40px;
                height: 40px;
            }
        }
    }

    &__logo-item {
        .rf-Popper__content {
            transform: translateX(spacing(1));
        }
    }

    &__top-group,
    &__bottom-group {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__top-item {
        .rf-Popper__content {
            transform: translate(spacing(2.5), spacing(-1.25));
        }
    }

    &__bottom-group {
        margin-bottom: spacing(2);
    }

    &__bottom-item {
        .rf-Popper__content {
            transform: translate(spacing(2.5), spacing(1.25));
        }
    }

    &--has-banner {
        padding-top: 42px;
    }

    &--hide-border {
        border-right: 0;
    }
}
