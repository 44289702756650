.rf-ControlTopicsTable {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    .rf-Table__table {
        table-layout: fixed;
    }

    .rf-Table__header {
        font-weight: 400;

        &:first-child {
            width: 62%;
        }

        &:nth-child(2) {
            width: 13%;
        }

        &:nth-child(3) {
            width: 10%;
        }

        &:nth-child(4) {
            width: 10%;
        }

        &:nth-child(5) {
            width: 5%;
        }

        &:nth-child(6) {
            width: 5%;
        }
    }

    .rf-Table__body {
        .rf-Table__row {
            &:hover {
                .rf-ControlTopicsTable__remove-button {
                    visibility: visible;
                    color: $color-grey-65;

                    &:hover {
                        background-color: $color-grey-10;
                        color: $color-ui-red;
                        border-radius: 4px;
                    }
                }
            }

            .rf-Table__cell {
                padding: spacing(1) spacing(0.5);

                &:first-child {
                    padding-left: spacing(2);
                }
            }
        }
    }

    &__eye-icon {
        color: $color-grey-65;
    }

    &__log-icon {
        margin-top: spacing(0.5);
        margin-left: spacing(0.5);
    }

    &__remove-button {
        visibility: hidden;
        padding: spacing(0.5);

        .rf-Icon {
            font-size: 20px;
        }
    }

    .rf-TopicAssessmentIconContainer__button .rf-Button__inner {
        justify-content: start;
    }
}
