.rf-MonitoringReportTopicSelectionTableContainer {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    &__title-container {
        padding: 0 spacing(3);
    }

    &__warning {
        color: $color-ui-orange;
        width: 20px;
        height: 20px;
    }

    &__filters {
        padding: 0 spacing(3);
    }
}

// this is rendered in a react portal, so not available as a descendant of the above
.rf-MonitoringReportTopicSelectionTableContainer__warning-content {
    @import 'styles/variables/layout';

    width: 320px;
    text-align: left;
    padding: spacing(1) spacing(2);

    strong {
        font-weight: 600;
    }
}
