.rf-RiskGraph {
    position: relative;
    width: 100%;
    min-height: 280px;

    &__indicators {
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
    }
}
