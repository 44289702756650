@import 'styles/variables/colors';
@import 'styles/variables/layout';

.rf-ActiveCustomerFrameworks {
    display: flex;
    height: 12px;

    > * + * {
        margin-left: spacing(1) + 1px;
    }

    &__active-frameworks {
        display: flex;

        > * + * {
            margin-left: spacing(1) + 1px;
        }
    }

    &__framework-icon figure {
        height: 11px;
        width: 11px;
    }

    &__counter {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: $color-grey-80;
    }
}
