.rf-ExpandableList {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    @import 'styles/variables/animations';

    font-weight: 500;

    &__label {
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    &__icon {
        color: $color-grey-40;
        margin-top: spacing(1, -6px);
        margin-left: spacing(1, -2px);
        font-size: 12px;
        transition: color $animation-appear;
    }

    &__list {
        margin-top: spacing(2, -3px);

        > * + * {
            margin-top: spacing(2);
        }
    }

    &:hover {
        .rf-ExpandableList__icon {
            color: $color-grey-95;
        }
    }
}
