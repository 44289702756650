$timeline-outer-dot-size: 16px;
$timeline-inner-dot-size: 12px;
$timeline-inner-dot-spacing: $timeline-outer-dot-size - $timeline-inner-dot-size / 2;
$spacing-between-timeline: 16px;

@import 'styles/variables/colors';
@import 'styles/variables/layout';

.rf-TimelineGroup {
    display: flex;
    flex-direction: column;
    margin-left: $spacing-between-timeline;

    &__date {
        font-size: 12px;
        line-height: 21px;
        font-weight: 500;
        color: $color-grey-80;
    }

    &__metadata {
        display: inline-flex;
        position: relative;

        .rf-TimelineDot {
            position: absolute;
            transform: translate(-32px, 2px);
        }
    }

    &__content {
        overflow: hidden;
        margin-top: 2px;

        > * + * {
            margin-top: spacing(1);
        }
    }
}
