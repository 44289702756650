.rf-AllTasksTable {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    .rf-Table__table {
        // Make the table fixed so the widths don't vary accross months
        table-layout: fixed;

        .rf-Table__cell {
            padding-right: spacing(2);
        }
    }

    .rf-Table__header {
        &:nth-child(1) {
            width: 60%;
        }

        &:nth-child(2) {
            width: 17%;
        }

        &:nth-child(3) {
            width: 17%;
        }

        &:nth-child(4) {
            width: 6%;
        }

        @media screen and (max-width: 1200px) {
            &:nth-child(1) {
                width: 50%;
            }

            &:nth-child(2) {
                width: 22%;
            }

            &:nth-child(3) {
                width: 22%;
            }
        }
    }

    .rf-Table__body {
        .rf-Table__row {
            background-color: $color-white;
        }

        .rf-Table__cell:first-child {
            padding-left: 0;
        }
    }

    .rf-Table__cell {
        vertical-align: middle;
        height: 58px;
        padding-top: 0;
        padding-bottom: 0;
    }

    & > .rf-Table__table {
        .rf-SortHeader .rf-SortHeader__icon {
            color: $color-grey-65;
        }

        .rf-SortHeader--sorted .rf-SortHeader__icon {
            color: $color-grey-95;
        }
    }

    &__name-container {
        position: relative;
    }

    &__linked-item-container {
        width: 44px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .rf-IconLabel__icon {
            margin-right: 0;
            width: 100%;
            height: 100%;
        }

        &--is-sub-task {
            .rf-IconLabel__icon {
                color: $color-grey-65;
            }
        }

        &::after {
            content: '';
            width: 2px;
            height: 56px;
            position: absolute;
            left: 36px;
            background-color: $color-background-blue;
        }

        .rf-RiskLabel__risk-icon {
            margin-right: 0;
        }
    }

    &--no-linked-item-icon {
        .rf-AllTasksTable__name-container {
            margin-left: spacing(2);
        }
    }

    &--is-in-empty-state {
        & > .rf-Table__table > .rf-Table__body > .rf-Table__row > .rf-Table__cell {
            padding-left: spacing(3);
        }
    }
}
