.rf-RadarTopicLinkedItemsContainer {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    width: 100%;

    .rf-LinkList__linked-document {
        box-shadow: none;
        width: 100%;
        padding: spacing(2);
        text-decoration: none;
        cursor: pointer;
        border-radius: 4px;
        user-select: none;
        padding: spacing(1, 1) spacing(2);
        pointer-events: all;
        border: 1px solid $color-grey-40;

        &:hover {
            background-color: $color-grey-20;
        }

        .rf-LinkList {
            &__title {
                margin-right: 0;
                margin-left: spacing(2);
            }

            &__icon {
                color: $color-ruler-blue;
                font-size: large;
                margin-right: spacing(1);
            }
        }
    }
}
