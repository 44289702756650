.rf-TopicDesignAndEffectivenessEventCard {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    border-radius: 4px;
    border: 1px solid $color-grey-40;
    padding: spacing(1.5) spacing(2);
    margin-bottom: spacing(1);

    display: grid;
    grid-template-columns: 3fr 5fr;
    gap: spacing(2);

    &:last-child {
        margin-bottom: 0;
    }

    &__content-container {
        border-left: 1px solid $color-grey-40;
        padding-left: spacing(2);
        height: 100%;
        display: flex;
        align-items: center;
    }
}
