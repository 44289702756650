.rf-SortHeader {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';

    border: 0;
    background: 0;
    padding: 0;
    font-weight: inherit;
    display: flex;
    width: 100%;
    white-space: nowrap;

    align-items: center;

    &__sort-direction {
        margin-left: 5px;
    }

    &__asc-icon,
    &__desc-icon,
    &__icon {
        color: $color-icon;
        font-size: 12px;
    }

    &__asc-icon {
        margin-bottom: -5px;
    }

    svg {
        transition: $animation-appear;
    }

    &--sorted svg use {
        color: $color-text;
    }

    &--sorted-asc svg {
        transform: rotate(-180deg);
    }
}
