.rf-RiskWidget {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__content-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: spacing(1) spacing(1);
        height: 99px;
        position: relative;
    }

    &__count-link {
        height: 100%;
        width: 100%;
        margin-left: 0;
    }

    &__box-container {
        background-color: $color-grey-10;
        border-radius: 4px;
        height: 100%;
        padding-top: spacing(2);
        margin-left: 0;
    }

    &__count {
        font-size: 36px;
        font-weight: bold;
        line-height: 1;
        color: $color-text-header;
        text-align: center;
    }

    &__level-container {
        justify-content: center;
    }

    &__icon {
        font-size: 10px;

        &--is-high {
            svg {
                color: $color-widget-red;
            }
        }
        &--is-medium {
            svg {
                color: $color-ui-orange;
            }
        }
        &--is-low {
            svg {
                color: $color-ui-green;
            }
        }
    }
}
