.rf-CardRow {
    @import 'styles/variables/colors';
    @import 'styles/variables/defaults';
    @import 'styles/variables/layout';

    display: block;
    border-radius: $default-border-radius;
    border: solid 1px $color-grey-40;
    padding: spacing(2, -1px) spacing(2);

    &--is-link {
        cursor: pointer;
        text-decoration: none;

        &:hover {
            background-color: $color-grey-10;
        }
    }
}
