.rf-CustomerFormFields {
    @import 'styles/variables/layout';

    .rf-Field__label-input {
        min-width: 230px;
    }

    &__logo {
        .rf-Field__label-input {
            align-self: center;
        }
    }

    .rf-FieldSet--no-margin-on-last-child {
        .rf-CustomerFormFields__radio-group {
            margin-top: spacing(1.25);
        }
    }
}
