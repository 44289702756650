.rf-CustomerFrameworkSelect {
    @import 'styles/variables/layout';
    &__select-option {
        display: flex;
        align-items: center;

        > * + * {
            margin-left: spacing(1);
        }
    }
}
