.rf-InboxGroupTable {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    .rf-Table__table {
        // Make the table fixed so the widths don't vary accross months
        table-layout: fixed;

        .rf-Table__checkbox-content-wrapper {
            padding-left: spacing(2);
        }
    }

    .rf-Table__row {
        &:hover {
            .rf-InboxGroupTable__hidden-actions {
                opacity: 1;
            }

            .rf-InboxGroupTable__revert-ignore {
                opacity: 1;
            }
        }
    }

    .rf-Table__cell {
        &:nth-child(1) {
            padding-left: 0;
            width: 50%;

            .rf-Table__checkbox-content-wrapper {
                margin-left: spacing(1, 3);
            }
        }

        &:nth-child(2) {
            width: 15%;
        }

        &:nth-child(3) {
            width: 15%;
        }

        &:nth-child(4) {
            width: 10%;
        }
    }

    .rf-Table__body .rf-Table__row {
        background-color: $color-white;
    }

    &--is-empty {
        .rf-Table__cell {
            background-color: $color-white;
        }
    }

    &__deparment {
        width: 100%;
    }

    &__fetch-more-wrapper {
        text-align: center;
    }

    &__fetch-more {
        font-size: 14px;
        font-weight: 500;

        .rf-Icon.rf-Button__icon {
            font-size: 14px;
            margin-top: spacing(1, -4px);
        }
    }

    &__actions {
        .rf-Button--is-icon-button {
            padding: spacing(1, -1px);
        }
    }

    &__hidden-actions {
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
        display: flex;

        .rf-Button--is-icon-button {
            padding: spacing(1, -1px);
        }

        * + * {
            margin-left: spacing(1);
        }
    }

    &__tombstones {
        & > * + * {
            margin-top: spacing(1);
        }
    }

    &__revert-ignore {
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
        margin-right: spacing(1, -1);

        &.rf-Button--is-icon-button {
            padding: spacing(1, -1px);
        }
    }

    &__label {
        padding-right: spacing(2);
        padding-left: spacing(1.5);

        .rf-IconLabel__label {
            margin-left: spacing(0.5);
        }
    }
}
