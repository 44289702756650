.rf-DepartmentSwitchPopup {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__error-container {
        background-color: $color-ui-red-pale;
        padding: spacing(1.25);
        border-radius: 4px;
        color: $color-ui-red;
    }

    &__error-icon {
        padding-top: spacing(1);
    }

    &__error-text {
        color: $color-ui-red;
    }

    &__arrow-icon {
        align-self: flex-end;
        font-size: 20px;
    }

    &__message {
        margin-top: spacing(3);
    }

    &__active-department-text {
        color: $color-grey-80;
    }
}
