.rf-RelevantModalAddDepartment {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    &__filters {
        .rf-Search {
            max-width: 224px;
        }

        margin-bottom: spacing(2);
    }

    &__query-wrapper {
        height: 100%;
        max-height: 600px;
        overflow-y: auto;
        margin: 0 -24px;
        padding: 0 spacing(3);
    }

    &__table {
        .rf-Table__header {
            padding-top: 0;
        }

        .rf-Table__row {
            &:hover {
                box-shadow: 1px 4px 8px 0px rgba(41, 41, 136, 0.15);
            }
        }
    }

    &__spinner-wrapper {
        margin-top: spacing(2);
        overflow: hidden;
    }

    &__checkbox {
        .rf-Checkbox__checkbox {
            border: 1px solid $color-grey-40;
        }
    }
}
