.rf-PageNavigation {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__breadcrumbs {
        margin-bottom: spacing(2);
    }

    &__link {
        text-decoration: none;
        font-size: 14px;
        color: $color-grey-80;
        padding: 3px 0px;

        &:hover {
            color: $color-grey-95;
        }

        &.active {
            color: $color-grey-95;
            font-weight: 600;
            border-bottom: 2px solid $color-grey-95;
        }

        &--is-active {
            color: $color-ruler-blue;
            font-weight: 600;
        }

        &--is-disabled.active {
            color: $color-grey-80;
            font-weight: 600;
        }

        & + .rf-PageNavigation__link {
            margin-left: spacing(4);
        }
    }
}
