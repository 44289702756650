.rf-NormThemeCard {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    border: 0;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    padding: spacing(1, 4) spacing(2) spacing(2) spacing(2);

    &__content {
        text-decoration: none;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__title {
        margin-right: auto;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: spacing(0, 4);
    }

    &__card-header {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        font-size: 14px;
        font-weight: 500;
        min-height: 69px;
    }

    &__assessment-note-status {
        margin-right: auto;
        margin-left: spacing(0, 6);
    }

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__card &__profile-list {
        margin-top: spacing(1.5);
    }

    &__card-footer {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: auto;
    }

    &__status-label {
        line-height: 1.25;
    }

    &__compliance-count {
        justify-content: flex-end;
        margin-bottom: spacing(0, 4);
    }

    &:hover {
        background-color: rgba(247, 247, 250, 0.3);
    }

    &--is-disabled {
        cursor: auto;
        background-color: $color-grey-10;

        .rf-NormThemeCard__title {
            color: $color-grey-80;
        }

        &:hover {
            background-color: $color-grey-10;
        }
    }

    &__assessment-note-status {
        margin-top: 4px;
    }
}
