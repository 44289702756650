.rf-StepContainer {
    @import 'styles/variables/colors';

    &__step-counter {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.67;
        color: $color-grey-65;
        margin: 0 auto;
        max-width: 1128px;
        width: 100%;
    }

    .rf-Step {
        margin: 0 auto;
        max-width: 1128px;
        width: 100%;
    }

    .rf-WrappedParagraph {
        .rf-TextLink {
            // TODO not really the good place for this
            text-decoration: underline;
        }
    }
}
