.rf-LinkedNormsCard {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';
    @import 'styles/variables/layout';

    padding: 12px 16px;
    z-index: 1;

    &__title {
        font-size: 12px;
        font-weight: 600;
        color: $color-grey-95;
    }

    &__expired-icon {
        color: #ff9001;
        font-size: 12px;
        position: absolute;
        left: -18px;
    }

    &__topic-title {
        position: relative;
        color: $color-grey-95;
        flex: 1;

        &:hover {
            color: $color-ruler-blue !important;
            flex: 1;
            text-decoration: none;
        }
    }

    &__link {
        text-decoration: none;
    }

    &__icon {
        display: inline-block;
        margin-right: 8px;
    }

    &__topic-details {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 12px;
        margin-top: 4px;
        padding-left: 16px;
    }

    &__article-count {
        color: $color-grey-65;
        flex-grow: 0;
        flex-shrink: 0;
        margin-left: 4px;
    }

    &__edit {
        opacity: 0;
        position: absolute;
        font-size: 15px;
        right: 16px;
        top: 15px;
        color: $color-grey-40;
        cursor: pointer;
        transition: opacity $animation-appear;
    }

    &:hover {
        .rf-LinkedNormsCard__edit {
            opacity: 1;
        }
    }
}
