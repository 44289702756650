.rf-Card {
    @import 'styles/variables/defaults';
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    @import 'styles/variables/animations';

    border-radius: $default-border-radius;
    border: 1px solid $color-grey-20;
    background-color: $color-background;
    padding: spacing(2);
    position: relative;
    flex: 1;
    display: block;
    text-decoration: none;
    overflow: hidden;

    &__selected-icon {
        position: absolute;
        background-color: $color-ruler-blue;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        bottom: 17px;
        right: 18px;

        .rf-Icon {
            width: 14px;
        }
    }

    &__checkbox-container-outer {
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 100%;
        z-index: 10;
    }

    &__checkbox-container-inner {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        padding-right: 4px;
        height: 100%;
        border-right: 2px solid $color-ruler-blue;
        background-color: $color-ruler-blue;
        transform: translateX(-48px);
        transition: transform $animation-appear;

        .rf-Checkbox {
            margin-left: spacing(0.5);

            &__checkbox {
                border: 2px solid $color-white;
                border-radius: 2px;
                background-color: $color-ruler-blue;
            }
            &--is-checked > div {
                border-color: $color-white;
                background-color: $color-white;
            }

            &--is-checked:hover > div {
                border-color: $color-white;
                background-color: $color-white;
            }

            &:focus-within > div {
                border-color: $color-white;
            }

            &__mark {
                color: $color-ruler-blue;
            }
        }
    }

    &__border-container {
        width: 6px;
        height: 100%;
        background-color: $color-background;
        position: absolute;
        border-radius: 4px 0 0 4px;
        left: 4px;
        transform: translateX(48px);
        transition: transform $animation-appear;
    }

    &__checkbox-container-outer:hover &__checkbox-container-inner {
        transform: translateX(0);
    }

    &--is-checkbox-active &__checkbox-container-inner {
        transform: translateX(0);
    }

    &--is-checked &__checkbox-container-inner {
        transform: translateX(0);
    }

    &--is-button {
        cursor: pointer;

        &:hover {
            background-color: $color-grey-10;
        }
    }

    &--is-selected {
        border: 2px solid $color-ruler-blue;

        .rf-Icon {
            color: $color-background;
        }
    }

    &--is-small {
        padding: spacing(1) spacing(2);
    }

    &__checkbox-container-outer + * {
        transform: translateX(0);
        transition: transform $animation-appear;
    }

    &__checkbox-container-outer:hover + *,
    &--is-checked &__checkbox-container-outer + *,
    &--is-checkbox-active &__checkbox-container-outer + * {
        transform: translateX(48px);
        transition: transform $animation-appear;
    }

    // for initial animations
    .rf-Card-appear &__checkbox-container-inner {
        transform: translateX(-48px);
    }
    &__checkbox-container-outer.rf-Card-appear + * {
        transform: translateX(0);
    }

    .rf-Card-appear-active &__checkbox-container-inner,
    .rf-Card-exit &__checkbox-container-inner {
        transform: translateX(0);
    }
    &__checkbox-container-outer.rf-Card-appear-active + *,
    &__checkbox-container-outer.rf-Card-exit + * {
        transform: translateX(48px);
    }

    .rf-Card-exit-active &__checkbox-container-inner,
    .rf-Card-enter-done &__checkbox-container-inner {
        transform: translateX(-48px);
    }
    &__checkbox-container-outer.rf-Card-exit-active + *,
    &__checkbox-container-outer.rf-Card-enter-done + * {
        transform: translateX(0);
    }

    &__checkbox-container-outer:hover.rf-Card-enter-done + * {
        transform: translateX(48px);
    }
}
