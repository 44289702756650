.rf-StyleguideComponentTable {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    width: 100%;
    text-align: left;
    color: $color-grey-95;
    font-size: 14px;
    font-weight: 400;

    &__header {
        background-color: $color-grey-10;
        padding: 16px;
        color: #bfbfc2;
    }

    &__head &__row {
        border-bottom: 1px solid $color-white;
    }

    &__body &__row {
        border-bottom: 1px solid $color-grey-20;
    }

    &__body &__row &__cell {
        height: 72px;
        padding: 16px;
    }
}
