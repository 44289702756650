.rf-MonitoringReportTopicAssessmentSummaryTooltip {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    &__container {
        padding: spacing(2);
        width: 320px;
        text-align: left;
    }

    &__warning-banner {
        padding: spacing(1) spacing(2);
        border-radius: 4px;
        background-color: $color-ui-orange-pale;
        text-align: left;
    }

    &__link {
        font-size: 12px;
        font-weight: normal;
    }

    &__description {
        max-height: 100px;
        overflow-y: scroll;

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-grey-65;
            border-radius: 2px;
        }
    }
}
