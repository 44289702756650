.rf-AttentionIcon {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: inline-flex;
    color: $color-ui-red;
    align-items: center;
    font-size: 12px;

    &__label {
        margin-left: 5px;
        font-size: 14px;
        margin-bottom: 1px;
    }

    &__round-icon {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: $color-ui-orange;
    }
}
