.rf-ExpiredArticleWarning {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &--no-delete {
        height: 32px;
        background-color: $color-ui-orange-pale;
        border-radius: 4px;
        padding: 0 spacing(1);
    }

    .rf-ExpandableList__label {
        height: 32px;
        justify-content: space-between;
        background-color: $color-ui-orange-pale;
        border-radius: 4px;
        padding: 0 spacing(1);
    }

    .rf-ExpandableList__icon {
        color: $color-grey-80;
    }

    .rf-ExpandableList__list {
        margin-top: 0;
    }

    &__icon {
        color: $color-ui-orange;
        font-size: 12px;
    }

    &__label {
        color: $color-ui-orange;
    }

    &__delete-container {
        padding: spacing(1) spacing(2);
        background-color: $color-grey-10;
        border: 2px solid $color-white;
        border-radius: 2px;
    }
}
