.rf-AvatarUpload {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    position: relative;

    &__action-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > * + * {
            margin-top: spacing(1);
        }
    }

    &__button-remove,
    &__button-upload {
        display: block;
        appearance: none;
        border: 0;
        background: none;
        padding: 0;
        font-size: 14px;
        color: $color-ruler-blue;

        &:hover {
            text-decoration: underline;
        }
    }

    &__upload {
        visibility: none;
        opacity: 0;
        width: 0;
        height: 0;
        overflow: hidden;
        position: absolute;
    }

    &__button-remove {
        color: $color-ui-red;
    }
}
