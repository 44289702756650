.rf-DepartmentLabelTag {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    overflow: hidden;
    position: relative;

    &__tooltip-content {
        flex-wrap: wrap;
        max-width: 250px;
        justify-content: center;
    }

    &__tag {
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;

        &--show-inactive-departments-as-inactive {
            color: $color-grey-80;

            &.rf-DepartmentLabelTag__tag--is-current-department {
                color: initial;
            }
        }

        &--prevent-ellipsis {
            text-overflow: initial;
            overflow: initial;
        }
    }

    .react-tooltip-lite {
        padding: 0 !important; // to override the inline css
        // padding: spacing(1, -4) spacing(1) spacing(1) !important; // to override the inline css
        max-width: 600px !important; // to override the inline css
        display: inline-flex !important; // to override the inline css
        flex-wrap: wrap;
        justify-content: center;
    }
}
