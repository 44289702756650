.rf-ReleaseNotesModal {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    position: relative;
    background: #fff;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    width: spacing(100);
    z-index: 9999;
    padding: spacing(5);

    .rf-ComponentTitle {
        font-size: 24px;
    }

    &__close-button {
        color: $color-text;
        padding: 10px 0 10px 10px;

        .rf-Icon--close-modal.rf-Button__icon {
            font-size: 14px;
        }
    }

    &__date {
        margin-top: 6px;
        font-size: 14px;
        color: $color-grey-65;
    }

    &__changes {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    &__change + &__change {
        margin-top: 36px;
    }

    &__change-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.4em;
    }

    &__change-description {
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        white-space: pre-line;
    }

    &__attachments {
        margin-top: 36px;
        padding-bottom: 24px; // padding to prevent margin-collapse
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;

        > * + * {
            margin-top: 48px;
        }
    }

    &__attachment-image,
    &__attachment-video {
        max-width: 100%;
        box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.2);
    }

    &__buttons {
        margin-top: 21px;
    }

    &__button-prev,
    &__button-next {
        color: $color-ruler-blue;
        font-size: 14px;
    }

    &__button-next {
        margin-left: auto;
    }

    &__button-prev &__navigation-icon {
        margin-right: 8px;
        font-size: 14px;
    }
    &__button-next &__navigation-icon {
        margin-left: 8px;
        font-size: 14px;
    }
}
