.rf-RadioGroup {
    @import 'styles/variables/layout';

    .rf-Radio:not(:first-of-type) {
        margin-top: 0;

        margin-left: spacing(3, 2);
    }

    .rf-Radio__label {
        margin-left: spacing(2, 2);
    }
}
