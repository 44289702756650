.rf-MultiDepthExpandableTableHeader {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: grid;
    grid-template-columns: repeat(2, 104px);
    gap: spacing(1);

    @media screen and (min-width: 1200px) {
        grid-template-columns: repeat(4, 104px);
    }

    @media screen and (min-width: 1500px) {
        grid-template-columns: repeat(6, 104px);
    }
}
