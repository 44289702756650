.rf-ActionBar {
    @import 'styles/variables/colors';

    border-top: 1px solid $color-grey-20;
    padding: 16px 24px;
    padding-left: 244px;
    background: $color-white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    &__spacer {
        width: 100%;
        height: 73px;
    }
}
