.rf-ToolbarItem {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';

    display: flex;
    align-self: stretch;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background $animation-appear, color $animation-appear;
    color: $color-grey-95;
}
