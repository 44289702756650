.rf-MultiCustomerEmployeeEmailChangeModal {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__radio {
        padding: spacing(2);
        border-radius: 4px;
        background-color: $color-grey-10;
        width: 100%;

        &--checked {
            background-color: $color-ruler-pale;
        }
    }
}
