.rf-CreateCustomerFrameworkModalFrameworkContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__field-container {
        margin-top: spacing(5);
        overflow-y: scroll;
        max-height: 580px;
        padding-right: spacing(1);

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-grey-65;
            border-radius: 2px;
        }
    }

    &__framework-card {
        .rf-FrameworkCard__title {
            font-size: 14px;
            line-height: 1.24;
        }
    }
}
