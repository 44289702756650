.rf-AddLinkedMonitoringReportList {
    @import 'styles/variables/layout';

    &__card {
        margin-bottom: spacing(1);
    }

    &__buton {
        margin-bottom: spacing(1);
    }
}
