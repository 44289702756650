.rf-TaskControlRowTombstone {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    border-radius: 4px;
    align-items: center;

    & > * {
        padding: spacing(2, 2px) spacing(1, -4px) spacing(2, 1px);
        display: flex;
        align-items: center;
    }

    &__name {
        width: 33.7%;
        padding-left: spacing(2, -1px);
    }

    &__tags {
        width: 11.3%;
    }

    &__linked-item {
        width: 19.9%;
    }

    &__employees {
        width: 9.9%;
    }

    &__created-at {
        width: 12.9%;
    }

    &__priority {
        width: 4.5%;
    }

    &__name-icon {
        margin-right: spacing(1, 3px);
    }

    &__linked-item-icon {
        margin-right: spacing(1);
    }

    &__employee {
        & + .rf-TaskControlRowTombstone__employee {
            margin-left: spacing(1);
        }
    }

    &__priority-element {
        margin-right: spacing(1);
    }
}
