.rf-PageActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    &__primary,
    &__secondary {
        width: 100%;
    }

    &__primary {
        width: 100%;
        max-width: 512px;
    }

    &__secondary {
        margin-left: 16px;
        max-width: 400px;
        display: flex;
        justify-content: flex-end;
    }
}
