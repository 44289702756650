.rf-ToggleTag {
    @import 'styles/variables/colors.scss';
    @import 'styles/variables/animations.scss';

    border-radius: 18px;
    padding: 5px 16px;
    color: $color-ruler-blue;
    font-size: 14px;
    background: white;
    border: 1px solid $color-ruler-blue;
    font-weight: 500;
    transition: background $animation-appear;

    &--is-toggled {
        color: white;
        background: $color-ruler-blue;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    }
}
