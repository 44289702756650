.rf-Toolbar__container {
    @import 'styles/variables/layout';

    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    z-index: 100;
    align-items: center;

    .rf-ReleaseUpdateButton {
        margin-right: spacing(4);
    }
}

.rf-Toolbar {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    display: flex;

    align-items: center;
    background-color: rgba(white, 0.9);
    padding: spacing(3) spacing(4) spacing(3) 0;
    border-bottom-left-radius: 8px;
    border-left: solid 1px #ededf2;
    border-bottom: solid 1px #ededf2;
    width: 392px;

    &__task-tool {
        margin-left: spacing(5);
    }

    &__search {
        margin-left: spacing(7, -7px);
        width: 100%;
        margin-right: spacing(1);
    }

    &__navigation-tab {
        margin-left: spacing(5);
        width: 81px;
    }

    &__sub-menu {
        z-index: 9999;
        .rf-Popper__content {
            background: #ffffff;
            border: 1px solid #ededf2;
            box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
        }
    }

    &--no-context-switch {
        width: unset;
    }

    &--has-banner {
        margin-top: spacing(5, 6px);
    }
}
