.rf-ConsultantAlertBanner {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    @import 'styles/variables/defaults';

    align-items: center;
    background: $color-ruler-blue;
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: spacing(1, -2);
    padding-left: spacing(4);
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    width: 100%;
    will-change: transform;
    z-index: 997;
    font-size: 14px;

    &__message {
        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__button {
        border-color: $color-white;
        padding: 7px 16px;

        &:active {
            background: $color-ruler-pale;
        }
    }

    &--is-consultant + * + .rf-Toolbar .rf-Toolbar__context-switch {
        top: 0;
    }
}
