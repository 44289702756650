.rf-SearchToolItem {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__search {
        width: 100%;
        height: 32px;
    }
}
