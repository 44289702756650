.rf-HierarchyList {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    background-color: $color-ruler-pale;
    padding: spacing(3);

    .rf-HierarchyListItem {
        + .rf-HierarchyListItem {
            margin-top: spacing(1);
        }
    }
}
