@mixin stripedBG($backgroundColor: $color-ruler-blue, $stripeColor: transparent) {
    background: linear-gradient(
        45deg,
        $stripeColor 8.33%,
        $backgroundColor 8.33%,
        $backgroundColor 50%,
        $stripeColor 50%,
        $stripeColor 58.33%,
        $backgroundColor 58.33%,
        $backgroundColor 100%
    );
    background-size: 6px 6px;
}
