.rf-OnboardingIntroModal {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    position: relative;
    background: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    width: spacing(72);
    z-index: 9999;

    &__hero {
        max-height: spacing(33);
        background: $color-ruler-blue;
        object-fit: contain;
        border-radius: 4px 4px 0 0;
        overflow: hidden;
        border-bottom: 1px solid #e4e4e4;

        > img {
            width: 100%;
        }
    }

    &__content {
        padding: spacing(3) spacing(4);
        text-align: center;
    }

    &__intro {
        > * + * {
            margin-top: spacing(2);
        }
    }

    &__buttons {
        margin-top: spacing(4);
    }
}
