.rf-SignalingOverviewView {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    margin-top: spacing(7, 2);
    padding-top: 0;
    max-width: calc(100vw - 72px);

    &__container {
        background-color: $color-ruler-pale;
        padding: 0 spacing(8);
        border-radius: 16px 0 0 0;
        padding-top: spacing(5);
        margin: spacing(3) spacing(-5) 0;
        min-height: 100vh;
    }

    &__title {
        padding-bottom: spacing(3);
    }
}
