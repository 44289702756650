.rf-TaskTag {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    font-size: 12px;
    font-weight: 600;
    color: $color-text;
    border-radius: 4px;
    background-color: $color-grey-20;
    padding: spacing(1, -5px) spacing(1) spacing(1, -4px);
    white-space: nowrap;
}
