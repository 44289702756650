.rf-TaskIgnoredForControlTooltip {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__icon {
        color: $color-grey-65;
        margin-top: spacing(1, -4px);
        margin-right: spacing(1, -5px);

        .rf-Icon.rf-Button__icon {
            font-size: 16px;
        }
    }

    &__created-by {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        word-wrap: none;
    }

    &__content {
        padding: spacing(1, -4) spacing(1);
        text-align: start;
    }

    &__user {
        margin-left: spacing(1);
    }

    &__date {
        font-size: 14px;
        line-height: 1.71;
        color: $color-grey-80;
        margin-top: spacing(1, -6px);
    }

    &__reason {
        line-height: 1.5;
        font-size: 12px;
        color: $color-grey-95;
        margin-top: spacing(1);
        margin-bottom: spacing(1, -4px);
    }
}
