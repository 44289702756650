.rf-SidebarTaskWidgetContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: flex;
    height: 84px;
    width: 100%;
    background: $color-white;
    border-radius: 4px;
    user-select: none;
    padding-left: spacing(4);
    padding-top: spacing(3);
    padding-bottom: spacing(3);
    cursor: pointer;
    pointer-events: all;
    border: 1px solid $color-grey-20;

    &:hover {
        background-color: $color-grey-10;
    }
}
