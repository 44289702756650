.rf-PageDetailContentTombstones {
    @import 'styles/variables/layout';

    margin-top: spacing(2, 4);

    &__paragraph {
        margin-bottom: spacing(4);

        &:first-child {
            margin-bottom: spacing(5);
        }

        &:nth-child(4) {
            margin-bottom: spacing(11);
        }
    }
}
