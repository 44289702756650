.rf-ActionButton {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    .react-tooltip-lite {
        min-width: unset;

        > * {
            margin-left: spacing(2);
            margin-right: spacing(2);
        }
    }

    &__button {
        background: $color-grey-10;
        padding: spacing(1);

        &, &:focus {
            background: $color-grey-10;

            .rf-Icon.rf-Button__icon {
                color: $color-grey-95;
            }
        }

        &:hover {
            .rf-Icon.rf-Button__icon {
                color: $color-ruler-blue;
            }
            background: $color-ruler-pale;
        }
    }

    &--secondary {
        .rf-ActionButton__button {
            background: none;

            &, &:focus {
                background: none;

                .rf-Icon.rf-Button__icon {
                    color: $color-grey-65;
                }
            }

            &:hover {
                .rf-Icon.rf-Button__icon {
                    color: $color-ruler-blue;
                }
                background: $color-ruler-pale;
            }
        }
    }

    &--tertiary {
        .rf-ActionButton__button {
            background: none;

            &, &:focus {
                background: none;

                .rf-Icon.rf-Button__icon {
                    color: $color-grey-65;
                }
            }

            &:hover {
                .rf-Icon.rf-Button__icon {
                    color: $color-grey-80;
                }
                background: $color-grey-10;
            }
        }
    }

}
