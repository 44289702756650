@import 'styles/variables/layout';
@import 'styles/variables/colors';

.rf-ViewTaskModal {
    &__title {
        font-size: 20px;
    }

    &__metadata {
        padding-left: spacing(3);
        padding-right: spacing(3);
        margin-top: spacing(3);
        margin-bottom: spacing(3);
    }

    &__description {
        margin-top: spacing(1);
    }

    &__profile-list {
        & > .rf-UserProfileList__container {
            flex-wrap: wrap;
        }
    }

    &__profile-icon {
        margin-left: 0;
        margin-right: spacing(0.5);
        margin-bottom: spacing(0.5);
    }

    &__repeat-icon {
        color: $color-grey-65;

        &:hover {
            color: $color-text;
        }
    }

    &__review-button-icon svg {
        padding-top: spacing(0, 2);
        transform: scale(1.5);
    }

    &__date-priority-row {
        align-items: baseline;

        & > * {
            margin-right: spacing(6);
        }
    }
}
