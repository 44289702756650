.rf-ArticleRenderer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    font-size: 16px;

    &__alinea {
        font-size: inherit;
    }

    &__ref {
        text-decoration: underline;
    }

    li ul,
    ul ul,
    > * + *,
    &__lid > * + *,
    &__lijst > * + * {
        margin-top: spacing(2);
    }

    ul ul {
        margin-left: spacing(6);
    }

    &__afbeelding {
        width: 100%;
    }

    li span {
        position: absolute;
        font-weight: 500;
    }

    &__lid &__alinea,
    &__lijst &__alinea {
        margin-left: spacing(4.5);
    }

    &__title {
        width: 100%;
        padding: 5px;
        margin: 2px 0;
        background: $color-grey-10;
        font-weight: 500;
    }

    // Tables
    &__table {
        table {
            width: 100%;
        }
        margin: spacing(3) 0 spacing(5) 0;
    }

    &__th,
    &__td {
        border-bottom: 1px solid $color-grey-40;
        padding: spacing(1);
        min-width: 60px;
    }

    &__table &__alinea {
        margin: 0;
    }

    // Typography
    b {
        font-weight: normal;
    }

    &__cursief {
        font-style: italic;
    }

    &__vet {
        font-weight: 500;
    }

    &__ref {
        display: inline;
    }
}
