.rf-Markdown {
    @import 'styles/variables/colors';

    font-size: 16px;
    line-height: 1.5;

    p {
        font-size: inherit;
        color: $color-text;
        white-space: pre-line;
    }

    code {
        white-space: pre-line;
    }

    ul {
        list-style-type: disc;
    }

    li {
        margin-top: 6px;
    }

    ol,
    ul {
        padding-left: 1.5em;
    }

    ol {
        list-style-type: decimal;
    }

    strong,
    b {
        font-weight: 500;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: inherit;
        font-weight: 500;
    }

    h1 {
        font-size: 15px;
    }

    a {
        color: $color-ruler-blue;
        text-decoration: none;
    }

    table {
        width: 100%;
        font-size: 14px;
        text-align: left;
        border: 1px solid $color-grey-40;

        th {
            font-weight: 500;
            padding: 6px;
            border: 1px solid $color-grey-40;
        }

        td {
            padding: 6px;
            border: 1px solid $color-grey-40;

            div {
                word-break: normal;
            }
        }
    }

    img {
        max-width: 100%;
    }

    > * + * {
        margin-top: 12px;
    }

    &--paragraph-like {
        font-size: 14px;

        * {
            font-size: 14px;
            font-family: inherit;
            line-height: inherit;
        }
    }
}
