.rf-ControlTaskWidget {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__main-content-container {
        border-radius: 4px;
        background-color: $color-widget-light-blue;
        height: 64px;
        padding-left: spacing(3);
        z-index: 1;
        position: relative;
    }

    &__icon {
        font-size: 48px;
        position: absolute;
        z-index: 2;
        top: 10px;
        left: 16px;

        svg {
            color: $color-white;
        }
    }

    &__week-count {
        color: $color-ruler-blue;
        font-size: 20px;
        white-space: nowrap;
        z-index: 3;
        margin-left: 0;
        width: 32px;
        text-align: center;
    }

    &__text {
        font-weight: 500;
    }
}
