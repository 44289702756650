.rf-EditTaskTemplateModalContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__subtask-name-container {
        padding-bottom: spacing(2);
        border-bottom: 2px solid $color-grey-20;
    }

    &__consultant-fields-container {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }
}
