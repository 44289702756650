.rf-NotificationBubble {
    @import 'styles/variables/colors';

    background: $color-ruler-blue;
    border-radius: 10px;
    display: block;
    margin-left: 4px;
    text-align: center;
    min-width: 21px;
    color: $color-white;
    font-weight: 600;
    font-size: 13px;
    line-height: 1;
    padding: 3px 7px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    &--is-large-number &__amount {
        padding: 0 1px;
    }

    &__amount {
        margin-top: 1px;
    }
}
