.rf-TaskCompleteButton {
    @import 'styles/variables/colors';

    position: relative;
    border-radius: 50%;
    background-color: transparent;
    color: $color-grey-65;
    border: 1px solid $color-grey-65;
    width: 20px;
    height: 20px;
    padding: 0;
    flex-shrink: 0;
    cursor: pointer;

    input[type='checkbox'] {
        position: absolute;
        visibility: hidden;
        pointer-events: none;
    }

    .rf-Icon {
        position: absolute;
        top: 2px;
        left: 2px;
        opacity: 0;
    }

    &:hover {
        .rf-Icon--task-checkmark {
            opacity: 1;
        }
    }

    &:active {
        background-color: $color-grey-20;
    }

    &--is-completed {
        background-color: $color-grey-20;

        .rf-Icon--task-checkmark {
            opacity: 1;
        }

        &:hover {
            background-color: $color-white;

            .rf-Icon--task-checkmark {
                opacity: 0;
            }

            .rf-Icon--task-reopen {
                opacity: 1;
            }
        }
    }

    &--is-disabled {
        cursor: not-allowed;
    }
}
