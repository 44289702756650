.rf-PageTitle {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: flex;
    font-size: 32px;
    color: $color-text-header;
    font-weight: 700;
    white-space: normal;
    hyphens: auto;
    line-height: 1.33;
    align-items: center;

    &__sub-title {
        font-size: 18px;
        margin-top: spacing(4, -6px);
        display: flex;
        align-items: center;
    }

    &__suffix {
        margin-left: spacing(2);
        display: inline-flex;
        align-self: center;
        padding-bottom: spacing(1);
    }

    &__sub-title-suffix {
        margin-left: 15px;
    }

    &__icon {
        margin-right: spacing(2);
    }

    &__title-text {
        display: inline-block;
        position: relative;
        padding-bottom: spacing(1.5);
    }

    &--larger-title {
        font-size: 36px;
    }

    &--subtle {
        color: $color-grey-80;
    }

    &--has-back-to-link {
        margin-top: spacing(2);
    }
}
