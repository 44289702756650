.rf-LinkedItemsTable {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    background-color: $color-background-light;
    border-radius: 4px;

    &__header {
        padding: spacing(2, -2px) spacing(3) spacing(2, -3px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }

    &__title {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
    }

    &__list {
        padding: spacing(3);
        padding-top: spacing(0.5);
        grid-row-gap: spacing(2);
    }

    &--no-items {
        :hover {
            cursor: not-allowed;
        }

        .rf-LinkedItemsTable__arrow {
            color: $color-grey-65;
        }
    }
}
