.rf-NormArticleCount {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    min-width: 120px;
    font-size: 12px;
    display: flex;
    align-items: center;
    &--none {
        color: $color-grey-80;
    }

    .rf-Icon {
        font-size: 14px;
        margin-right: spacing(2, -6px);
        display: inline-block;
    }
}
