.rf-SubTasksTableContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    .rf-Table__header {
        &:nth-child(1) {
            width: 40%;
        }

        &:nth-child(2) {
            width: 20%;
        }

        &:nth-child(3) {
            width: 20%;
        }

        &:nth-child(4) {
            width: 20%;
        }
    }

    .rf-Table__body {
        .rf-Table__row {
            background: $color-white;

            &--is-inactive {
                background: $color-grey-10;
            }
        }
    }

    &__name {
        &--completed {
            color: $color-grey-80;
            text-decoration: line-through;
        }

        .rf-TaskCompleteButton {
            margin-right: spacing(1);
        }
    }

    &__date {
        &--completed {
            color: $color-grey-80;
        }
    }
}
