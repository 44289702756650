.rf-TextAttention {
    @import 'styles/variables/colors';

    &--show {
        time {
            position: relative;
            &::after {
                position: absolute;
                content: '';
                height: 1px;
                bottom: -1px;
                left: 0;
                right: 0;
                width: 100%;
                background: $color-ui-red;
            }
        }
    }
}
