.rf-ViewTaskModalSubTasksAndLogs {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    min-height: 170px;
    padding: spacing(2.5) spacing(3);
    background-color: $color-grey-10;
    border-radius: 4px;

    &__no-sub-task-message {
        margin-top: spacing(4);
    }
}
