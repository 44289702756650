.rf-PopperContainer {
}

.rf-Popper {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    z-index: 9998;

    &__content {
        min-width: 200px;
        margin: spacing(1);
        padding-bottom: spacing(1);
        background-color: $color-white;
        border-radius: 8px;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.05);
        border: 1px solid $color-grey-20;

        * > * {
            user-select: none;
        }

        &--has-single-content {
            padding-bottom: 0;
        }
    }

    &__title-container {
        margin-top: spacing(1, -4);
        border-bottom: 1px solid $color-grey-20;
        height: 50px;
        margin-bottom: spacing(1);
    }

    &__title {
        padding-left: spacing(3);
        padding-right: spacing(3);
    }

    &__item-container {
        margin: 0 spacing(1);
    }
}
