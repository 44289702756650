.rf-ProminentIconButton {
    @import 'styles/variables/colors.scss';

    $color-manatee-grey: #9196a7;

    background: $color-white;
    color: #d4d6d9;
    border: 1px solid $color-grey-10;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    padding: 10px;

    .rf-Icon.rf-Button__icon {
        font-size: 18px;
        color: $color-manatee-grey;
    }

    &:hover,
    &:focus {
        background: $color-ruler-pale;
        color: $color-ruler-blue;
    }
}
