.rf-DashboardWidgetContainer {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    min-height: 100vh;
    background-color: $color-widget-light-blue;
    margin: spacing(4.5, 1) spacing(-5) spacing(-15);
    padding: spacing(8.5) spacing(4) spacing(3.5) spacing(8);
    border-radius: 16px 0 0 0;

    .rf-ResponsiveGrid {
        max-width: 1140px;
        margin-left: 0;
        margin-right: auto;

        @media screen and (max-width: 1450px) {
            max-width: 770px;
        }
    }

    .react-grid-item.react-grid-placeholder {
        border-left: solid 10px $color-widget-light-blue;
        border-right: solid 10px $color-widget-light-blue;
        border-bottom: solid 22px $color-widget-light-blue;
    }
}
