.rf-Page {
    @import 'styles/variables/layout';

    height: 100%;
    width: 100%;
    position: relative;
    max-width: calc(1128px + #{spacing(8)});
    margin: 0 auto;
    padding-top: spacing(5, -4px);

    > .rf-PageHeader,
    .rf-Column .rf-PageHeader {
        padding-top: spacing(12, -1px);
        margin-top: 0;

        &--has-breadcrumbs {
            padding-top: spacing(6.5);
        }
    }

    .rf-PageNavigation + .rf-PageHeader {
        padding-top: spacing(12, -1px);
    }

    > .rf-BreadCrumbs,
    .rf-Column > .rf-BreadCrumbs {
        padding-top: spacing(6.5);

        & + .rf-PageNavigation {
            padding-top: 0;
        }
    }

    .rf-PageNavigation {
        padding-top: spacing(5, -2px);

        & + .rf-PageHeader {
            padding-top: spacing(4, 1px);
        }

        &--has-back-to {
            padding-top: spacing(2.5);
        }
    }

    &--for-overview {
        .rf-PageHeader {
            padding-left: spacing(3);
        }
    }
}
