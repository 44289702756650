.rf-ThemesDetailCard {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &--has-bottom-component {
        .rf-PinnableCard__card {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom: none;
        }
    }

    .rf-PinnableCard {
        &__header-container {
            width: 70%;
            padding: spacing(2);
        }

        &__card {
            padding: 0;
        }
    }

    &__sidebar {
        border-left: 1px solid $color-grey-20;
        margin: 0;
        height: 100%;
        min-height: 116px;
        display: flex;
        width: 30%;
    }

    &__sidebar-link {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 116px;
        width: 116px;
        padding: spacing(2);
    }

    &__non-applicable-card {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    &__risk-tooltip {
        padding: spacing(1.5);
    }

    &__risk {
        margin-top: spacing(.5);
    }

    &__warning-tooltip {
        width: 36px;
        text-align: left;
    }

    &__warning-icon {
        color: $color-ui-orange;
        font-size: 12px;
    }
}
