.rf-SAMLSettingsFormFields {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    border-radius: 4px;
    background-color: $color-grey-10;
    padding: spacing(3);

    &__information-url-field {
        .rf-Field__label-heading {
            align-self: center;
        }
    }

    &__information-url-input {
        .rf-Input__input {
            background-color: $color-white;
            color: $color-grey-80;
        }
    }

    &__copy-button {
        background: none;

        &:hover {
            background-color: $color-white;
        }
    }

    &__certificate-input {
        .rf-FileInput__uploaded-file {
            padding: spacing(1);
            border: 1px dashed $color-grey-40;
            background-color: $color-white;
            height: 40px;
        }

        .rf-FileInput__add-file-button {
            background-color: $color-white;
        }
    }
}
