.rf-LinkedMonitoringReportCard {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';

    padding: 10px 16px;
    z-index: 2;
    transition: opacity $animation-appear;
    display: flex;

    &__label {
        margin-right: auto;
    }

    &--is-faded {
        opacity: 0.4;
    }
}
