.rf-SectionList {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    font-size: 14px;
    line-height: 1.7;

    ol li > ol {
        margin-left: spacing(3);
    }

    li {
        margin-top: spacing(1);
    }

    > ol > li + li {
        margin-top: spacing(3);
    }

    &__toggle-button {
        align-items: flex-start;

        &:hover {
            color: $color-ruler-blue;
        }

        &--is-active {
            font-weight: 600;
        }

        .rf-Button__inner {
            align-items: baseline;
        }

        .rf-Button__icon {
            font-size: 8px !important;
        }
    }

    &__text-link {
        text-decoration: none;

        &:hover {
            color: $color-ruler-blue;
            text-decoration: none;
        }

        &.active {
            color: $color-ruler-blue;
            font-weight: 600;
        }
    }
}
