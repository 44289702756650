.rf-NormsGrid {
    @import 'styles/variables/layout';

    grid-template-columns: repeat(3, minmax(#{spacing(26)}, #{spacing(33.5)}));

    @media (min-width: 1245px) {
        grid-template-columns: repeat(4, minmax(#{spacing(26)}, #{spacing(33.5)}));
    }

    @supports (grid-template-columns: repeat(auto-fit, minmax(200px, 250px))) {
        @media (min-width: 1245px) {
            grid-template-columns: repeat(auto-fit, minmax(#{spacing(26)}, #{spacing(33.5)}));
        }

        @media (min-width: 2048px) {
            grid-template-columns: repeat(auto-fit, minmax(#{spacing(26)}, #{spacing(33.5)})) !important;
        }
    }
}
