.rf-AssessmentSectionItemCard {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    background-color: $color-grey-10;
    padding: spacing(2) 0 spacing(2, -2) spacing(3);

    &__row {
        margin-bottom: spacing(-2);
        margin-right: spacing(1);
    }

    &__button {
        padding: spacing(0, 4);
        color: $color-grey-65;

        &:hover,
        &:focus {
            background-color: $color-grey-20;
            border-color: $color-grey-20;

            .rf-Button__icon {
                color: $color-ui-red;
            }
        }
    }

    &__section {
        margin-top: spacing(4, 2);
        padding: spacing(3, -1) spacing(4) spacing(4);
        background-color: $color-grey-10;
    }

    .rf-Modal__spacer {
        padding-top: spacing(1);
    }
}
