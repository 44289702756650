.rf-AiSummaryVoteButton {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    position: relative;

    &__button {
        background-color: $color-background;
        border: none;
        border-radius: 4px;

        .rf-Icon {
            transition: transform .2s ease-in-out;
        }

    }

    &:hover {
        cursor: pointer;

        .rf-AiSummaryVoteButton__button {
            background-color: $color-background;

            .rf-Icon {
                transform: rotate(-30deg) scale(1.1);
            }
        }
    }

    &--upvote .rf-AiSummaryVoteButton__button {
        color: $color-ruler-blue;
    }

    &--downvote .rf-AiSummaryVoteButton__button {
        color: $color-ui-red;
        transform: rotate(-.5turn);
    }

    &__icon {
        position: absolute;
        z-index: 10;
        top: -6px;
        right: -6px;
        color: $color-background;
        background-color: $color-ui-red;
        border-radius: 100%;
        font-size: 16px;
        padding: spacing(.5);

        opacity: 0;
        transition: opacity .2s;
    }

    &--active {
        &:hover {
            .rf-AiSummaryVoteButton__icon {
                opacity: 1;
            }
        }

        &.rf-AiSummaryVoteButton--upvote .rf-AiSummaryVoteButton__button {
            color: $color-background;
            background-color: $color-ruler-blue;
        }

        &.rf-AiSummaryVoteButton--downvote .rf-AiSummaryVoteButton__button {
            color: $color-background;
            background-color: $color-ui-red;
        }

    }

    &--disabled {
        &.rf-AiSummaryVoteButton--upvote .rf-AiSummaryVoteButton__button,
        &.rf-AiSummaryVoteButton--downvote .rf-AiSummaryVoteButton__button {
            background-color: unset;
            color: $color-grey-10;
        }

        &:hover {
            cursor: not-allowed;
        }
    }
}
