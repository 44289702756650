.rf-RiskIndicator {
    @import 'styles/variables/colors';

    display: flex;
    align-items: center;

    svg {
        width: 8px;
        height: 8px;
    }

    &__indicator {
        &--is-inactive {
            color: $color-grey-40;
        }

        &--is-low {
            color: $color-ui-green;
        }

        &--is-warning {
            color: $color-ui-orange;
        }

        &--is-critical {
            color: $color-ui-red;
        }

        // Usage in table
        table td & {
            margin-top: 4px;
        }
    }

    &__label {
        font-size: 12px;
        background-color: rgba(white, 0.7);
        border-radius: 2px;
        padding: 0 4px;
        white-space: nowrap;
    }

    &--for-graph {
        svg {
            width: 18px;
            height: 16px;
        }
    }

    &--larger {
        svg {
            width: 12px;
            height: 12px;
        }
    }

    &--show-as-unmoved {
        .rf-RiskIndicator__indicator {
            color: $color-grey-65;
        }
    }
}
