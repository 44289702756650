.rf-Field {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    $label-width: 175px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    // this is meant to improve accessibility when tabbing for the strikelabel
    // component when used in a form, see the framework wizard step 3
    &:focus-within {
        .rf-StrikeLabel {
            span:before,
            span:after {
                background: $color-grey-65;
            }
        }
    }

    &__tooltip-icon {
        margin-top: spacing(0.5);
        color: $color-grey-65;
    }

    &__label-input,
    &__label-heading {
        align-self: flex-start;
        width: $label-width;
        white-space: normal;
        margin-right: spacing(2);
        font-weight: 600;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.71;
        color: $color-grey-95;
        display: flex;
        align-items: flex-start;
    }

    &__content {
        flex: 1;
        font-size: 14px;
    }

    &__error {
        font-size: 13px;
        margin-top: 4px;
        color: $color-ui-red;
    }

    &__hint {
        display: block;
        font-size: 14px;
        color: $color-grey-80;
        margin-left: 3px;
        margin-top: 4px;
        margin-bottom: 8px;
        width: 80%;
    }

    &--has-small-label {
        .rf-Field__label-input,
        .rf-Field__label-heading {
            min-width: 160px;
            max-width: 160px;
        }
    }

    &__suffix {
        margin-left: auto;
    }

    &--is-compact {
        display: block;

        .rf-Field__label-input,
        .rf-Field__label-heading {
            width: 100%;
            min-width: auto;
            margin-right: 0;
        }

        .rf-Field__content {
            margin-top: 4px;
        }
    }

    &--is-nested {
        background-color: $color-grey-10;
        padding: spacing(2);

        .rf-Field__label-input,
        .rf-Field__label-heading {
            width: $label-width - spacing(2);
        }
    }

    &--has-error {
        .rf-Input__input {
            border-color: $color-ui-red;
        }

        .rf-Select {
            // important needed to override default styling of react select :sad-pepe:
            border-color: $color-ui-red !important;
        }
    }
}
