.rf-LinkControlToTopicModal {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__loading {
        height: 485px;
    }

    &__container {
        margin-top: spacing(5);
        overflow-y: auto;
        height: 455px;
        padding-right: spacing(1);
        border-top: 1px solid $color-grey-40;

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-grey-65;
            border-radius: 2px;
        }
    }

    &__row {
        margin-top: spacing(1);
        padding-bottom: spacing(0.5);
        padding-left: spacing(1);
        border-bottom: 1px solid $color-grey-40;
    }
}
