.rf-TaskFormFields {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__template-container {
        padding-top: spacing(2);
        padding-bottom: spacing(1);
        border-bottom: solid 2px $color-grey-20;
    }

    &__row-container {
        gap: spacing(2);
    }

    &__repeat-field {
        .rf-Field__label-input {
            width: 100%;
        }
        .rf-Tooltip .rf-Icon {
            margin-top: 0;
        }
    }
}
