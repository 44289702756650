.rf-CustomerNavigationItem {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    &__icon {
        margin: spacing(1.5) 0;

        > .rf-Button__inner > .rf-Button__icon {
            font-size: 20px;
        }

        &:focus {
            color: $color-grey-65;
        }

        &--active {
            // the important is needed to override :focus
            color: $color-ruler-blue !important;
        }

        &--not-nav-button {
            cursor: default;
        }

        &:hover {
            background-color: $color-ruler-pale;
        }

        &--no-hover:hover {
            background-color: unset;
        }
    }
}

.rf-CustomerNavigationItem__single-item {
    @import 'styles/variables/layout';

    padding: spacing(2) spacing(3);
}
