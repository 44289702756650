.rf-CreateWidgetWidget {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';

    width: 778px;
    height: 162px;
    padding: 0 spacing(2);
    border-radius: 8px;

    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: $color-ruler-blue;
    opacity: 1;
    transition: opacity $animation-appear;

    // alignment
    position: fixed;
    z-index: 5;
    bottom: 32px;
    left: 228px; // to account for sidebar width
    right: 0;
    margin-left: auto;
    margin-right: auto;

    &__scroll-container {
        overflow-y: auto;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        height: 100%;
        gap: spacing(1) spacing(3);
        padding-top: spacing(3);

        &::-webkit-scrollbar-track {
            width: 4px;
            border-radius: 2.5px;
            background-color: $color-widget-blue;
        }
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background: $color-white;
            border-radius: 2px;
        }
        &::-webkit-scrollbar-button {
            height: 10px;
            visibility: hidden;
        }
    }

    &__widget-container {
        width: 96px;
        height: 96px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background-color: $color-widget-blue;
            overflow: hidden;

            .rf-CreateWidgetWidget__new-widget-icon {
                visibility: visible;
            }
        }
    }

    &__new-widget-icon-container {
        position: absolute;
        right: 4px;
        top: 4px;
    }

    &__new-widget-icon {
        visibility: hidden;
        color: $color-white;

        polygon {
            fill: $color-ruler-blue;
        }
    }

    &__widget-icon {
        width: 48px;
        height: 48px;
        padding: spacing(1);
        border-radius: 4px;
        background-color: $color-white;
        margin: spacing(2) auto 0 auto;

        i {
            color: $color-ruler-blue;
            font-size: 24px;
        }
    }

    &__widget-text {
        color: $color-white;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
    }

    &__create-modal {
        left: 114px;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    &--is-faded {
        opacity: 0.2;
        transition: opacity $animation-appear;

        .rf-CreateWidgetWidget__scroll-container {
            pointer-events: none;
        }
    }
}
