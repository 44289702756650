.rf-RelevantMatrixCard {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    padding: 10px 16px;
    max-width: 328px;

    &__title {
        font-size: 12px;
        font-weight: 600;
        color: $color-grey-95;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &__suggestie {
        font-size: 10px;
        color: $color-grey-95;
        white-space: nowrap;
    }

    &__remove {
        color: $color-grey-65;
    }

    &--is-suggested {
        border-color: #bfbfc2;
        border-style: dashed;

        .rf-RelevantMatrixCard__title {
            color: $color-ruler-blue;
        }
    }
}
