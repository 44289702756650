.rf-FrameworkAbbreviationList {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__tooltip {
        display: inline-block;
    }

    &__count {
        border: 1px solid $color-ruler-pale-dark;
        background-color: $color-ruler-pale;
        border-radius: 100%;
        padding-right: 2px;
        font-size: 12px;
        margin-left: spacing(1, -4);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        color: $color-grey-80;
    }
}
