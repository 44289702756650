.rf-GroupedInboxControlTable {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    .rf-Table__table {
        // Make the table fixed so the widths don't vary accross months
        table-layout: fixed;


        .rf-Table__checkbox-content-wrapper {
            padding: 0;
            padding-left: spacing(2);
        }
    }

    .rf-Table__row {
        &:hover {
            .rf-GroupedInboxControlTable__hidden-actions {
                opacity: 1;
            }

            .rf-GroupedInboxControlTable__revert-ignore {
                opacity: 1;
            }
        }
    }

    .rf-Table__cell--has-checkbox .rf-Table__checkbox-container-outer {
        height: 54px;
        border-radius: 4px;
    }

    td {
        padding-top: 0;
        padding-bottom: 0;
        height: 56px;
    }

    .rf-Table__header {
        padding-top: 0;

        .rf-SortHeader__icon {
            color: $color-grey-65;
        }

        &:nth-child(2) {
            padding-left: 0;
            width: 48%;
        }

        &:nth-child(3) {
            width: 15%;
        }

        &:nth-child(4) {
            width: 15%;
        }

        &:nth-child(5) {
            width: 10%;
        }
    }

    .rf-Table__expanded-row {
        > td {
            background-color: $color-white;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    .rf-Table__row--is-expanded {
        > .rf-Table__cell,
        > .rf-Table__row-action {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: none;
        }
    }

    > .rf-Table__table > .rf-Table__body > .rf-Table__row {
        background-color: $color-white;

        > td:nth-child(2) {
            transform: translateX(-24px);
            border-left: 1px solid $color-grey-40;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            overflow: visible;

            > .rf-Table__checkbox-container-outer {
                overflow: hidden;
            }

            .rf-Table__checkbox-content-wrapper {
                margin-left: spacing(1, -1);
            }

            &::before {
                content: '';
                display: block;
                width: 24px;
                top: -1px;
                bottom: -1px;
                border-top: 1px solid $color-grey-40;
                border-bottom: 1px solid $color-grey-40;
                right: -24px;
                position: absolute;
            }
        }

        &--has-children > td:nth-child(2) {
            transform: none;
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            overflow: hidden;

            &::before {
                border-top: none;
                border-bottom: none;
            }
        }
    }

    &--has-single-alert,
    &--has-all-single-department {
        .rf-Table__header {
            &:nth-child(1) {
                width: 50%;
            }

            &:nth-child(2) {
                padding-left: spacing(0.5);
                width: 15%;
            }

            &:nth-child(3) {
                width: 15%;
            }

            &:nth-child(4) {
                width: 10%;
            }

            &:nth-child(5) {
                width: unset;
            }
        }

        > .rf-Table__table > .rf-Table__body > .rf-Table__row {
            > td:first-child {
                .rf-Table__checkbox-content-wrapper {
                    margin-left: spacing(1, -1);
                }
            }

            > td:nth-child(2) {
                transform: translateX(0);
                border-left: none;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                overflow: hidden;

                &::before {
                    content: '';
                    display: none;
                    border-top: none;
                    border-bottom: none;
                }
            }
        }
    }

    &--is-empty {
        .rf-Table__cell {
            background-color: $color-white;
        }
    }

    &__deparment {
        width: 100%;
    }

    &__actions {
        .rf-Button--is-icon-button {
            padding: spacing(1, -1px);
        }
    }

    &__hidden-actions {
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
        display: flex;

        .rf-Button--is-icon-button {
            padding: spacing(1, -1px);
        }

        * + * {
            margin-left: spacing(1);
        }
    }

    &__tombstones {
        & > * + * {
            margin-top: spacing(1);
        }
    }

    &__revert-ignore {
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
        margin-right: spacing(1, -1);

        &.rf-Button--is-icon-button {
            padding: spacing(1, -1px);
        }
    }

    &__label {
        padding-right: spacing(2);
        padding-left: spacing(1.5);

        .rf-IconLabel__label {
            margin-left: spacing(0.5);
        }
    }
}
