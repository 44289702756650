.rf-TaskModalSubTaskSelection {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    padding: spacing(2.5) spacing(3);
    background-color: $color-grey-10;
    border-radius: 4px;

    &__list {
        .rf-CheckboxList__checkbox {
            padding: spacing(2);
            height: 48px;
            margin-bottom: spacing(0.5);

            background-color: $color-white;
            border-radius: 4px;
            border-bottom: none;

            &:first-of-type {
                padding-top: spacing(2);
            }
        }
    }
}
