.rf-CardList {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    color: $color-text;
    font-size: 14px;
    font-weight: 500;

    &__loading {
        width: 100% !important;
        margin-top: spacing(4);
        margin-bottom: spacing(4);
        margin-left: spacing(1, 4);
    }

    &__loading-more {
        width: 100% !important;
        margin-top: spacing(4);
        margin-bottom: spacing(4);
        margin-left: spacing(1, 4);
    }

    // IE11 fallback
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: stretch;
    // end of - IE11 fallback

    .rf-CardListItem {
        flex-basis: 100%;
        padding: spacing(2) spacing(2);
        margin-bottom: spacing(1);
    }

    // IE11 fallback
    // 2 columns
    &--2-columns .rf-CardListItem {
        width: calc(50% - 16px);
    }

    // 3 columns
    &--3-columns .rf-CardListItem {
        width: calc(33.3% - 16px);
    }

    // 4 columns
    &--4-columns .rf-CardListItem {
        width: calc(25% - 16px);
    }
    // end of - IE11 fallback

    // If browser supports display grid
    @supports (display: grid) {
        display: grid;
        grid-column-gap: spacing(2);

        // 2 columns
        &--2-columns {
            grid-template-columns: 1fr 1fr;
        }

        &--2-columns .rf-CardListItem:nth-of-type(1),
        &--2-columns .rf-CardListItem:nth-of-type(2) {
            border-top: 1px solid $color-grey-40;
        }

        // 3 columns
        &--3-columns {
            grid-template-columns: 1fr 1fr 1fr;
        }

        &--3-columns .rf-CardListItem:nth-of-type(1),
        &--3-columns .rf-CardListItem:nth-of-type(2),
        &--3-columns .rf-CardListItem:nth-of-type(3) {
            border-top: 1px solid $color-grey-40;
        }

        // 4 columns
        &--4-columns {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        &--4-columns .rf-CardListItem:nth-of-type(1),
        &--4-columns .rf-CardListItem:nth-of-type(2),
        &--4-columns .rf-CardListItem:nth-of-type(3),
        &--4-columns .rf-CardListItem:nth-of-type(4) {
            border-top: 1px solid $color-grey-40;
        }

        // Reset IE11 fallback
        &--multi-column .rf-CardListItem {
            width: auto;
            margin-left: 0;
            margin-right: 0;
        }
        // end of - Reset IE11 fallback
    }
}
