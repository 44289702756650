.rf-CreateNewAssessmentSectionButtonContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &.rf-Row {
        margin-top: spacing(6);
    }

    &__row {
        border-top: 2px solid $color-grey-20;
    }

    &__column {
        margin-left: 0;
        position: absolute;
        background-color: $color-background;
        padding: 0 spacing(2);

        .rf-Button {
            font-weight: 500;
        }
    }
}
