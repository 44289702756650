.rf-LinkedRiskCard {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';
    @import 'styles/variables/layout';

    padding: 10px 16px;
    z-index: 2;
    transition: opacity $animation-appear;
    padding: 10px 16px;
    z-index: 6;

    &__title {
        font-size: 12px;
        font-weight: 600;
        color: $color-grey-95;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &--show-text-overflow &__title {
        text-overflow: unset;
        overflow: unset;
        white-space: unset;
        display: flex;
        align-items: baseline;
    }

    &__description {
        padding-left: spacing(3);
        font-size: 12px;
        color: $color-grey-80;
    }

    &__icon {
        display: inline-block;
        margin-right: 8px;
    }

    &--is-faded {
        opacity: 0.4;
    }

    &__attention-icon {
        margin-right: spacing(0, 4px);
    }

    &__edit {
        font-size: 12px;
        opacity: 0;
        color: $color-grey-40;
        cursor: pointer;
        transition: opacity $animation-appear;
        display: flex;
        align-items: center;

        & > .rf-Button {
            font-size: 14px;
        }
    }

    &__name {
        display: inline-block;
    }

    &:hover {
        .rf-LinkedRiskCard__edit {
            opacity: 1;
        }
    }
}
