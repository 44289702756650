.rf-DashboardView {
    @import 'styles/variables/colors.scss';
    @import 'styles/variables/layout';
    position: relative;

    &__button-container {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__initial-band {
        padding-top: 40px;
    }

    &__customer-container {
        padding-top: 14px;
    }

    &__title-container {
        text-align: center;

        > * + * {
            margin-top: 12px;
        }
    }

    &__title {
        font-size: 32px;
        hyphens: none;
        line-height: 1.5;
    }

    &__button {
        font-weight: 500;
        font-size: 14px;
    }

    &__illustration {
        width: 500px;
    }

    &__suffix {
        font-size: 18px;
        line-height: 1.67;
    }
}
