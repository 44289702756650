.rf-AsssessmentCoverageBarSmall {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    width: 100%;
    max-width: 120px;
    text-align: right;
    position: relative;

    &__total-value {
        color: $color-grey-80;
        font-size: 12px;
        user-select: none;
    }

    &__details {
        margin-top: 6px;
    }

    &__assessment-coverage-bar {
        background-color: $color-grey-40;
        height: 4px;
        border-radius: 3px;
        overflow: hidden;
        display: flex;
        position: relative;
    }

    &__assessment-coverage-bar-container {
        position: relative;
        align-items: flex-end;
    }

    &__assessment-coverage-bar-item {
        height: 100%;
        background-color: $color-ruler-blue;
        transition: width 0.225s ease-in-out;
        border-radius: 0;

        &--assessed {
            opacity: 1;
        }

        &--noted {
            opacity: 0.25;
        }

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 15px;
            top: 15px;
            margin-top: -5px;
        }
    }

    &__assessment-coverage-details {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        font-size: 12px;
        border-radius: 4px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px $color-grey-40;
        background-color: $color-white;
        text-align: center;
        top: -50px;
        padding: spacing(0.5) spacing(1);
        transform: translate(-50%);
        left: 50%;
        white-space: nowrap;
        transition: opacity 0.2s linear, visibility 0.2s 0s;
        z-index: 1;
        &:hover {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.2s linear, visibility 0s 0.2s;
        }
    }

    &__assessment-coverage-bar-container:hover &__assessment-coverage-details {
        opacity: 1;
        visibility: visible;
    }

    &__loading-wrapper {
        height: 25px;

        .rf-Spinner {
            margin-top: auto;
            opacity: 0.5;
        }
    }
}
