.rf-CustomerFrameworkList {
    @import 'styles/variables/layout';

    &__framework-item {
        display: flex;
        align-items: center;
        font-size: 12px;
        white-space: nowrap;

        > * + * {
            margin-left: spacing(1);
        }

        & + .rf-CustomerFrameworkList__framework-item {
            margin-top: spacing(1);
        }
    }
}
