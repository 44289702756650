.rf-RadarDot {
    @import 'styles/variables/layout';
    @import 'styles/variables/animations';
    @import 'styles/variables/backgrounds';
    @import 'styles/variables/colors';

    width: 30px;
    height: 30px;
    margin-left: -15px;
    margin-top: -15px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: popIn 1s $animation-appear-bezier forwards;
    cursor: pointer;

    &__tooltip-content {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 316px;
        padding: spacing(1, -2) 0;
    }

    &__tooltip {
        &--is-unfollowed {
            .react-tooltip-lite {
                @include stripedBG($color-grey-20, $color-background);
                background-size: 7px 7px;
            }
        }
    }
}

@keyframes popIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
