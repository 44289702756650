.rf-RelevantModalAddTopic {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    &__filters {
        margin-bottom: spacing(2);

        .rf-Search {
            max-width: 224px;
        }
    }

    &__query-wrapper {
        height: 605px;
        margin: 0 -24px;
        overflow-y: hidden;
        padding: 0 spacing(1.5) 0 spacing(3);
        position: relative;

        > .rf-InfiniteScroll {
            position: relative;
            height: 100%;
        }
    }

    &__table {
            max-height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-right: spacing(1.5);


            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background: $color-grey-65;
                border-radius: 2px;
            }

        .rf-Table__row-action {
            padding-right: spacing(2);
        }

        .rf-Table__header {
            padding-top: 0;
            border-top: none;
            position: sticky;
            top: 0px;
            background-color: $color-background;
            z-index: 1000;
        }
    }

    &__spinner-wrapper {
        margin-top: spacing(2);
        overflow: hidden;
    }

    &__preview {
        line-height: 1.33;

        &.rf-Button .rf-Icon {
            color: $color-grey-65;
        }

        .rf-Button__inner {
            display: block;
        }
    }

    &__tool-tip {
        width: 100%;
        max-width: 200px;
    }

    &__checkbox {
        .rf-Checkbox__checkbox {
            border: 1px solid $color-grey-40;
        }
    }
}
