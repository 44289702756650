.rf-CreateDepartmentModal {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    width: 744px;

    &.rf-Modal .rf-Form {
        margin-bottom: 0;
        min-height: 303px;
    }

    .rf-Modal__content {
        padding: spacing(3);
    }

    &__modal-content-container {
        position: relative;
        display: grid;
        grid-template-columns: 25% 75%;
        margin-right: spacing(2);
        gap: spacing(3) 0;
    }

    &__table-title {
        margin-bottom: auto;
        margin-left: 0;
    }

    &__table-container {
        overflow-y: scroll;
        height: 100%;
        max-height: 50vh;
        grid-column: 2;

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-grey-65;
            border-radius: 2px;
        }
    }

    &__table-header-row {
        display: grid;
        grid-template-columns: 40% 60%;
        border-bottom: solid 1px $color-grey-20;
        padding: spacing(1, -3) 0;
        margin-right: spacing(1);
        margin-bottom: auto;
    }

    &__name {
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__table-row-container {
        width: 100%;
        height: 100%;
        padding-right: spacing(1, -2);
    }

    &__table-row {
        display: grid;
        grid-template-columns: 42% 58%;
        border-bottom: solid 1px $color-grey-20;
        margin: 0;
        padding: spacing(1, 2) 0;

        &:first-of-type {
            padding-top: 0;
        }
    }

    &__radio {
        justify-content: center;
    }
}
