.rf-ComponentTitle {
    @import 'styles/variables/colors';

    font-size: 18px;
    color: $color-text-header;
    font-weight: 600;

    &--is-bold {
        font-weight: 800;
    }

    &--is-to {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    &--is-disabled {
        color: $color-grey-80;
        text-decoration: line-through;
    }

    &--is-subtle {
        color: $color-grey-80;
    }
}
