.rf-ModalTitle {
    @import 'styles/variables/colors';

    font-size: 20px;
    color: $color-text-header;
    font-weight: 700;
    white-space: normal;
    hyphens: auto;

    &--is-subtle {
        color: $color-grey-80;
    }
}
