.rf-UserProfileLabel {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    @import 'styles/variables/animations';

    &__avatar-container {
        border-radius: 50%;
        height: 32px;
        width: 32px;
        background: $color-ruler-pale;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        transition: border-color $animation-appear, color $animation-appear;
        border: 1px solid $color-ruler-pale-dark;
        line-height: 1;
        color: $color-grey-80;
        text-transform: uppercase;
    }

    &--is-small {
        .rf-UserProfileLabel__avatar-container {
            height: 24px;
            width: 24px;
        }
    }
}
