@import 'styles/variables/colors';
@import 'styles/variables/layout';

.rf-RiskGraphBackground {
    user-select: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-left: 1px solid $color-grey-65;
    border-bottom: 1px solid $color-grey-65;

    &__axis-label {
        font-size: 14px;
        font-weight: 500;
        color: $color-grey-65;
        padding: spacing(1);
    }

    &__x-axis-description {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    &__y-axis-description {
        position: absolute;
        top: 0;
        left: 0;
        transform: rotate(90deg) translateY(-100%);
        transform-origin: left top;
    }
}
