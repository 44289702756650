.rf-CustomerFrameworkProfileModal {
    @import 'styles/variables/layout';

    min-height: 469px;

    &__field-container {
        margin-top: spacing(5);
    }

    &__delete-confirm-modal {
        min-height: unset;
        width: 590px;

        .rf-Modal__content .rf-Column {
            margin-bottom: spacing(3);
        }

        &-framework-icon {
            display: inline-flex;
            margin: 0 spacing(1);
        }
    }
}
