.rf-InboxInternalAlertsContainer {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    &__container {
        padding-bottom: spacing(4);
    }

    &__title {
        padding-bottom: spacing(3);
    }

    &__fetch-more-button-container {
        padding-top: spacing(1);
    }
}
