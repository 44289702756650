.rf-WidgetDepartmentsTooltip {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__label-tag {
        &.rf-LabelTag {
            height: 21px;
            color: $color-grey-80;
            border-radius: 12px;
            padding: 0 spacing(1);

            max-width: 95px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &--is-current-department.rf-LabelTag {
            color: initial;
        }

        &--has-white-bg.rf-LabelTag {
            background: $color-white;
        }
    }

    &__tooltip {
        .react-tooltip-lite {
            padding: spacing(0.5) spacing(1) spacing(1) !important; // to override the inline css
            max-width: 600px !important; // to override the inline css
            display: inline-flex !important; // to override the inline css
            flex-wrap: wrap;
            justify-content: center;
        }

        .rf-WidgetDepartmentsTooltip__label-tag {
            margin-top: spacing(0.5);

            &:nth-child(n + 2) {
                margin-left: spacing(0.5);
            }
        }
    }
}
