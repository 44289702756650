.rf-PdfPreviewModal {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    position: relative;
    background: #fff;
    border-radius: 3px;

    max-width: 760px;
    width: 100%;
    height: calc(100vh - 48px);
    display: flex;
    flex-direction: column;
    margin: spacing(1);

    &__close-button {
        color: $color-text;
    }

    &__header {
        border-bottom: 1px solid #e0e1e4;
        padding: spacing(2);
        flex-shrink: 0;
    }

    &__button-container {
        width: 100%;
    }

    &__title {
        font-size: 16px;
        font-weight: 800;
        color: $color-text;
        line-height: 1;
        font-weight: 800;
    }

    &__content {
        flex: 1;
    }

    &__spinner {
        height: 100%;
    }

    &__error {
        color: $color-text;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__object {
        width: 100%;
        height: 100%;
    }

    &__close-button {
        background: none;
        border: 0;
        padding: 0;
        margin: 0;
        font-size: 10px;

        .rf-Icon.rf-Icon--close-modal {
            font-size: 10px;
        }
    }

    &__close-icon {
        font-size: 14px;
        color: $color-icon;
    }
}
