@import 'styles/variables/layout';

.rf-IconLabel {
    padding: 0;

    &__wrapper {
        display: flex;
        align-items: center;
        font-size: 14px;
        text-decoration: none;
        flex-wrap: nowrap;
    }

    &--is-button {
        background: transparent;
        border: 0;
        appearance: none;
        display: block;
        width: 100%;

        &:hover {
            text-decoration: underline;
        }
    }

    &--is-rtl &__wrapper {
        justify-content: flex-end;
        display: flex;
    }

    &__icon {
        margin-right: spacing(0.5);
    }

    &__label {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &--is-button,
    &--is-link,
    &--is-bold {
        font-weight: 500;
    }
}
