.rf-SectionTitle {
    @import 'styles/variables/colors';

    font-size: 18px;

    &--larger {
        font-size: 20px;
    }

    &--is-bold {
        font-weight: bold;
    }

    &--emphasis {
        font-weight: 500;
    }

    &--is-subtle {
        color: $color-grey-80;
    }

    &--is-underlined {
        border-bottom: 2px solid $color-grey-95;
        padding-bottom: spacing(0.25);
        margin-top: spacing(0.25);
    }

    &--is-capitalized {
        text-transform: capitalize;
    }
}
