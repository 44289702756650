.rf-Slider {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    height: 4px;
    padding: 0;
    border-radius: 2px;
    margin-top: 10px;

    .rc-slider-rail {
        background: $color-grey-20;
    }

    .rc-slider-track {
        background: $color-grey-65;
        opacity: 0.4;
    }

    .rc-slider-handle {
        border: 2px solid $color-ruler-blue;
        height: 16px;
        width: 16px;
        background-color: $color-grey-20;
        z-index: 1;
    }

    // style dot as limiter
    .rc-slider-dot {
        border: 0;
        border-radius: 50%;
        width: 16px;
        margin-left: -8px;
        height: 16px;
        background-color: $color-grey-20;
        bottom: -6px;
        cursor: default;
    }

    &__label-holder {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    &--is-single-slider {
        .rc-slider-track {
            background: $color-grey-20;
            opacity: 1;
        }
    }

    &--is-unmoved {
        .rc-slider-handle {
            border: 2px solid $color-grey-65;
        }
    }

    &.rc-slider-disabled {
        .rc-slider-handle {
            border: 2px solid $color-grey-65;
            pointer-events: none;
            background: $color-grey-40;
        }
    }

    &--hint-direction {
        .rc-slider-handle {
            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 7px;
                height: 7px;
                border: 1px solid $color-ruler-blue;
                border-right: 0;
                border-bottom: 0;
                opacity: 0;
                top: 2px;
                animation-duration: 2s;
                animation-timing-function: ease;
                animation-iteration-count: infinite;
            }
        }
    }

    &--hint-direction-left {
        .rc-slider-handle {
            &::before {
                transform: rotate(-45deg);
                left: -15px;
                animation-name: hint-left;
            }
        }

        @keyframes hint-left {
            0% {
                opacity: 0;
            }
            25% {
                opacity: 1;
            }
            50% {
                opacity: 0;
                transform: translateX(-15px) rotate(-45deg);
            }
            100% {
                opacity: 0;
            }
        }
    }

    &--hint-direction-right {
        .rc-slider-handle {
            &::before {
                transform: rotate(135deg);
                right: -15px;
                animation-name: hint-right;
            }
        }

        @keyframes hint-right {
            0% {
                opacity: 0;
            }
            25% {
                opacity: 1;
            }
            50% {
                opacity: 0;
                transform: translateX(15px) rotate(135deg);
            }
            100% {
                opacity: 0;
            }
        }
    }
}
