.rf-LinkList {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    @import 'styles/variables/defaults';

    font-size: 14px;

    .rf-ExpiredArticleWarning {
        margin-top: spacing(1);
    }

    &__linked-document-list {
        > * + * {
            margin-top: spacing(2);
        }
    }

    &__linked-document {
        display: flex;

        &:hover {
            color: $color-ruler-blue;
        }
    }

    &__checkbox-container {
        margin-right: spacing(2.5);

        & > * {
            margin-top: spacing(0, 3);
            align-items: baseline;
        }
    }

    &__date {
        color: $color-grey-65;
        font-size: 14px;
    }

    &__linked-item-container {
        > * {
            margin-left: spacing(1);
            display: block;
        }
    }

    &__suffix {
        color: $color-grey-95;
    }

    &__icon {
        position: relative;
        top: 4px;
    }

    &__link {
        line-height: 1.7;
        font-weight: 300;

        &:hover {
            text-decoration: none;
        }
    }

    &__inner {
        display: flex;
        align-items: center;
    }
}
