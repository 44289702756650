.rf-Stepper {
    @import 'styles/variables/colors.scss';
    @import 'styles/variables/animations.scss';
    @import 'styles/variables/layout.scss';
    display: flex;
    align-items: center;
    height: 38px;

    &__button {
        background: $color-grey-10;
        border: 1px solid $color-input-border;
        height: 38px;
        font-size: 18px;
        font-weight: 600;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background $animation-appear, color $animation-appear;

        &:first-child {
            border-radius: 4px 0 0 4px;
        }

        &:last-child {
            border-radius: 0 4px 4px 0;
        }

        &:hover {
            background: darken($color-grey-10, 2);
        }

        &--is-inactive {
            background: rgba($color-grey-10, 0.4);
            color: rgba(#3d3d3d, 0.4);

            &:hover {
                background: rgba($color-grey-10, 0.4);
                color: rgba(#3d3d3d, 0.4);
            }
        }
    }

    &__input {
        appearance: none;
        background: $color-white;
        border: none;
        border-bottom: 1px solid $color-input-border;
        border-top: 1px solid $color-input-border;
        color: $color-white;
        height: 38px;
        line-height: 1;
        min-width: 53px;
        min-width: 4ch;
        padding: 0 spacing(3);
        text-align: center;
        border-radius: 0;
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            appearance: none;
            margin: 0;
        }
    }

    &__input-container {
        position: relative;
    }

    &__input-display {
        position: absolute;
        width: 100%;
        height: 100%;
        line-height: 38px;
        top: 0;
        left: 0;
        text-align: center;
        pointer-events: none;
        color: $color-text;
    }
}
