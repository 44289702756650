.rf-ExpandableRow {
    @import 'styles/variables/colors';
    @import 'styles/variables/defaults';
    @import 'styles/variables/layout';
    padding: 0;

    &--is-open {
        background-color: rgba($color-grey-10, 0.3);
    }

    &__row {
        padding-bottom: spacing(2, -1px);
        display: flex;
        align-items: center;

        &:hover {
            background-color: rgba($color-grey-10, 0.3);
        }

        .rf-ExpandableRow__row-content {
            flex-grow: 1;
            display: flex;
            align-items: center;
        }
    }

    &__icon {
        margin-top: 1px;
        font-size: 16px;
        margin-right: spacing(1, 1px);
        cursor: pointer;
    }

    &__content {
        border-top: solid 1px $color-grey-20;
    }
}
