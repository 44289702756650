.rf-ThemesOverviewItemCard {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__border {
        margin-left: 0;
        width: 1px;
        background-color: $color-grey-20;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 62%;
    }

    &__sidebar {
        padding-left: spacing(3);
        padding-right: spacing(1);
        margin-top: spacing(1);
        margin-left: auto;
    }
}
