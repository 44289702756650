.rf-ExpandableNewsGroupSourcesList {
    @import 'styles/variables/layout';

    &__table-header-grid {
        white-space: nowrap;
        font-weight: 600;
        font-size: 14px;
    }

    &__table-header-right {
        align-self: flex-end;
    }
}
