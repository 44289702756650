.rf-RelevantMatrix {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__add-button {
        width: 100%;
        border-color: #bfbfc2;
        border-style: dashed;
        margin: 0 auto;

        .rf-Button__inner {
            margin: 0 auto;
        }

        .rf-Icon.rf-Button__icon {
            font-size: 12px;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $color-white;
            text-decoration: underline;
        }
    }

    &__add-icon {
        margin: 0 auto;
        display: block;
        color: $color-ruler-blue;
        margin-top: spacing(1);

        .rf-Icon.rf-Button__icon {
            font-size: 12px;
        }
    }
}
