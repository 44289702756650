.rf-TaskTemplatesTableContainer {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    &__header-container {
        padding: 0 spacing(2);
        display: grid;
        grid-template-columns: 45% 20% 20% 15%;

        .rf-TableHeaderLabel__label {
            font-weight: 600;
            margin-left: 0;
        }
    }

    &__template-row {
        border: solid 1px $color-grey-20;
        border-radius: 4px;

        &.rf-ExpandableTableListItem {
            padding: 0;

            &--is-expanded {
                background-color: $color-grey-10;
                padding-bottom: spacing(2);

                .rf-ExpandableTableListItem__inner {
                    padding-bottom: 0;

                    &::after {
                        border-bottom: 1px solid $color-grey-20;
                        content: '';
                        width: 100%;
                        position: absolute;
                        left: 0;
                        bottom: 0; // to account for the bottom padding
                    }
                }
            }
        }

        .rf-ExpandableTableListItem__inner {
            width: 100%;
            height: 56px;
            position: relative;
            padding: 0;

            & > :first-child {
                width: 100%;
            }

            & > .rf-Button {
                position: absolute;
                left: 0;
                margin-left: 0;
            }
        }

        .rf-ExpandableTableListItem__children {
            border-top: none;
            padding-right: spacing(2);
        }

        &--no-sub-task {
            &.rf-ExpandableTableListItem--is-expanded {
                padding-bottom: 0;
            }
        }
    }

    &__template-row-content {
        display: grid;
        grid-template-columns: 45% 20% 20% 15%;
        padding: 0 spacing(2);
        align-items: center;

        & > :nth-child(1) {
            margin-left: spacing(3);
            margin-right: spacing(1);
            width: calc(100% - 32px); // to account for the margin left & right
        }
    }

    &--no-tag-column {
        .rf-TaskTemplatesTableContainer__header-container {
            grid-template-columns: 65% 20% 15%;
        }
    }

    &__tag-container {
        height: 25px;
    }

    &__subtask-table {
        .rf-Table__header {
            &:nth-child(1) {
                width: 46%;
                padding-left: spacing(1);
            }

            &:nth-child(2) {
                width: 54%;
            }
        }

        .rf-Table__body .rf-Table__row {
            background-color: $color-white;
        }
    }

    &__icons {
        margin-right: spacing(2);
    }
}
