.rf-TopicAssessmentLinkedArticlesTable {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    .rf-ExpandableList__label {
        justify-content: space-between;
        padding: spacing(1.25) spacing(2);
        border-radius: 4px;
        background-color: $color-grey-10;
    }

    .rf-ExpandableList__icon {
        color: $color-grey-65;
    }

    .rf-ExpandableList__list {
        margin-top: 0;
    }

    &__list-container {
        padding: spacing(1.25) spacing(2);
        background-color: $color-grey-10;

        &:hover {
            cursor: default;
        }
    }

    &__scroll-container {
        height: 100%;
        max-height: 150px;
        overflow: auto;
        margin-right: spacing(0.5);

        &::-webkit-scrollbar-track {
            background: $color-grey-10;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-grey-65;
            border-radius: 2px;
        }
    }

    &__article {
        width: calc(100% - #{spacing(1.5)});
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
