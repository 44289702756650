.rf-MobileListItem {
    @import 'styles/variables/layout';

    padding: spacing(2);
    font-size: 14px;

    &__row {
        margin-top: spacing(1);
    }
}
