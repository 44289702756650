.rf-TopicDesignAndEffectivenessView {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    &__container {
        margin: spacing(5) 0;
    }

    &__title {
        margin-top: spacing(5);

        .rf-Button--is-action-link {
            margin-left: spacing(2);
        }
    }

    &.rf-Page {
        margin: 0;
    }

    &__risks-reports-container {
        margin-top: spacing(7);
        column-gap: spacing(5);

        .rf-Table__cell:last-child {
            padding-right: spacing(6);
        }
    }
}
