@import 'styles/variables/layout';
@import 'styles/variables/colors';

.rf-AssessmentEventCard {
    padding: spacing(1) spacing(2) spacing(1.25);
    max-width: 552px;

    > * + * {
        margin-top: spacing(1);
    }

    &__event {
        display: flex;
        align-items: center;

        > * + * {
            margin-left: spacing(1);
        }
    }

    &__icon {
        font-size: 8px;
    }

    &__metadata {
        font-size: 12px;

        > * + * {
            margin-left: spacing(0.5);
        }
    }

    &__time {
        font-weight: 500;
        color: $color-grey-80;
    }
}
