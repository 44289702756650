.rf-InboxItemImpactIcon {
    @import 'styles/variables/colors';

    color: $color-grey-65;
    font-size: 16px;

    &--high {
        color: $color-ui-red !important;
    }

    &--medium {
        color: $color-ui-orange !important;
    }

    &--low {
        color: $color-ui-green !important;
    }
}
