.rf-DepartmentsTableContainer {
    .rf-MultiDepthExpandableTable__list-item .rf-ExpandableTableListItem__inner {
        .rf-DepartmentsTableRowContainer__checkbox-container,
        .rf-DepartmentsTableRowContainer__checkbox-container--hover {
            border-radius: 8px;
            height: 38px;
        }
    }

    .rf-MultiDepthExpandableTable__table-list-item {
        &:first-child {
            .rf-DepartmentsTableRowContainer__checkbox-container,
            .rf-DepartmentsTableRowContainer__checkbox-container--hover {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }
        }

        &:last-child {
            .rf-DepartmentsTableRowContainer__checkbox-container,
            .rf-DepartmentsTableRowContainer__checkbox-container--hover {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                height: 50px;
            }
        }
    }
}
