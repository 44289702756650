.rf-Radio {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 24px;

    &__input {
        position: absolute;
        opacity: 0;
        visibility: hidden;
    }

    &__radio {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $color-white;
        border: 1px solid $color-input-border;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        transition: all 0.3s;
    }

    &__label {
        margin-left: spacing(1);
        font-size: 14px;
        color: $color-text;
    }

    &:hover &__radio {
        border-color: darken($color-input-border, 10%);
    }

    &__mark {
        background-color: $color-ruler-blue;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        opacity: 0;
        transform: scale(0.2);
        transition: opacity 0.3s, transform 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    }

    &__input:checked ~ &__radio {
        background-color: $color-white;
        border-color: $color-input-border;
        background-image: none;

        .rf-Radio__mark {
            opacity: 1;
            transform: scale(1);
            border-radius: 50%;
        }
    }

    &__input:checked:hover ~ &__radio {
        border-color: darken($color-input-border, 5%);
    }

    &--large {
        .rf-Radio__radio {
            width: 20px;
            height: 20px;

            .rf-Radio__mark {
                width: 10px;
                height: 10px;
            }
        }
    }

    &--disabled {
        cursor: default;

        .rf-Radio__input:checked ~ .rf-Radio__radio,
        .rf-Radio__radio {
            opacity: 0.3;
            background-color: $color-grey-40;
            border-color: $color-grey-65;
        }

        .rf-Radio__mark {
            background-color: $color-grey-95;
        }
    }
}
