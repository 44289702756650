.rf-ConsultationsWidget {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__tooltip {
        overflow: hidden;
    }

    &__title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
