.rf-ModalManager {
    @import 'styles/variables/layout';

    &__overlay {
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 998;

        > * {
            transform: translate3d(0, 0, 0);
        }
    }

    &__modal-container {
        width: 100%;
        min-height: 100vh;
        padding: spacing(8);
        display: flex;
        justify-content: center;
        align-items: center;

        &--for-drawer-modal {
            padding: spacing(3);
            padding-bottom: 0;
            display: block;
        }
    }
}
