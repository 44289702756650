.rf-MonitoringReportWidget {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__row {
        height: 50px;
        width: 100%;
        padding: 0 spacing(1.5);
        border-radius: 4px;
        background-color: $color-widget-light-blue;
        align-items: center;

        display: grid;
        grid-template-columns: 60% 40%;

        &--is-overdue {
            background-color: $color-widget-light-red;

            .rf-MonitoringReportWidget__date {
                align-self: start;
                padding-top: spacing(0.5);
            }
        }
    }

    &__name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__overdue-count {
        margin-top: 0;
        color: $color-ui-red;
        font-weight: 500;
    }

    &__date {
        white-space: nowrap;
        margin-left: 0;
        text-align: right;
    }
}
