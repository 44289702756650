.rf-MonitoringReportSelectTable {
    @import 'styles/variables/animations';

    &__select-monitoring-report-button {
        opacity: 0;
        transition: opacity $animation-appear;
    }

    .rf-Table__row:hover {
        .rf-MonitoringReportSelectTable__select-monitoring-report-button {
            opacity: 1;
        }
    }
}
