.rf-Button {
    @import 'styles/variables/defaults';
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    @import 'styles/variables/animations';

    // // Variables
    // $default-color: #fff;
    // $default-background-color: $color-ruler-blue;
    // $default-border-color: mix($color-ruler-blue, rgba(0, 0, 0, 0.2));

    // $secondary-color: $color-ruler-blue;
    // $secondary-background-color: rgba($color-white, 0);
    // $secondary-border-color: $color-ruler-blue;

    // $tertiary-color: $color-grey-65;
    // $tertiary-background-color: #fff;
    // $tertiary-border-color: $color-grey-65;

    // $color-ui-red: $color-ui-red;
    // $danger-background-color: #fff;
    // $danger-border-color: $color-ui-red;

    // $confirm-color: #fff;
    // $confirm-background-color: $color-ruler-blue;
    // $confirm-border-color: $color-ruler-blue;

    // $cancel-color: $color-grey-80;
    // $cancel-background-color: transparent;
    // $cancel-border-color: transparent;

    // $icon-color: $color-text-link;
    // $icon-background-color: $color-grey-40;
    // $icon-border-color: transparent;

    // $action-color: $color-text-link;
    // $action-background-color: transparent;
    // $action-border-color: $color-text-link;

    position: relative;
    display: inline-flex;
    font-size: 14px;

    font-weight: 600;
    text-align: center;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: spacing(1.5) spacing(2);
    border-radius: 4px;
    user-select: none;
    transition-property: background, border;
    transition-duration: 225ms;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    color: $color-white;
    border: 1px solid $color-ruler-blue;
    background-color: $color-ruler-blue;
    text-decoration: none;
    line-height: 1em;
    z-index: 1;

    // ELEMENTS
    &__inner {
        display: flex;
        align-items: center;

        .rf-Button--is-loading & {
            opacity: 0;
            visibility: hidden;
        }
    }

    .rf-Spinner.rf-Button__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -10px;
        margin-left: -10px;
    }

    .rf-Icon.rf-Button__icon {
        font-size: 16px;
        color: inherit;
        margin-right: 8px;
    }

    .rf-Icon.rf-Button__icon-right {
        font-size: 16px;
        color: inherit;
        margin-left: 8px;
    }

    &--is-icon-button {
        padding: spacing(1, -5);

        .rf-Icon.rf-Button__icon {
            font-size: 16px;
            margin-right: 0;
        }
    }

    // MODIFIERS
    &:focus {
        background: $color-ruler-blue-darken-10;
    }

    &:hover {
        background: $color-ruler-blue-darken-10;
    }

    &:active {
        background: $color-ruler-blue-darken-15;
    }

    // Danger styles
    &--is-danger,
    &--is-delete {
        color: $color-white;
        border-color: $color-ui-red;
        background-color: $color-ui-red;

        &:hover,
        &:focus {
            border-color: $color-ui-red-darken-10;
            background-color: $color-ui-red-darken-10;
        }

        &:active {
            border-color: $color-ui-red-darken-15;
            background-color: $color-ui-red-darken-15;
        }
    }

    // Disabled styles
    &--is-disabled,
    &--is-disabled:hover,
    &--is-disabled:active {
        color: $color-grey-65;
        border-color: $color-grey-10;
        background-color: $color-grey-10;
        cursor: not-allowed;
    }

    // ButtonType:Secondary
    &--is-secondary {
        background-color: $color-white;
        border-color: $color-ruler-blue;
        color: $color-ruler-blue;

        &:hover,
        &:focus {
            background-color: $color-ruler-pale;
        }

        &:active {
            background-color: $color-ruler-pale-dark;
        }

        &.rf-Button--is-danger {
            background-color: $color-white;
            border-color: $color-ui-red;
            color: $color-ui-red;

            &:hover,
            &:focus {
                background-color: $color-ui-red-pale;
            }

            &:active {
                background-color: $color-ui-red-pale-dark;
            }
        }

        &.rf-Button--is-disabled,
        &.rf-Button--is-disabled:hover,
        &.rf-Button--is-disabled:active {
            background-color: $color-white;
            border-color: $color-grey-65;
            color: $color-grey-65;
        }
    }

    // ButtonType:Tertiary
    &--is-tertiary {
        background-color: $color-white;
        border-color: $color-white;
        color: $color-grey-95;

        &:hover,
        &:focus {
            background-color: $color-grey-10;
        }

        &:active {
            background-color: $color-grey-20;
        }

        &.rf-Button--is-disabled {
            background-color: $color-white;
            border-color: $color-white;
            color: $color-grey-65;
        }

        &.rf-Button--is-loading {
            background-color: $color-grey-10;
            border-color: $color-grey-10;
        }
    }

    &--is-subtle,
    &--is-subtle:hover,
    &--is-subtle:focus {
        background: none;
        color: $color-grey-65;
        border: none;
        padding: 0;
        transition: color $animation-appear;
    }

    &--is-subtle:hover,
    &--is-subtle:focus {
        text-decoration: underline;
        color: $color-text;
    }

    // ButtonType:InputLike
    &--is-input-like {
        background-color: $color-white;
        border-color: $color-input-border;
        color: $color-input-text;

        &:hover,
        &:focus {
            background-color: $color-grey-10;
        }

        &:active {
            background-color: $color-grey-10;
            color: $color-ruler-blue;
        }

        &.rf-Button--is-disabled,
        &.rf-Button--is-disabled:hover,
        &.rf-Button--is-disabled:active {
            background-color: $color-white;
            border-color: $color-grey-65;
            color: $color-grey-65;
        }
    }

    &--is-action-link {
        background-color: transparent;
        border-color: transparent;
        color: $color-ruler-blue;
        padding: 0;
        user-select: auto;

        &:hover,
        &:focus {
            color: $color-ruler-blue;
            text-decoration: underline;
            background-color: transparent;
            border-color: transparent;
        }

        &:active {
            color: $color-ruler-blue;
            background-color: transparent;
            border-color: transparent;
        }

        &.rf-Button--is-disabled {
            color: $color-grey-65;
            text-decoration: none;
            background-color: transparent;
            border-color: transparent;
        }

        &.rf-Button--is-danger {
            background-color: $color-white;
            border: none;
            color: $color-ui-red;

            &:hover,
            &:focus {
                // background-color: $color-ui-red-pale;
                text-decoration: underline;
            }
        }
    }

    &--is-table-link,
    &--is-item-link {
        background-color: transparent;
        border-color: transparent;
        color: $color-grey-95;
        padding: 0;
        user-select: auto;

        &:hover,
        &:focus,
        &:active {
            color: $color-ruler-blue;
            background-color: transparent;
            border-color: transparent;
        }
    }

    &--is-table-link {
        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }

    &--is-subtle-item-link {
        background-color: transparent;
        border-color: transparent;
        color: $color-grey-95;
        padding: 0;
        user-select: auto;
        font-weight: inherit;

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
            background-color: transparent;
            border-color: transparent;
        }
    }

    &--is-rounded {
        border-radius: 20px;
    }

    &--is-large-rounded {
        border-radius: 20px;
        height: 40px;
        width: 40px;

        .rf-Button__inner {
            align-self: center;
            margin: auto;
        }
    }

    &--is-not-styled {
        background-color: none;
        background: none;
        border: none;
        padding: 0;
        transition: none;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        text-align: inherit;
        white-space: pre-line;

        &:hover,
        &:active,
        &:focus {
            background-color: none;
            border: none;
            background: none;
        }

        &.rf-Button--is-disabled {
            background-color: none;
            background: none;
            border: none;
            color: inherit;
        }

        .rf-Button__inner {
            width: 100%;
        }
    }

    & &__icon {
        &.rf-Icon--filter {
            font-size: 12px;
        }
    }

    &--is-vertical {
        .rf-Button__inner {
            flex-direction: column;
        }
        .rf-Icon.rf-Button__icon {
            margin: 0;
            margin-bottom: 4px;
        }
    }

    &--is-subtle-secondary {
        background: transparent;
        color: $color-grey-65;
        border: none;
        padding: spacing(1, -3);
        transition: color $animation-appear;

        .rf-Icon.rf-Button__icon {
            font-size: 12px;
        }
    }

    &--is-subtle-secondary:hover,
    &--is-subtle-secondary:focus {
        color: $color-ruler-blue;
        background-color: $color-ruler-pale-dark;
        transition: color $animation-appear;
    }

    &--is-subtle-icon {
        color: $color-grey-65;
        background-color: none;
        background: none;
        border: none;
        padding: spacing(1, -3);

        &:hover,
        &:focus {
            color: $color-ruler-blue;
            background-color: $color-ruler-pale-dark;
            background: none;
            transition: color $animation-appear;
            border: none;
        }
    }
}
