.rf-MonitoringAgendaView {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    max-width: unset;

    &__table-container {
        background-color: $color-background-blue;
        min-height: 100vh;
        margin-bottom: spacing(-15);
        margin-left: spacing(-5);
        margin-right: spacing(-5);
        padding: spacing(3) spacing(5);
        border-radius: 16px 0 0 0;
    }
}
