.rf-CustomerFrameworksOptionsButton {
    @import 'styles/variables/layout';

    &__new-option {
        &.rf-Button .rf-Icon.rf-Button__icon {
            margin-left: spacing(0, 2);
            font-size: 12px;
        }
    }
}
