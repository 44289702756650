.rf-TableHeaderLabel {
    &__label {
        &--is-visually-hidden {
            position: absolute;
            overflow: hidden;
            clip: rect(0 0 0 0);
            height: 1px;
            width: 1px;
            margin: -1px;
            padding: 0;
            border: 0;

            &+.rf-SortHeader__sort-direction {
                margin-left: 0;
            }
        }
    }

}
