.rf-UserProfileList {
    @import 'styles/variables/layout';

    &__container {
        display: flex;
        > * + * {
            margin-left: spacing(0.5);
        }
    }
}
