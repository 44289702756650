.rf-SearchResults {
    @import 'styles/variables/layout';

    position: relative;
    max-width: 1184px;
    margin: 0 auto;
    padding: 0 24px;

    &__loader {
        margin-top: 48px;
    }

    &__fixed-loader {
        position: absolute;
        left: 50%;
        top: -12px;
        left: 50%;
        margin-left: -12px;
    }

    &__item {
        border-top: 1px solid #f2f4f5;
        padding: 16px 0;
        display: grid;
        grid-template-columns: 6fr 1fr 1fr;
        gap: spacing(3) spacing(3);
    }

    &__header {
        flex: 1;
    }

    &__title {
        color: #1d55ff;
        font-size: 14px;
        font-weight: 600;
    }

    &__date {
        font-size: 12px;
        color: #87878a;
        font-weight: 600;
    }

    &__content {
        margin-top: 8px;
        color: #3d3d3d;
        font-size: 14px;
        font-weight: normal;
        max-width: 900px;
        em {
            font-weight: 600;
        }
    }

    &__type {
        font-size: 14px;
    }

    &__frameworks {
        margin-top: 8px;
    }

    &__loadmore {
        padding: 24px 0;
        display: flex;
        justify-content: center;
    }

    &__loadmore-button {
        margin: 0 auto;
    }
}
