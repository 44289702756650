.rf-InboxItemImpactWidget {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__count-container {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background-color: $color-grey-10;
    }

    &__count {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        padding-top: spacing(0.5);
        white-space: nowrap;
    }

    &__icon {
        width: 9px;
        height: 9px;

        &--low-impact-count {
            color: $color-grey-65;
        }
        &--medium-impact-count {
            color: $color-ui-orange;
        }
        &--high-impact-count {
            color: $color-ui-red;
        }
    }
}
