.rf-FileInput {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    position: relative;
    width: 100%;
    z-index: 10;
    overflow: hidden;

    &__file-input {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        width: 1px;
        height: 1px;
    }

    &__add-file-button {
        border-radius: 4px;
        width: 100%;
        appearance: none;
        align-items: center;
        font-size: 14px;
        display: flex;
        color: $color-ruler-blue;
        line-height: 1;
        font-weight: 600;
        text-align: left;
        padding: spacing(1);
        background-color: transparent;
        border: 1px dashed $color-grey-40;
        transition: background-color 0.2s ease-in;

        &:hover {
            background-color: $color-ruler-pale;
        }
    }

    &--is-disabled {
        color: $color-grey-65;

        .rf-FileInput__file-name {
            color: $color-grey-65;
        }

        .rf-Icon {
            color: $color-grey-65;
        }
    }

    &__add-file-button-icon {
        margin-right: 12px;
        font-size: 22px;
        color: $color-ruler-blue;
    }

    &__uploaded-file {
        border-radius: 4px;
        width: 100%;
        appearance: none;
        align-items: center;
        font-size: 14px;
        display: flex;
        color: $color-ruler-blue;
        line-height: 1;
        font-weight: 500;
        text-align: left;
        padding: spacing(1);
        background-color: $color-grey-10;
        border: 1px solid $color-grey-10;
    }

    &__file-name {
        font-weight: 600;
        z-index: 10;
        font-size: 14px;
    }

    &__file-icon {
        z-index: 10;
        margin-right: 12px;
        font-size: 22px;
        color: $color-ruler-blue;
    }

    &__remove-file {
        color: $color-text;
        margin-left: auto;
        z-index: 10;
        padding: 6px;

        .rf-Button__icon {
            font-size: 15px;
        }
    }

    &__error {
        color: $color-ui-red;
    }
}
