.rf-ThemesOverviewPinnedThemesContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    grid-row-gap: spacing(3.25);
    border-bottom: 1px solid $color-ruler-pale-dark;
    padding-bottom: spacing(3, -3);

    &.rf-CardList {
        grid-column-gap: spacing(2.25);
    }
}
