.rf-ExpandableTableListItem {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: block;
    padding: 16px 8px;

    border-top: 1px solid $color-grey-40;

    &:last-child {
        border-bottom: 1px solid $color-grey-40;
    }

    &__inner {
        width: 100%;
    }

    &__button {
        height: 100%;
        align-items: center;
        padding-left: spacing(1.5);
        padding-right: spacing(0.5);
    }

    &__children {
        border-top: 1px solid $color-grey-40;
        position: relative;
        left: -8px;
        width: calc(100% + 8px);
        padding-left: 24px;

        .rf-TableListItem {
            padding-top: 16px;
            padding-bottom: 16px;
        }
        .rf-TableListItem + .rf-TableListItem {
            border-top: 1px solid $color-grey-40;
        }

        .rf-TableListItem:last-child {
            border-bottom: 0;
        }
    }

    &--is-expanded {
        padding-bottom: 0;
    }

    &--is-expanded &__inner {
        padding-bottom: 16px;
    }
}
