.rf-AnnotatedCheckbox {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    @import 'styles/variables/defaults';

    width: 100%;

    &__label-container {
        display: flex;
        padding: 9px 8px 9px 16px;
        align-items: baseline;
        border-radius: $default-border-radius;
        border: 1px solid $color-grey-40;
        background-color: $color-background;
        flex: 1;
        align-items: center;
        cursor: pointer;
    }

    &__label {
        display: flex;
        font-size: 12px;
        font-weight: 600;
        color: $color-grey-95;
    }

    &__label-icon {
        font-size: 16px;
        margin-top: 1px;
        margin-right: spacing(1);
        display: inline-flex;
        align-items: center;
    }

    &__checkbox {
        min-height: 0;
        margin-left: auto;

        .rf-Checkbox__checkbox {
            width: 20px;
            height: 20px;
        }
    }

    &__annotation {
        margin-top: spacing(1);
    }

    &--is-disabled {
        .rf-AnnotatedCheckbox__label-container {
            background-color: $color-grey-10;
            cursor: auto;
        }

        .rf-AnnotatedCheckbox__label {
            color: $color-grey-65;
        }
    }
}
