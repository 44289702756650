.rf-ExpandableNewsGroupSourcesListHeader {
    @import 'styles/variables/layout';

    &__table-header {
        padding: spacing(3) spacing(2) 1px;
        white-space: nowrap;
        font-weight: 600;
        font-size: 14px;
    }
}
