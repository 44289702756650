.rf-ReviewInternalAlertModal {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    position: relative;
    width: spacing(103.5);

    &__note-container {
        padding-top: spacing(3);
    }

    &__note-title {
        padding-bottom: spacing(1);
    }

    &__date-source-container {
        padding-bottom: spacing(2);
    }

    &__url {
        padding-bottom: spacing(2.75);
    }

    &__spacer {
        padding-top: 16px;
    }

    &__line {
        border-top: 1px solid $color-grey-40;
        margin-left: spacing(-3);
        margin-right: spacing(-3);
    }

    &__icon-button {
        width: spacing(5);
        height: spacing(5);
        align-items: center;
        justify-content: center;
    }

    &__content {
        width:100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }
}
