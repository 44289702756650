.rf-EditRiskModalHeaderAction {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__button {
        background-color: $color-ruler-blue;
        color: $color-white;
        border: none;
        padding: spacing(1, 4);
    }
}
