.rf-TopicDesignAndEffectivenessLinkedArticlesModalContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &--has-expired {
        margin-left: spacing(-0.5);
    }

    &__expired-icon {
        font-size: 12px;
        color: $color-ui-orange;
        margin-right: spacing(0.25);
    }
}
