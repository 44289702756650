* {
    margin: 0;
    font-family: inherit;
    line-height: inherit;
    color: inherit;
    box-sizing: inherit;
    word-break: break-word;
}

*:before,
*:after {
    box-sizing: inherit;
}

/*
 * Give element block that are not block by default.
 * This makes sure the parent div is the exact size of the element.
 */
label,
img,
audio,
video,
canvas,
meter,
progress,
svg,
iframe {
    display: block;
}

button {
    font-size: inherit;
    cursor: pointer;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

ol,
ul {
    padding: 0;
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

html,
body {
    min-width: 1150px;
    min-height: 100vh;
    padding: 0;
}

body {
    @import 'styles/variables/fonts';
    @import 'styles/variables/colors';

    display: flex;
    background: $color-background;
    font-family: $font-default;
    color: $color-text;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.rf-has-overlay {
    overflow: hidden;
    height: 100%;
}
