.rf-RowFraction {
    flex: 1;
    display: flex;
    align-items: center;
    min-width: 0;

    > .rf-Row {
        flex: 1;
    }
}
