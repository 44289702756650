.rf-EnvironmentBanner {
    @import 'styles/variables/colors';

    position: fixed;
    top: 0;
    left: calc(50% + 114px);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 12px;
    padding: 5px 11px;
    color: #fff;
    background-color: fade-out($color: #1d55ff, $amount: 0.1);
    border: 1px solid #1d55ff;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    z-index: 9998;
    will-change: transform;
    transform: translate3d(-50%, 0, 0);
}
