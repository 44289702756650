.rf-Column {
    @import 'styles/variables/layout';
    position: relative;

    & > * + *,
    & > .rf-InfiniteScroll > * + * {
        margin-top: spacing(1, 4);
    }

    &--has-big-spacing {
        & > * + *,
        & > .rf-InfiniteScroll > * + * {
            margin-top: 21px;
        }
    }

    &--has-extra-big-spacing {
        & > * + *,
        & > .rf-InfiniteScroll > * + * {
            margin-top: spacing(5);
        }
    }

    &--has-small-spacing {
        & > * + *,
        & > .rf-InfiniteScroll > * + * {
            margin-top: spacing(1);
        }
    }

    &--has-extra-small-spacing {
        & > * + *,
        & > .rf-InfiniteScroll > * + * {
            margin-top: spacing(1, -4);
        }
    }

    &--has-double-spacing {
        & > * + *,
        & > .rf-InfiniteScroll > * + * {
            margin-top: spacing(2);
        }
    }

    &--has-big-bottom-spacing {
        & > * + *,
        & > .rf-InfiniteScroll > * + * {
            padding-bottom: spacing(2);
        }
    }

    &--has-no-spacing {
        & > * + *,
        & > .rf-InfiniteScroll > * + * {
            margin-top: 0;
        }
    }
}
