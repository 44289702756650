.rf-LanguageSwitchModal {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    &__option {
        height: 64px;
        padding: 19px 249px 21px 24px;
        background-color: $color-grey-10;

        &--is-default {
            background-color: $color-ruler-pale;
        }
    }

    &__options-container {
        margin-top: spacing(3);
        margin-bottom: spacing(2);
    }

    &__option-container {
        cursor: pointer;
    }

    &__language-icon {
        color: $color-grey-95;
        margin-right: spacing(1);
        margin-left: spacing(1, 2);
        transform: scale(1.2);

        & > i {
            width: 18px;
            height: 25px;
        }

        svg {
            width: 18px;
            height: 25px;
        }
    }

    &__option-text {
        white-space: nowrap;
    }

    &__deepl-logo {
        color: $color-grey-95;
        width: 60px;
        height: 25px;
        margin-left: spacing(1);
        padding-bottom: spacing(0, 2);

        svg {
            width: 60px;
            height: 25px;
        }
    }
}
