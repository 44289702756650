.rf-TaskControlTable {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    .rf-Table__table {
        // Make the table fixed so the widths don't vary accross months
        table-layout: fixed;

        .rf-Table__checkbox-content-wrapper {
            padding: 0;
            height: 100%;
        }
    }

    .rf-Table__row {
        &:hover {
            .rf-TaskControlTable__hidden-actions {
                opacity: 1;
            }

            .rf-TaskControlTable__revert-ignore {
                opacity: 1;
            }
        }
    }

    .rf-Table__header {
        padding-top: 0;

        &:nth-child(1) {
            width: 55%;
        }

        &:nth-child(2) {
            width: 15%;
        }

        &:nth-child(3) {
            width: 15%;
        }

        &:nth-child(4) {
            width: 5%;
        }
    }

    .rf-Table__body .rf-Table__row {
        background-color: $color-white;

        .rf-Table__cell {
            vertical-align: middle;
            height: 58px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &__fetch-more-wrapper {
        text-align: center;
    }

    &__fetch-more {
        font-size: 14px;
        font-weight: 500;

        .rf-Icon.rf-Button__icon {
            font-size: 14px;
            margin-top: spacing(1, -4px);
        }
    }

    &__empty-state {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.71;
        color: $color-grey-80;
    }

    &__actions {
        .rf-Button--is-icon-button {
            padding: spacing(1, -1px);
        }
    }

    &__hidden-actions {
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
        display: flex;

        .rf-Button--is-icon-button {
            padding: spacing(1, -1px);
        }

        * + * {
            margin-left: spacing(1);
        }
    }

    &__tombstones {
        & > * + * {
            margin-top: spacing(1);
        }
    }

    &__revert-ignore {
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
        margin-right: spacing(2);

        &.rf-Button--is-icon-button {
            padding: spacing(1, -1px);
        }
    }

    &__name-row {
        height: 100%;
    }

    &__linked-item-container {
        width: 44px;
        height: 100%;
        margin-right: spacing(2);
        padding-left: spacing(0.5);
        display: flex;
        justify-content: center;
        align-items: center;

        &--is-sub-task {
            .rf-IconLabel__icon {
                color: $color-grey-65;
            }
        }

        .rf-IconLabel__icon {
            margin-right: 0;
            width: 100%;
            height: 100%;
        }

        &::after {
            content: '';
            width: 2px;
            height: 56px;
            position: absolute;
            left: 40px;
            top: 0;
            background-color: $color-background-blue;
        }

        .rf-RiskLabel__risk-icon {
            margin-right: 0;
        }
    }
}
