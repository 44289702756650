.rf-MonitoringReportDetail {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    .rf-PageTitle__suffix {
        margin-top: spacing(1.5);
        align-self: baseline;
    }

    .rf-PageDetail__sidebar {
        padding-left: 0;
        padding-right: spacing(5);
    }

    &__topics-section {
        margin-top: spacing(5);
    }

    &__warning {
        color: $color-ui-orange;
        width: 20px;
        height: 20px;
    }
}

// this is rendered in a react portal, so not available as a descendant of the above
.rf-MonitoringReportDetail__warning-content {
    @import 'styles/variables/layout';

    width: 320px;
    text-align: left;
    padding: spacing(1.5) spacing(2) spacing(1);
}
