.rf-TaskControlSectionTitle {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: flex;
    align-items: center;

    &__title {
        font-size: 18px;
        margin-right: spacing(2, 1px);
        text-transform: capitalize;
    }

    &__task-count {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.71;

        &::before {
            content: '';
            display: inline-block;
            margin-right: spacing(2, -1px);
            height: 12px;
            width: 2px;
            background-color: $color-text;
        }
    }

    &__finished-tasks {
        font-weight: 500;
        color: $color-grey-80;
        margin-left: 0.1em;
    }
}
