.rf-AddAssessmentModal {
    @import 'styles/variables/layout';

    .rf-Modal__spacer {
        padding-top: spacing(1);
    }

    .rf-Radio:first-of-type {
        margin-top: spacing(1, 6);
    }

    &__radio {
        margin-top: spacing(1, 6);

        &:nth-child(2) {
            margin-left: spacing(3);
        }

        .rf-Radio__label {
            margin-left: spacing(2);
        }
    }
}
