.rf-TopicDetailViewAssessmentStatus {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__non-applicable-container {
        background-color: $color-ruler-pale;
        border-radius: 4px;
        padding: spacing(1) spacing(2);
    }

    &__non-applicable-tooltip {
        text-align: left;
        padding: spacing(1.5);
        max-width: 285px;
    }

    &__undo-status {
        .rf-Icon--undo {
            transform: rotate(75deg);
            font-size: 14px;
        }
    }

    &__assessments-container {
        border-radius: 4px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: spacing(2);
    }

    &__assessment-button {
        width: 100%;

        &:hover .rf-TopicDetailViewAssessmentStatus__assessment {
            background-color: $color-grey-10;
        }
    }

    &__assessment {
        display: flex;
        border-radius: 4px;
        width: 100%;
        border: 1px solid $color-grey-20;
        background-color: $color-white;
        padding: spacing(2);
        padding-left: spacing(3);
    }

    &__info-column {
        margin-left: spacing(2);
    }
}
