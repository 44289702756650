.rf-MainProfile {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';

    border-left: 1px solid $color-grey-10;
    display: flex;
    overflow: hidden;
    position: relative;
    min-height: 71px;
    padding: 23px 15px;

    &__profile-container {
        align-items: center;
        display: flex;
        margin-right: 8px;
        min-height: 30px;
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        margin-right: 8px;
        margin: -3px;
    }

    &__profile-icon {
        margin: 3px;
        transition: opacity 0.4s $animation-appear-bezier;
    }

    &__button {
        align-items: center;
        background: none;
        border: none;
        color: $color-grey-65;
        display: flex;
        justify-content: center;
        padding: 4px;
    }

    &-appear {
        opacity: 0;
    }

    &-appear-active {
        opacity: 1;
    }

    &-enter-done {
        opacity: 1;
    }

    &--is-compact {
        min-height: auto;
        padding: 0;
        border-left: 0;
    }
}
