.rf-UserProfilePicker {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    @import 'styles/variables/animations';

    position: relative;

    &__picker {
        position: absolute;
        width: 219px;
        right: -227px;
        top: 0;
        background: $color-white;
        border: 1px solid $color-grey-40;
        border-radius: 4px;
        padding: spacing(1);
        padding-bottom: 0;
        z-index: 1;
    }

    &__empty {
        min-height: 72px;
        display: flex;
        align-items: center;
    }

    &__list {
        padding: spacing(0.5) 0;
        transition: background-color $animation-appear;
        width: 100%;

        &:hover,
        &:focus,
        &:focus-within {
            background: $color-grey-10;
        }
    }

    &__user-profile {
        pointer-events: none;
    }

    &__avatar {
        border-radius: 50%;
        height: 24px;
        width: 24px;
    }

    &__avatar-container {
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        transition: border-color $animation-appear, color $animation-appear, background-color $animation-appear;
        border: 1px dashed $color-grey-65;
        line-height: 1;
        color: $color-grey-65;
        text-transform: uppercase;
    }

    &__user-container {
        padding: spacing(1) 0;
        overflow: auto;
        max-height: 138px;
        -webkit-overflow-scrolling: touch;
    }

    &__button {
        outline: none;
        width: 100%;
    }

    .rf-UserProfilePicker__button:focus .rf-UserProfilePicker__avatar-container,
    .rf-UserProfilePicker__button:hover .rf-UserProfilePicker__avatar-container {
        border-color: $color-ruler-blue;
        color: $color-ruler-blue;
        outline: none;
    }
}
