@import 'styles/variables/layout';
@import 'styles/variables/colors';

.rf-EventLogItem {
    display: flex;
    flex-direction: row;

    > * + * {
        margin-left: spacing(1);
    }

    &__card {
        padding: spacing(1) spacing(2) spacing(1, 2);
    }

    &__row + &__row {
        margin-top: spacing(0.5);
    }

    &__title {
        font-weight: 600;
    }

    &__description {
        margin-left: spacing(1);
    }

    &__date {
        font-size: 12px;
        font-weight: 500;
        color: $color-grey-80;
    }

    &__actor {
        font-size: 12px;
    }

    &__file-list {
        display: flex;
        flex-direction: column;
    }

    &__reports-list {
        margin: 8px 0;
    }

    &__report-card {
        max-width: 542px;
    }
}
