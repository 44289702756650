.rf-NewsUrlButton {
    &--auto-trim {
        min-width: 0;

        .rf-Button__inner {
            min-width: 0;

            .rf-Button__label {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
