.rf-PaginatedSentBackInternalAlertsContainer {
    @import 'styles/variables/layout';

    &__card {
        padding-left: spacing(3, 1px);
        padding-right: spacing(3);
        display: flex;
        align-items: center;
        box-shadow: none;
        justify-content: space-between;

        &:hover {
            box-shadow: 1px 4px 8px rgba(41, 41, 136, 0.15);
        }
    }

    &__title {
        overflow: hidden;
        padding-right: spacing(6.5);

        .rf-SignalingTitle__tooltip {
            width: 100%;
        }

        .rf-SignalingTitle__title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__action {
        display: flex;
        flex-shrink: 0;

        & > * + * {
            margin-left: spacing(2);
        }
    }

    &__archive-button {
        padding: spacing(1, 3px);
    }
}
