.rf-RiskSettingsIndicatorLabel {
    @import 'styles/variables/layout';

    .rf-Icon {
        margin-top: spacing(1);
        height: spacing(3);
        width: spacing(5);

        > svg {
            height: spacing(3);
            width: spacing(5);
        }
    }
}
