.rf-AddLinkedMonitoringReportButton {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: flex;
    border: 1px dashed $color-grey-40;
    border-radius: 4px;
    width: 100%;
    appearance: none;
    font-size: 13px;
    color: $color-ruler-blue;
    font-weight: 600;
    text-align: left;
    padding: spacing(1, 2);
    background-color: transparent;
    transition: background-color 0.2s ease-in;
    align-items: center;
    justify-content: center;

    &__icon {
        height: 12px;
        width: 12px;
        margin-right: spacing(1);
    }
}
