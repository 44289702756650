.rf-LogOutButton {
    @import 'styles/variables/layout';
    @import 'styles/variables/animations';

    font-size: 14px;
    font-weight: 500;
    padding: spacing(1) 0;

    & polygon.arrow {
        transform: translateX(0);
        transition: transform $animation-appear;
    }

    &:hover,
    &:focus {
        & polygon.arrow {
            transform: translateX(15%);
        }
    }

    .rf-Icon.rf-Button__icon {
        font-size: 18px;
    }
}
