.rf-BreadCrumbs {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    display: flex;
    align-items: center;

    &__crumb {
        color: $color-grey-80;
        font-size: 14px;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &--is-link {
            color: $color-ruler-blue;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__arrow {
        font-size: 8px;
        margin: 0 spacing(1, 1px);
        color: $color-grey-80;
    }
}
