.rf-InboxInternalAlertItem {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    border-color: $color-grey-20;
    box-shadow: none;
    padding: spacing(2) spacing(3);
    gap: spacing(2.5);

    &:hover {
        box-shadow: 1px 4px 8px rgba(41, 41, 136, 0.15);
    }

    &__title-container {
        width: 100%;
        position: relative;
    }

    &__title {
        font-size: 16px;
        font-weight: 600;
        color: $color-grey-95;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: spacing(0.5);
        max-width: 700px;
    }

    &__container {
        display: flex;
        align-items: center;
    }

    &__user-icon-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__icon-styling {
        color: $color-grey-80;
    }

    &__avatar-container {
        padding-right: spacing(1);
    }

    &__userprofile {
        padding-right: spacing(0.5);
    }
}
