.rf-Avatar {
    @import 'styles/variables/colors';

    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;
    background-color: $color-white;
    border: 1px solid $color-grey-40;

    &__subtle {
        color: $color-grey-40;
    }

    &__letter {
        display: block;
        line-height: 1;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }

    &__image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &--is-medium {
        height: 64px;
        width: 64px;
    }

    &--is-large &__letter {
        font-size: 32px;
    }

    &--is-large {
        width: 90px;
        height: 90px;
    }
}
