.rf-RiskEditSidebar {
    @import 'styles/variables/colors';

    .rf-AssessmentMatrixLayout__empty {
        min-width: auto;
        text-align: center;
        margin-top: 24px;
    }

    .rf-AcceptBlock {
        margin-top: 24px;
    }

    &__hint-to-evaluate-netto-risk {
        color: $color-ui-orange;
    }
}
