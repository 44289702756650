@import 'styles/variables/layout';
@import 'styles/variables/colors';

.rf-NavigationTab {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .rf-Button {
        height: 100%;
        width: 100%;
    }

    &__toggle-content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__label {
        font-size: 10px;
        color: $color-grey-80;
    }

    &__switch-component-container {
        display: flex;
        margin-top: -3px;
    }

    &__switch-component {
        flex-basis: auto;
        display: flex;
        height: 18px;
        overflow: hidden;
        align-items: center;
    }

    &__toggle-arrow {
        will-change: transform;
        transition: transform 0.125s ease-in-out;
        font-size: 12px;
        display: flex;
        align-self: center;
        margin-top: 4px;
        margin-left: 5px;
    }

    .rf-ActiveCustomerFrameworks {
        margin-top: -3px;
        margin-right: 4px;
    }

    &--is-open &__toggle-arrow {
        transform: rotateX(180deg);
    }
}
