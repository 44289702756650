.rf-ControlsOverviewView {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    margin-top: spacing(7, 2);
    padding-top: 0;
    max-width: calc(100vw - 72px);

    &__control-container {
        background-color: $color-ruler-pale;
        padding: spacing(5) spacing(8);
        border-radius: 16px 0 0 0;
        margin: spacing(3) spacing(-5) spacing(-15);
        min-height: 100vh;
    }
}
