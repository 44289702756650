.rf-animation-popIn {
    @import 'styles/variables/animations';

    // Animations
    &-appear {
        opacity: 0;
        transform: translateY(5%);
    }

    &-appear-active {
        transition: opacity $animation-appear, transform $animation-appear;
        transform: translateY(0);
        opacity: 1;
    }

    &-enter {
        opacity: 0;
        transform: translateY(5%);
    }

    &-enter-active {
        transition: opacity $animation-appear, transform $animation-appear;
        transform: translateY(0);
        opacity: 1;
    }

    &-enter-done {
        opacity: 1;
        transform: translateY(0);
    }

    &-exit {
        opacity: 1;
        transform: translateY(0);
    }

    &-exit-active {
        opacity: 0;
        transform: translateY(5%);
        transition: opacity $animation-appear, transform $animation-appear;
    }

    @media screen and (prefers-reduced-motion: reduce) {
        &-appear {
            opacity: 0;
            transform: scale(1);
        }

        &-enter {
            opacity: 0;
            transform: scale(1);
        }

        &-enter-done {
            opacity: 1;
            transform: scale(1);
        }

        &-exit {
            opacity: 1;
            transform: scale(1);
        }

        &-exit-active {
            opacity: 0;
            transform: scale(1);
        }
    }
}

.rf-animation-fadeIn {
    @import 'styles/variables/animations';

    // Animations
    &-appear {
        opacity: 0;
    }

    &-appear-active {
        transition: opacity $animation-appear;
        opacity: 1;
    }

    &-enter {
        opacity: 0;
    }

    &-enter-active {
        transition: opacity $animation-appear;
        opacity: 1;
    }

    &-enter-done {
        opacity: 1;
    }

    &-exit {
        opacity: 1;
    }

    &-exit-active {
        transition: opacity $animation-appear;
        opacity: 0;
    }
}

.rf-animation-menuSlideIn {
    @import 'styles/variables/animations';

    // Animations
    &-appear {
        opacity: 0;
        transform: translateX(100%);
    }

    &-appear-active {
        transition: opacity $animation-appear, transform $animation-appear;
        transform: translateX(0);
        opacity: 1;
    }

    &-enter {
        opacity: 0;
        transform: translateX(100%);
    }

    &-enter-active {
        transition: opacity $animation-appear, transform $animation-appear;
        transform: translateX(0);
        opacity: 1;
    }

    &-enter-done {
        opacity: 1;
        transform: translateX(0);
    }

    &-exit {
        opacity: 1;
        transform: translateX(0%);
    }

    &-exit-active {
        opacity: 0;
        transform: translateX(100%);
        transition: opacity $animation-appear, transform $animation-appear;
    }

    @media screen and (prefers-reduced-motion: reduce) {
        &-appear {
            opacity: 0;
            transform: scale(1);
        }

        &-enter {
            opacity: 0;
            transform: scale(1);
        }

        &-enter-done {
            opacity: 1;
            transform: scale(1);
        }

        &-exit {
            opacity: 1;
            transform: scale(1);
        }

        &-exit-active {
            opacity: 0;
            transform: scale(1);
        }
    }
}

.rf-animation-slideInFromLeft {
    @import 'styles/variables/animations';

    // Animations
    &-appear {
        opacity: 0;
        transform: translateX(-5%);
    }

    &-appear-active {
        transition: opacity $animation-appear, transform $animation-appear;
        transform: translateX(0);
        opacity: 1;
    }

    &-enter {
        opacity: 0;
        transform: translateX(-5%);
    }

    &-enter-active {
        transition: opacity $animation-appear, transform $animation-appear;
        transform: translateX(0);
        opacity: 1;
    }

    &-enter-done {
        opacity: 1;
        transform: translateX(0);
    }

    &-exit {
        opacity: 1;
    }

    &-exit-active {
        opacity: 0;
        transition: opacity $animation-appear, transform $animation-appear;
    }

    @media screen and (prefers-reduced-motion: reduce) {
        &-appear {
            opacity: 0;
            transform: scale(1);
        }

        &-enter {
            opacity: 0;
            transform: scale(1);
        }

        &-enter-done {
            opacity: 1;
            transform: scale(1);
        }

        &-exit {
            opacity: 1;
            transform: scale(1);
        }

        &-exit-active {
            opacity: 0;
            transform: scale(1);
        }
    }
}

.rf-animation-rotate-180deg {
    @import 'styles/variables/animations';
    transition: transform $animation-appear, color $animation-appear;

    &-enter {
        transform: rotateZ(0);
    }

    &-enter-active {
        transform: rotateZ(-180deg);
    }

    &-enter-done {
        transform: rotateZ(-180deg);
    }

    &-exit-active {
        transform: rotateZ(0);
    }

    &-exit-done {
        transform: rotateZ(0);
    }
}

.rf-modal-slide-in {
    &-enter {
        transform: translateX(100%);
        opacity: 0;
    }
    &-enter-active {
        transform: translateX(0%);
        transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
        opacity: 1;
    }
    &-exit {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        opacity: 1;
    }
    &-exit-active {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0;
    }
}

.rf-modal-slide-out {
    &-enter {
        transform: translateX(-100%);
        opacity: 0;
    }
    &-enter-active {
        transform: translateX(0%);
        transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
        opacity: 1;
    }
    &-exit {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        opacity: 1;
    }
    &-exit-active {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0;
    }
}

.rf-selection-box-slide-in {
    @import 'styles/variables/animations';
    // Animations
    &-appear {
        .rf-SelectionBox__container {
            opacity: 0;
            transform: translateY(100%);
        }
    }

    &-appear-active {
        .rf-SelectionBox__container {
            transition: opacity $animation-appear, transform $animation-appear;
            transform: translateY(0);
            opacity: 1;
        }
    }

    &-enter {
        .rf-SelectionBox__container {
            opacity: 0;
            transform: translateY(100%);
        }
    }

    &-enter-active {
        .rf-SelectionBox__container {
            transition: opacity $animation-appear, transform $animation-appear;
            transform: translateY(0);
            opacity: 1;
        }
    }

    &-enter-done {
        .rf-SelectionBox__container {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &-exit {
        .rf-SelectionBox__container {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &-exit-active {
        .rf-SelectionBox__container {
            opacity: 0;
            transform: translateY(100%);
            transition: opacity $animation-appear, transform $animation-appear;
        }
    }
}
