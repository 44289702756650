.rf-CreateTaskTemplateModalContainer {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    &__subtask-name-container {
        padding-bottom: spacing(2);
        border-bottom: 2px solid $color-grey-20;
    }

    &__subtasks-container {
        height: 170px;
        padding: spacing(2.5) spacing(3);
        background-color: $color-grey-10;
        border-radius: 4px;
    }

    &__subtasks-message {
        margin-top: spacing(2.5);
    }

    &__consultant-fields-container {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }
}
