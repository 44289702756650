.rf-CustomerFrameworksBlockList {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    background-color: $color-grey-10;
    border-radius: 4px;
    padding: spacing(0.5);

    &__framework {
        padding: spacing(1);
        padding-left: spacing(2);
        max-width: 170px;
        position: relative;
        border-radius: 4px;

        &:hover {
            background-color: $color-grey-20;
        }
    }

    &__name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: default;
    }
}
