@import 'styles/variables/colors';

.rf-TaskMetaData {
    &__title {
        font-size: 14px;
        font-weight: 600;
        color: $color-grey-95;
    }

    &__content {
        margin-top: 2px;
    }
}
