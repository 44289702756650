.rf-GroupedTasksTable {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    & > .rf-Table__table {
        // Make the table fixed so the widths don't vary accross months
        table-layout: fixed;

        & > .rf-Table__head .rf-Table__header {
            &:nth-child(2) {
                width: 60%;
            }

            &:nth-child(3) {
                width: 10%;
            }

            &:nth-child(4) {
                width: 15%;
            }

            &:nth-child(5) {
                width: 15%;
            }

            @media screen and (max-width: 1200px) {
                &:nth-child(2) {
                    width: 40%;
                }

                &:nth-child(3) {
                    width: 20%;
                }

                &:nth-child(4) {
                    width: 25%;
                }
            }

            @media screen and (max-width: 1000px) {
                &:nth-child(2) {
                    width: 30%;
                }

                &:nth-child(3) {
                    width: 25%;
                }

                &:nth-child(4) {
                    width: 30%;
                }
            }
        }

        .rf-SortHeader .rf-SortHeader__icon {
            color: $color-grey-65;
        }

        .rf-SortHeader--sorted .rf-SortHeader__icon {
            color: $color-grey-95;
        }
    }

    .rf-Table__body {
        .rf-Table__row {
            background-color: $color-white;

            &.rf-Table__row--has-children .rf-Table__cell {
                position: relative;

                &:nth-child(2) {
                    width: 60%;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 16px;
                        top: 0;
                        height: 100%;
                        width: 2px;
                        background-color: $color-background-blue;
                    }

                    & > * {
                        margin-left: spacing(3.5);
                        width: 90%;
                    }
                }

                &:nth-child(3) {
                    width: 10%;
                }

                &:nth-child(4) {
                    width: 15%;
                }

                &:nth-child(5) {
                    width: 15%;
                }

                @media screen and (max-width: 1200px) {
                    &:nth-child(2) {
                        width: 40%;
                    }

                    &:nth-child(3) {
                        width: 20%;
                    }

                    &:nth-child(4) {
                        width: 25%;
                    }
                }

                @media screen and (max-width: 1000px) {
                    &:nth-child(2) {
                        width: 30%;
                    }

                    &:nth-child(3) {
                        width: 25%;
                    }

                    &:nth-child(4) {
                        width: 30%;
                    }
                }
            }
        }

        .rf-Table__expanded-row > td {
            background-color: $color-white;
            padding: spacing(1) spacing(3);
        }

        .rf-Table__expand-button {
            position: absolute;
            left: 8px;
            top: 12px;
            z-index: 2;
            padding: spacing(1);
        }
    }
}
