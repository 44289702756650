.rf-Spinner {
    color: inherit;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    &__container {
        position: relative;
        width: calc(100% / 3);
        height: calc(100% / 3);
        max-width: 50px;
        max-height: 50px;
        min-height: 20px;
        min-width: 20px;
    }

    &__circle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 24px;
        max-width: 24px;
        animation: rotate 2s linear infinite;
        will-change: transform;
        transform-origin: center center;
    }

    &__path {
        stroke-dasharray: 150, 200;
        stroke-dashoffset: -10;
        will-change: transform;
        animation: dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
    }

    &--is-small {
        .rf-Spinner__container {
            width: 14px;
            height: 14px;
            min-width: auto;
            min-height: auto;
        }
    }
    &--is-large {
        .rf-Spinner__container {
            width: 54px;
            height: 54px;
            min-width: auto;
            min-height: auto;
        }
    }

    &--is-slow {
        .rf-Spinner__circle {
            animation-duration: 16s;
        }
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}
