.rf-AssessmentsEditView {
    @import 'styles/variables/layout';

    .rf-FieldSet .rf-Row:last-child {
        margin-top: 0;
    }

    .rf-Modal__spacer {
        padding-top: spacing(1);
    }

    &__delete-button {
        margin-top: spacing(6, 3);
    }
}
