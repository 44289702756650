.rf-TaskControlOverviewView {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    max-width: unset;
    width: 100%;

    &__header {
        max-width: calc(1128px + #{spacing(8)});
        margin: 0 auto;
    }

    &__table-container {
        background-color: $color-background-blue;
        min-height: 100vh;
        margin-bottom: spacing(-15);
        margin-left: spacing(-5);
        margin-right: spacing(-5);
        padding: spacing(3) spacing(5);
        border-radius: 16px 0 0 0;
    }

    &__table {
        max-width: calc(1128px + #{spacing(8)});
        margin: 0 auto;
    }
}
