.rf-CustomerGroupCustomersTableContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    border-radius: 4px;
    background-color: $color-grey-10;
    padding: spacing(3) spacing(5) spacing(5);

    &__table {
        .rf-Table__table {
            border-spacing: 0;
        }

        .rf-Table__head {
            .rf-Table__header {
                border-bottom: 1px solid $color-grey-40;
                padding-bottom: spacing(0.5);

                &:first-child {
                    width: 60%;
                    padding-left: 0;
                }

                &:nth-child(2) {
                    width: 20%;
                }
            }
        }

        .rf-Table__body {
            .rf-Table__cell {
                border: none;
                border-radius: 0;
                border-bottom: 1px solid $color-grey-40;

                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }

    &__framework-tooltip,
    &__name-tooltip {
        width: calc(100% - #{spacing(2)});
    }

    &__name-button {
        width: 100%;

        .rf-Button__inner {
            width: 100%;

            .rf-Button__label {
                width: 100%;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    &__frameworks {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
