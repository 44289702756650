.rf-MonitoringReportsCreateFormModal {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    width: 100%;
    position: relative;

    > .rf-Modal {
        width: 100%;

        > .rf-Modal__content {
            padding-left: 0;
            padding-right: 0;
        }

        .rf-Modal__action-container {
            padding-right: spacing(3);
        }

        .rf-Modal__line {
            margin-left: 0;
            margin-right: 0;
        }

        .rf-Modal__spacer,
        .rf-Modal__line {
            margin-top: 0;
            padding-top: 0;
        }

        .rf-MonitoringReportTopicSelectionHeader {
            padding-left: spacing(3);
            padding-right: spacing(3);

            &__right-button-container {
                right: spacing(3);
            }
        }

        .rf-MonitoringReportTopicSelectionTable {
            margin-top: 8px;
            max-height: 40vh;
            overflow-y: scroll;
            overflow-x: hidden;
            margin-right: spacing(1.25);

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background: $color-grey-65;
                border-radius: 2px;
            }

            &__table {
                padding-left: spacing(3);
                padding-right: spacing(1.25);
            }
        }
    }

    &__fields-container {
        padding: 0 spacing(3) spacing(3);
    }

    &__file-field,
    &__date-field {
        width: 100%;
    }

    &__topic-field {
        padding-left: spacing(3);
    }
}
