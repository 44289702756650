.rf-InboxItemActions {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';


    .rf-EditInboxItemImpactActionButton__icon:focus .rf-Icon {
        color: $color-grey-65;
    }

    .rf-EditInboxItemImpactActionButton__icon:hover {
        .rf-Icon {
            color: $color-grey-80;
        }
        background: $color-grey-10;
    }
}
