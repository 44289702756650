.rf-TopicDesignAndEffectivenessUnassessedArticlesTable {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    border-radius: 4px;
    border: 1px solid $color-grey-10;
    background-color: $color-grey-10;
    padding: spacing(1.25) spacing(2);

    &__expired-icon {
        font-size: 12px;
        color: $color-ui-orange;
    }

    &__warning {
        max-width: 230px;
    }

    .rf-ExpandableList {
        &__label {
            justify-content: space-between;
        }

        &__icon {
            color: $color-grey-65;
        }

        &__list {
            padding-bottom: spacing(0.75);
        }
    }
}
