.rf-FilterDropdown {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    position: relative;
    white-space: nowrap;

    &__dropdown-wrapper {
        position: absolute;
        // width: 230px;
        border: 1px solid $color-grey-40;
        padding: spacing(3);
        border-radius: 4px;
        background: #fff;
        -webkit-overflow-scrolling: touch;
        max-height: calc(#{spacing(2)} + (6.5 * 40px));
        right: 0;
        top: 100%;
        margin-top: 9px;
        z-index: 9999;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);

        // IE10+ browsers have a bug with top 100%, this ensures it will work on those browsers.
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            top: 66px;
        }
    }

    .rf-FilterOption {
        + .rf-FilterOption {
            margin-top: spacing(1);

            &--is-sub {
                margin-top: 0;

                & ~ .rf-FilterOption--is-sub {
                    padding-top: 0;
                }
            }
        }
    }

    &--center {
        .rf-FilterDropdown__dropdown-wrapper {
            right: auto;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    &--auto-height {
        .rf-FilterDropdown__dropdown-wrapper {
            max-height: none;
        }
    }
}
