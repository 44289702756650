.rf-CustomerFeedFrequencySelect {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__frequency-label,
    &__startat-label {
        .rf-Field__label-input {
            min-width: unset;
            color: $color-grey-65;
            height: 40px;
            white-space: nowrap;
            margin-top: 0;
            margin-right: spacing(1);
            width: auto;
        }
    }

    &__startat-label {
        margin-left: spacing(2);
    }

    &__frequency-input {
        width: 65px;
    }

    &__frequency-type-input {
        width: 140px;
    }
}
