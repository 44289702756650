.rf-ControlLabel {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';
    @import 'styles/variables/layout';

    &__child-icon {
        color: $color-grey-95;
        margin-right: spacing(1.5);
    }

    &__icon-label {
        display: flex;
        flex-wrap: nowrap;
        color: $color-text;

        .rf-IconLabel__icon {
            color: $color-text;
            font-size: 16px;
            margin-right: spacing(1);
        }

        &.rf-IconLabel--is-link {
            text-decoration: none;

            &:hover {
                color: $color-ruler-blue;
                transition: $animation-default;

                svg * {
                    fill: $color-ruler-blue;
                }
            }
        }
    }

    &__content {
        display: flex;
        align-items: center;
    }

    &__attention-icon {
        color: red;
        margin-right: spacing(0.5);
        font-size: 12px;
    }

    &__shared-icon {
        margin-right: spacing(0.5);
    }
}
