.rf-RadarItemCard {
    @import 'styles/variables/backgrounds';
    @import 'styles/variables/colors';

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--is-unfollowed {
        @include stripedBG($color-grey-20, $color-background);
        background-size: 7px 7px;
        border: 0;
        box-shadow: none;
    }
}
