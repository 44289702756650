.rf-ReleaseUpdateModal {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    background: white;
    border-radius: 4px;
    padding: 40px 64px 24px 64px;
    width: 944px;
    overflow: hidden;

    &__buttons {
        margin-right: -40px;
    }

    &__label {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.71;
        color: #87878a;
        letter-spacing: 1.6px;
        margin-bottom: spacing(2);
    }

    &__title {
        color: $color-ruler-blue;
        font-size: 56px;
        font-weight: bold;
        line-height: 1;
    }

    &__container {
        margin-top: spacing(4);
        margin-bottom: spacing(4);
        align-items: flex-start;
    }

    &__content {
        color: #3d3d3d;
        line-height: 1.63;
        font-size: 16px;
        flex: 1;
        max-width: 50%;
    }

    &__img {
        flex: 1;
        max-width: 50%;
        min-width: 260px;
        margin-top: -24px;
    }

    &__list {
        display: flex;
        transition: transform 285ms ease-in-out;
    }

    &__block {
        width: 100%;
        flex: 1 0 100%;
        margin-left: 128px;

        &:first-child {
            margin-left: 0;
        }
    }

    &__next {
        color: white;
    }
}
