.rf-Step {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    display: flex;
    flex-direction: column;

    &__header {
        margin-bottom: spacing(8, -4);
        position: relative;
    }

    &__title {
        margin: 0;
        flex: 1;
        font-size: 30px;
        color: $color-text-header;
        font-weight: 700;
        position: relative;
        padding-bottom: spacing(1.5);
        white-space: normal;
        hyphens: auto;

        &:after {
            width: 24px;
            height: 4px;
            display: block;
            background: currentColor;
            bottom: 0;
            left: 0;
            position: absolute;
            content: '';
        }
    }

    &__action-component {
        flex: 1;
        width: 100%;

        & > .rf-Row {
            justify-content: flex-end;

            & > .rf-Select__container {
                max-width: 160px;
            }

            & > .rf-Search {
                max-width: 224px;
            }
        }
    }

    &__action-bar.rf-ActionBar {
        width: 100%;
        left: 50%;
        padding-left: 24px !important;
        max-width: calc(100vw - 32px);
        transform: translateX(-50%);

        @media screen and (min-width: 1500px) {
            width: 1392px;
            margin: 0 auto;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__content-wrapper {
        display: flex;
    }

    &__main-content {
        width: 100%;
        flex: 1;
    }

    &__sidebar {
        flex: 1 1;
        padding-left: 48px;
        width: 100%;
        max-width: 360px;
    }

    &__sidebar-title {
        font-size: 18px;
        margin-bottom: spacing(2);
    }

    &__seperator {
        align-self: stretch;
        width: 1px;
        background-color: $color-grey-20;
    }

    &--has-sidebar &__main-content {
        padding-right: spacing(6);
    }
}
