.rf-MonitoringReportTopicsTable {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    .rf-Table__table {
        table-layout: fixed;
    }

    .rf-Table__header {
        &:first-child {
            padding-left: 0;
            width: 4%;
        }

        &:nth-child(2),
        &:nth-child(3) {
            width: 27%;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
            width: 14%;
        }
    }

    &--no-children {
        .rf-Table__header {
            &:first-child {
                padding-left: spacing(2.5);
                width: 55%;
            }
            &:nth-child(2),
            &:nth-child(6) {
                width: 15%;
            }

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                width: 10%;
            }
        }
    }

    .rf-Table__cell:first-child {
        padding-left: spacing(2.5);
    }

    .rf-Table__expanded-row {
        background-color: $color-ruler-pale;
        > td {
            padding: spacing(2);
            padding-top: 0;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border: none;
        }

        .rf-Table__header {
            &:first-child {
                padding-left: spacing(4.5);
                width: 59.25%;
            }

            &:nth-child(2) {
                width: 14.5%;
            }

            &:nth-child(3) {
                width: 28%;
            }
        }

        .rf-Table__cell {
            background-color: $color-white;
        }
    }

    &__label-container {
        display: grid;
    }

    &__eye-icon {
        color: $color-grey-65;
    }
}
