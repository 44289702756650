.rf-ItemCustomerInfoSummary {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    padding: spacing(1.5);
    position: relative;
    max-width: 500px;

    hr {
        border-top: 1px solid $color-grey-20;
        border-bottom: none;
        margin-bottom: spacing(2.5);
    }

    &__label {
        text-align: left;
    }

    &__departments {
        display: grid;
        grid-template-columns: 80px 1fr;

        .rf-ItemCustomerInfoSummary__label {
            padding-top: spacing(.75);
        }

        .rf-ItemCustomerInfoSummary__department:nth-child(n+1) {
            margin-right: spacing(1.5);
        }
    }

    &__department {
        background: $color-ruler-pale;
        color: $color-ruler-blue;
        font-weight: 400;
    }

    &__frameworks {
        display: grid;
        grid-template-columns: 80px 1fr;
    }
}
