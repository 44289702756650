.rf-UserProfileIcon {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    @import 'styles/variables/animations';

    position: relative;

    &__icon {
        touch-action: none;
        pointer-events: none;
    }

    &__column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__card {
        position: absolute;
        background: $color-white;
        right: -229px;
        top: 50%;
        width: 229px;
        z-index: 2;
        border: 1px solid $color-grey-40;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        padding: 8px;

        > * + * {
            margin-top: 20px;
        }
    }

    &__avatar-container {
        border-radius: 50%;
        height: 32px;
        width: 32px;
        background: $color-ruler-pale;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        transition: border-color $animation-appear, color $animation-appear;
        border: 1px solid $color-ruler-pale-dark;
        line-height: 1;
        color: $color-grey-95;
        text-transform: uppercase;
    }

    &--is-interactive {
        cursor: pointer;

        &__button {
            outline: none;
        }

        .rf-UserProfileIcon__button:focus .rf-UserProfileIcon__avatar-container,
        .rf-UserProfileIcon__button:hover .rf-UserProfileIcon__avatar-container,
        .rf-UserProfileIcon__button:focus .rf-UserProfileIcon__avatar,
        .rf-UserProfileIcon__button:hover .rf-UserProfileIcon__avatar {
            border: 1px solid $color-ruler-blue;
            outline: none;
        }

        &__test {
            &.rf-Tooltip {
                .react-tooltip-lite {
                    background: $color-white;
                    padding: 8px !important;
                }
            }
        }
    }

    &--is-small {
        .rf-UserProfileIcon__avatar-container {
            height: 24px;
            width: 24px;
        }
    }

    &--is-large {
        .rf-UserProfileIcon__avatar-container {
            height: 40px;
            width: 40px;
        }
    }
}
