.rf-NewsSourcesGrid {
    @import 'styles/variables/layout';

    // IE11 fallback (css-grid)
    display: flex;
    flex-wrap: wrap;

    > * {
        margin: spacing(1);
        width: calc(33% - #{spacing(2)});
        flex: none;
    }

    @media screen and (min-width: 2048px) {
        > * {
            width: calc(25% - #{spacing(2)});
        }
    }
    // end of - IE11 fallback (css-grid)

    @supports (display: grid) {
        // Reset IE11 fallback (css-grid)
        flex-wrap: unset;
        // margin: unset;

        > * {
            margin: unset;
            width: unset;
        }
        // end of - Reset IE11 fallback (css-grid)

        display: grid;
        grid-gap: spacing(2);
    }
}
