.rf-DashboardDetailViewContainer {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    min-height: 100vh;
    background-color: $color-widget-light-blue;
    margin: spacing(1.5) spacing(-5) spacing(-15);
    padding: spacing(3.5) spacing(4) spacing(3.5) spacing(3);

    &__header {
        padding-left: spacing(1);
    }

    &__grid {
        margin-top: spacing(3.5);

        max-width: 1140px;
        margin-left: 0;
        margin-right: auto;

        @media screen and (max-width: 1450px) {
            max-width: 770px;
        }
    }

    &__title {
        font-size: 36px;
        font-weight: bold;
        line-height: 1;
    }

    &__widget-icon {
        font-size: 36px;
        padding-top: spacing(0.5);
    }
}
