.rf-AiSummaryContainer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    padding: spacing(3) spacing(4.5) spacing(3.5) spacing(4);
    border-radius: 16px;
    border: 1px solid $color-grey-20;

    &__summary-container {
        position: relative;
    }

    &__summary--collapsed {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }

    &__side-highlight {
        width: 6px;
        height: 100%;
        position: absolute;
        left: spacing(-4); // to bypass the AiSummaryContainer padding
        background-color: $color-ruler-blue;
        border-radius: 0 4px 4px 0;
    }

    &__collapse-icon {
        margin-top: spacing(0.25);
        color: $color-ruler-blue;
    }
}
