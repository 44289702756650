.rf-SignalingDetailView {
    @import 'styles/variables/layout';

    .rf-PageDetailContent {
        margin-left: 0;
    }

    &__sidebar-column {
        display: block;
    }

    &__user-icon {
        margin-right: spacing(1);
    }

    &__past-action {
        display: flex;
        font-size: 14px;
        align-items: center;
        font-weight: 500;
    }

    &__past-action-date {
        margin-left: spacing(4);
        margin-top: spacing(1);
        margin-bottom: spacing(2, -2);
    }

    &__archived-reason {
        margin-left: spacing(4);
        margin-top: spacing(1);
    }

    &__change-topic-link-button {
        .rf-Button__inner .rf-Icon.rf-Icon--add.rf-Button__icon {
            font-size: 12px;
        }
    }

    &__label {
        white-space: nowrap;
        font-size: 16px;
        padding-top: spacing(1);
    }

    &__multi-line-truncate {
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
    }
}
