.rf-ContextTasksTable {
    @import 'styles/variables/_colors.scss';

    .rf-Table__body {
        .rf-Table__cell {
            &:nth-child(1) {
                width: 50%;
            }
            &:nth-child(2) {
                width: 13%;
            }
            &:nth-child(3) {
                width: 15%;
            }
            &:nth-child(4) {
                width: 7%;
            }
        }
    }

    &--has-department {
        .rf-Table__body {
            .rf-Table__cell {
                &:nth-child(1) {
                    width: 50%;
                }
                &:nth-child(2) {
                    width: 13%;
                }
                &:nth-child(3) {
                    width: 15%;
                }
                &:nth-child(4) {
                    width: 15%;
                }
                &:nth-child(5) {
                    width: 7%;
                }
            }
        }
    }

    &__department {
        &--subtle {
            color: $color-grey-80;
        }
    }
}
