.rf-DepartmentTableRowWarningText {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    margin-left: spacing(-1);
    margin-right: spacing(2, 3);
    margin-bottom: spacing(1, -2);

    &__warning-text {
        width: 100%;
        display: flex;
        height: 36px;
        align-items: center;
        padding-left: spacing(2);
        border-radius: 4px;
        background-color: $color-ui-orange-pale;
        color: $color-ui-orange;
        font-weight: 500;
    }

    &--is-new {
        .rf-DepartmentTableRowWarningText__warning-text {
            width: unset;
            padding: spacing(2, 4);
            color: $color-text;
            font-weight: normal;
        }
    }

    &__ignore-button {
        font-weight: 500;
        color: $color-ui-orange;
        margin-left: spacing(1, 2);
    }
}
