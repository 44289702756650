.rf-AssessmentScoreBar {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__label {
        padding-bottom: spacing(0.5);
    }

    &__assessment-score-bar {
        background-color: $color-white;
        height: 6px;
        border-radius: 8px;
        display: flex;
        border: 1px solid $color-grey-40;
        width: 96px;
        gap: spacing(0, 1px);
    }

    &__assessment-score-bar-item {
        &--is-assessment-satisfies {
            background-color: $color-ui-green;
        }

        &--is-assessment-not-satisfies {
            background-color: $color-ui-red;
        }

        &--is-assessment-almost-satisfies {
            background-color: $color-ui-orange;
        }

        &--is-assessment-not-given {
            background-color: $color-white;
        }
        &--is-not-applicable {
            background: repeating-linear-gradient(
                135deg,
                $color-grey-20 8px,
                $color-grey-20 10px,
                $color-grey-70 10px,
                $color-grey-70 12px
            );
        }
    }
}
