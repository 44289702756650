.rf-MonitoringReportsContainer {
    @import 'styles/variables/layout';

    &__year {
        &:first-of-type {
            margin-top: 0;
        }

        margin-top: spacing(4);
    }
}
