.rf-PageDetail {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    display: flex;
    flex-direction: row;
    padding-top: spacing(10);
    height: 100%;
    width: 100%;
    justify-content: center;

    & > * + * {
        margin-top: spacing(3);
    }

    &__right-content {
        display: flex;
        flex-direction: column;
        width: 352px;
        margin-top: 0;

        .rf-PageDetail__sidebar {
            padding-left: spacing(5);
        }

        .rf-PageDetail__action-component {
            justify-content: flex-end;
        }
    }

    &__left-content {
        display: flex;
        flex-direction: column;
        min-width: 392px;

        .rf-PageDetail__sidebar {
            padding-right: spacing(5);
        }

        .rf-PageDetail__action-component {
            justify-content: flex-start;
        }
    }

    &__middle-content {
        width: 100%;
        min-width: 580px;
        max-width: 975px;
        padding-right: spacing(5);
        padding-left: 0;

        @media (min-width: 1440px) {
            // due backwards compatabillity with old screens, the remaining spacing(5) is added in AppLayout

            padding-right: spacing(10);
            justify-content: flex-start;
        }
    }

    &__breadcrumbs {
        margin-bottom: spacing(3, -3px);
    }

    &--has-no-bread-crumbs {
        padding-top: spacing(12, -1px);
    }

    &__title {
        margin-bottom: 60px - 8px; // visually 60px, with text correction
        width: 100%;
    }

    &--title-is-not-full-width .rf-PageTitle,
    &--title-is-not-full-width .rf-ArticleTitle {
        max-width: 600px;
    }

    &--small-bottom-spacing {
        .rf-PageDetail__title {
            margin-bottom: spacing(4);
        }
    }

    &--space-between {
        justify-content: space-between;
    }

    &__content {
        flex: 3;
        width: 100%;

        .rf-PageDetailMeta + {
            .rf-PageDetailContent {
                margin-top: 32px;
            }
        }

        .rf-HierarchyList {
            // todo refactor to proper page layouting
            margin-bottom: 41px;
        }
    }

    &__action-component {
        padding-top: 42.5px;
        padding-bottom: 64px;

        padding-left: 40px;
        display: flex;

        & > .rf-Row {
            justify-content: flex-end;

            & > .rf-Select__container {
                max-width: 160px;
            }

            & > .rf-Search {
                max-width: 224px;
            }

            & > .rf-OptionsDropdown {
                margin-left: spacing(1.5);
            }
        }
    }

    &__sidebar {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        height: 100%;
        min-width: 392px;
    }

    &__fixed-content {
        position: fixed;
    }
}
