.rf-PasswordInput {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    position: relative;

    &__strength {
        display: block;
        font-size: 12px;
        line-height: 1;
        margin-left: spacing(2);
    }

    &__strength-value {
        font-weight: 500;
    }

    &__warning {
        display: block;
        margin: 10px 0;
        font-size: 13px;
    }

    &__password-check {
        height: 12px;
        margin: 12px 0;
        padding: 0 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__strength-bar {
        display: block;
        height: 6px;
        width: 100%;
        max-width: 260px;
        background-color: $color-grey-10;
        border-radius: 8px;
        z-index: 10;
        pointer-events: none;
        overflow: hidden;
    }

    &__strength-bar-inner {
        transform: translateX(-100%);
        width: 100%;
        height: 6px;
    }

    &__strength-value {
        color: $color-grey-95;
    }

    &--strength-very-weak {
        .rf-PasswordInput__strength-bar-inner {
            background-color: $color-ui-red;
        }
    }

    &--strength-weak {
        .rf-PasswordInput__strength-bar-inner {
            background-color: $color-ui-orange;
        }
    }

    &--strength-medium {
        .rf-PasswordInput__strength-bar-inner {
            background-color: $color-ui-orange;
        }
    }

    &--strength-strong {
        .rf-PasswordInput__strength-bar-inner {
            background-color: $color-ui-green;
        }
    }

    &--strength-very-strong {
        .rf-PasswordInput__strength-bar-inner {
            background-color: $color-ruler-blue;
        }
    }
}
