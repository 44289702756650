.rf-ReviewTasksLabel {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    // overflow ellipsis wont work without this chain
    &__tooltip,
    &__task-name,
    .rf-Button__inner,
    &__task-icon {
        display: flex;
        flex-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__task-name {
        padding-top: spacing(0, 4);
    }

    &__task-icon .rf-IconLabel__label {
        padding: spacing(0, 2), 0;
        font-weight: 500;
    }

    .rf-Table__table {
        table-layout: fixed;
        max-width: 700px;
        padding: spacing(1) spacing(3, -4);

        .rf-Table__header {
            width: 50%;
            padding-bottom: spacing(1);

            &:first-child {
                padding-left: unset;
            }
        }

        .rf-Table__cell {
            padding: 4px;
            border: none;
            font-weight: 400;

            .rf-IconLabel__label {
                font-weight: 400;
            }

            .rf-ReviewTasksLabel {
                font-weight: 400;
            }
        }
    }
}
