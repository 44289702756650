.rf-ControlsTopicSelectionTableContainer {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    &__filters {
        padding: spacing(3) 0;
    }

    &__header {
        padding:0 0 spacing(1) spacing(8);
    }
}


