.rf-DatePicker {
    @import 'styles/variables/colors';
    @import 'styles/variables/fonts';

    position: relative;

    &__repeatable {
        padding-right: 12px;
        .rf-Checkbox__label {
            margin-left: 6px;
            color: $color-grey-80;
        }
    }

    &__calendar-container {
        position: absolute;
        transform: translateY(12px);
        left: 14px;
        z-index: 9999;
        border: 1px solid #d5d5d5 !important;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.12);
    }

    &__clear-button {
        margin-left: 8px;
        margin-bottom: 8px;
    }

    &__calendar {
        font-size: 14px;
        font-family: $font-default !important;
        border: 0 !important;
        border-radius: 4px;

        .react-calendar__tile--active:not([disabled]) {
            background-color: $color-ruler-blue;
            color: #fff !important;
        }

        button.react-calendar__tile--active:enabled:hover,
        button.react-calendar__tile--active:enabled:focus {
            background-color: darken($color-ruler-blue, 10%);
        }

        .react-calendar__tile--now:not([disabled]) {
            border: 1px solid $color-text;
        }

        .react-calendar__tile--hasActive:not([disabled]) {
            background-color: $color-ruler-blue;
            color: #fff;
        }

        .react-calendar__month-view {
            padding: 0 8px 8px 8px;
        }

        .react-calendar__month-view__days__day--weekend {
            color: $color-ui-red;
        }

        .react-calendar__month-view__weekdays {
            font-weight: 500;
        }

        button.react-calendar__tile:disabled {
            color: #a9a9a9;
        }

        &:after,
        &:before {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: ' ';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: #ffffff;
            border-width: 6px;
            margin-left: -6px;
        }

        &:before {
            border-color: rgba(213, 213, 213, 0);
            border-bottom-color: #d5d5d5;
            border-width: 7px;
            margin-left: -7px;
        }
    }

    &__controls {
        padding: 0 15px 10px 15px;
    }

    &--has-today-value .react-calendar__tile--now {
        background-color: $color-ruler-blue;
        color: #fff;
    }

    &--is-disabled {
        .rf-Input__input {
            color: $color-grey-65;
            background-color: $color-grey-10;
        }
    }
}
