.rf-OptionsDropdownOption {
    @import 'styles/variables/colors.scss';

    display: block;
    padding: 10px;
    line-height: 1;
    font-size: 14px;
    width: 100%;
    border-radius: 0;
    white-space: nowrap;
    color: $color-grey-95;

    .rf-Icon {
        font-size: 12px;
        color: inherit;
    }

    &:hover {
        background-color: $color-ruler-pale;
    }

    .rf-Button {
        width: 100%;
        border-radius: 0;
        white-space: nowrap;
    }

    &--style-primary {
        color: $color-ruler-blue;
        font-weight: 500;
    }

    &__new-section {
        display: block;
        border-top: 1px solid $color-grey-20;
        margin: 5px -4px;
    }
}
