.rf-ThemeDetailEditView {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &__table-container {
        background-color: $color-background-blue;
        min-height: 100vh;
        margin: spacing(2.5) spacing(-5) spacing(-15);
        padding: 0 spacing(5);
        border-radius: 16px 0 0 0;
    }

    &__close {
        background-color: transparent;
        border-color: transparent;

        &:hover {
            background-color: $color-ruler-pale-dark;
            border-color: $color-ruler-pale-dark;
        }
    }
}
