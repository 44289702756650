.rf-MainProfileRow {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';
    @import 'styles/variables/layout';

    &__header {
        align-items: center;
        display: flex;
        margin-left: 2px;
        transform: translateX(0);
        transition: transform $animation-appear;
    }

    &__row-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    &__header-wrapper {
        max-width: 196px;
    }

    &__profile-icon {
        opacity: 1;
        transform: scale(1);
        transition: opacity $animation-appear, transform $animation-appear;
        padding: 0 4px;

        &--not-in-view {
            opacity: 0;
            pointer-events: none;
            transform: scale(0.6);
        }
    }

    &__button {
        background: none;
        border: none;
        margin: 0;
        padding: 0;
    }

    &__arrow {
        font-size: 12px;
        transition: color $animation-appear;
        user-select: none;
        z-index: 10;
        &--is-inactive {
            color: $color-grey-10;
        }
    }
}
