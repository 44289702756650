.rf-Bold {
    @import 'styles/variables/colors';
    font-weight: 600;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: $color-grey-95;
}
