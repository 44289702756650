.rf-NewsSourcesListItem {
    @import 'styles/variables/layout';
    @import 'styles/variables/colors';

    padding: 0 spacing(2);
    border: none;
    font-size: 14px;
    border: 1px solid $color-grey-40;
    border-radius: 4px;

    .rf-ExpandableTableListItem__inner {
        height: 56px;
    }

    .rf-ExpandableTableListItem__children {
        border-top: none;
    }
    .rf-ExpandableTableListItem:last-child {
        border-bottom: none;
    }

    .rf-ExpandableTableListItem__button {
        margin-left: 0;
    }

    &.rf-ExpandableTableListItem--is-expanded {
        background-color: $color-grey-10;
        padding-bottom: spacing(2);

        .rf-ExpandableTableListItem__inner {
            padding-bottom: 0;
        }
    }

    &__group-text-enabled {
        font-weight: 600;
    }

    &__group-text-disabled {
        color: $color-grey-80;
        text-decoration: line-through;
        font-weight: 600;
    }

    &__group-info-text {
        font-weight: normal;
        padding-left: spacing(3, -3);
    }

    &__source-item-grid {
        border-bottom: $color-grey-40 1px solid;
        margin-right: spacing(4, -2);
        padding: spacing(2) spacing(2) spacing(2) 0;
        margin-left: spacing(1);
    }

    &__source-item-grid:last-of-type {
        border-bottom: none;
        padding-bottom: 3px;
    }

    &__source-item-enabled {
        font-weight: normal;
        color: $color-grey-95;
    }

    &__source-item-disabled {
        font-weight: normal;
        text-decoration: line-through;
        color: $color-grey-80;
    }

    &__source-item-info {
        font-weight: normal;
        padding-left: spacing(3, -3);
    }
}
