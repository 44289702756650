.rf-DashboardTaskWidget {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    &.rf-WidgetContainer {
        padding-top: spacing(7);
    }

    &--is-detail {
        &.rf-WidgetContainer {
            padding-top: spacing(1.5);
        }
    }

    &__content-container {
        margin-top: spacing(2);

        & > *:nth-child(n + 1) {
            margin-top: spacing(1.5);
        }
    }

    &__sub-container {
        margin-top: 0;
    }

    &__flag {
        color: $color-widget-red;
    }

    &__count-container {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background-color: $color-widget-light-blue;

        &--danger {
            background-color: $color-widget-light-red;
        }
    }

    &__count {
        color: $color-ruler-blue;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        padding-top: spacing(0.5);
        white-space: nowrap;

        &--danger {
            color: $color-widget-red;
        }

        &--is-zero {
            color: initial;
        }
    }
}
