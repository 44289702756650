@import 'styles/variables/colors';
@import 'styles/variables/animations';
@import 'styles/variables/layout';

.rf-MonitoringReportLabel {
    display: flex;
    flex-wrap: nowrap;
    color: $color-text;

    .rf-IconLabel__icon {
        color: $color-text;
        font-size: 12px;
        margin-right: spacing(1);
    }

    &.rf-IconLabel--is-link {
        text-decoration: none;

        &:hover {
            color: $color-ruler-blue;
            transition: $animation-default;

            svg * {
                fill: $color-ruler-blue;
            }
        }
    }
}
