.rf-SearchField {
    @import 'styles/variables/colors';
    @import 'styles/variables/animations';
    @import 'styles/variables/layout';

    width: 100%;
    background: $color-grey-10;
    padding: 0;
    position: relative;

    &__clear-button {
        position: absolute;
        transform-origin: 50% 50%;
        right: 5%;
        top: 45%;
        color: $color-grey-65;
        transition: color $animation-appear;

        &:hover,
        :focus {
            color: $color-grey-95;
        }
    }

    &__search {
        position: absolute;
        transform-origin: 50% 50%;
        left: 5%;
        top: 45%;
        color: $color-grey-65;
        transition: color $animation-appear;
    }

    &__input {
        text-align: center;
        background: $color-grey-10;
        width: 100%;
        height: 100px;
        font-size: 36px;
        line-height: 36px;
        outline: 0;
        border: 0;
        border-radius: 0;
        appearance: none;
        font-weight: 600;
        padding: 0 spacing(14);
        -webkit-overflow-scrolling: touch;
    }
}
