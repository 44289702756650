.rf-Footer {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';
    align-items: center;
    border-top: 1px solid $color-grey-10;
    color: $color-grey-80;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    padding: spacing(2) spacing(4);
    text-decoration: none;
    background: $color-white;
    z-index: 10;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;

    &__logo-container {
        align-items: center;
        color: $color-grey-95;
        display: flex;

        .rf-Logo {
            margin-right: spacing(1, +2);

            svg {
                height: 31px;
                width: 31px;
            }
        }
    }

    &__link-container {
        align-items: flex-end;
        display: flex;
    }

    &__link-list {
        > * {
            margin-left: spacing(4);
        }
    }

    &__link {
        text-decoration: none;

        &:hover,
        :focus {
            text-decoration: underline;
        }
    }
}
