.rf-ConsultationItemList {
    @import 'styles/variables/colors';

    &--has-children {
        border-top: 1px solid $color-grey-40;
        border-bottom: 1px solid $color-grey-40;

        > * + * {
            border-top: 1px solid $color-grey-40;
        }
    }
}
