.rf-EditInboxItemImpactActionButton {
    @import 'styles/variables/colors';
    @import 'styles/variables/layout';

    .rf-Popper__content {
        min-width: 146px;
        padding-bottom: 0;
    }

    .rf-Popper__item-container {
        margin: spacing(1);
    }

    &__icon {
        padding: spacing(1);

        &--action-button {
            background: $color-grey-10;

            &, &:focus {
                background: $color-grey-10;

                .rf-Icon.rf-Button__icon {
                    color: $color-grey-95;
                }
            }

            &:hover {
                .rf-Icon.rf-Button__icon {
                    color: $color-ruler-blue;
                }
                background: $color-ruler-pale;
            }
        }
    }

    &__impact-option {
        width: 100%;
        padding: spacing(1) spacing(2);

        &--active.rf-Button--is-disabled {
            background-color: $color-ruler-pale;
            p {
                color: $color-ruler-blue;
            }
        }
    }
}
